import constate from 'constate';

import { useState, useEffect } from 'react';
import { useHttpApi } from 'state/useHttpApi';

const useInvestmentsState_ = () => {
  const {
    getAllAllotmentsByInvestor,
    getAllEscrowOrdersByInvestor,
    getAllRewardsByInvestor,
    getStatsByInvestor,
    getAllRedeemAllotmentsByInvestor
  } = useHttpApi();

  const [allotmentsInvestor, setAllotmentsInvestor] = useState([]);
  const [escorwOrdersInvestors, setEscrowOrdersInvestors] = useState([]);
  const [rewardsInvestor, setRewardsInvestor] = useState([]);
  const [statsInvestor, setStatsInvestor] = useState([]);
  const [canIssueResponse, setcanIssueResponse] = useState(0);
  const [redeemAllotments, setRedeemAllotments] = useState([]);
  const [currentInvestor, setCurrentInvestor] = useState([]);
  const setCurrentInvestorFunction = (data) => {
    setCurrentInvestor(data);
  };
  const fetchAllAllotmentsByInvestor = async (address) => {
    const rest = await getAllAllotmentsByInvestor(address);
    const filtered = rest.sort((a, b) => {
      return new Date(b.maturity_date) - new Date(a.maturity_date);
    });
    setAllotmentsInvestor(filtered);
  };

  const fetchAllEscrowOrdersByInvestor = async (address) => {
    const rest = await getAllEscrowOrdersByInvestor(address);
    console.log('rest', rest);
    setEscrowOrdersInvestors(rest);
  };

  const fetchAllRewardsByInvestor = async (address) => {
    const rest = await getAllRewardsByInvestor(address);
    console.log('rest', rest);
    setRewardsInvestor(rest);
  };

  const fetchStatsByInvestor = async (address) => {
    const rest = await getStatsByInvestor(address);
    console.log('rest', rest);
    setStatsInvestor(rest);
  };

  const fetchAllRedeemAllotmentsByInvestor = async (address) => {
    const res = await getAllRedeemAllotmentsByInvestor(address);
    console.log('res/redeemAllotments', res);
    setRedeemAllotments(res);
  };
  return {
    allotmentsInvestor,
    fetchAllAllotmentsByInvestor,
    escorwOrdersInvestors,
    fetchAllEscrowOrdersByInvestor,
    rewardsInvestor,
    fetchAllRewardsByInvestor,
    statsInvestor,
    fetchStatsByInvestor,
    canIssueResponse,
    setcanIssueResponse,
    fetchAllRedeemAllotmentsByInvestor,
    redeemAllotments,
    currentInvestor,
    setCurrentInvestorFunction
  };
};

export const [InvestmentsProvider, useInvestmentsState] = constate(useInvestmentsState_);
