import { LoadingButton } from '@mui/lab';
import { Grid, FormLabel, TextField, Button, Checkbox, Switch, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useHttpApi, useWeb3State } from 'state';

const RefreshInvestors = ({ currentToken }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { refreshInvestors } = useWeb3State();
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',

        mt: 2
      }}
    >
      <Typography
        sx={{
          mt: 3,
          fontWeight: 'bold'
        }}
      >
        Refresh Investors NFT / NON NFT Buckets
      </Typography>
      <LoadingButton
        variant="gradient"
        type="submit"
        sx={{ mt: 3, height: '2.6rem', width: '7.813rem' }}
        loading={isSubmitting}
        onClick={async () => {
          try {
            setIsSubmitting(true);
            const res = await refreshInvestors(currentToken.deployment_address);
            if (res) {
              enqueueSnackbar('Investors NFT / NON NFT Buckets Refreshed!', { variant: 'success' });
            }
            setIsSubmitting(false);
          } catch (e) {
            setIsSubmitting(false);
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        }}
      >
        Submit
      </LoadingButton>
    </Grid>
  );
};

export default RefreshInvestors;
