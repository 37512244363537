import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Container,
  CardContent,
  Typography,
  Box,
  Select,
  Grid,
  FormControl,
  MenuItem
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import SettlementsTable from 'components/Admin/Tables/SettlementsTable';
import { useBusinessEntityState } from 'state';
import EscrowButtonPool from 'components/EscrowButtonPool';
import WrapXDC from 'components/Admin/pool/WrapXDC';

const AllSettlemets = () => {
  const { pools, fetchAllPools } = useBusinessEntityState();
  const [selectedToken, setSelectedToken] = useState('all');

  useEffect(() => {
    fetchAllPools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event) => {
    setSelectedToken(event.target.value);
    console.log('SELECTED TOKEN', event.target.value);
  };
  return (
    <Page title="XDC Staking">
      <Container sx={{ mt: 2 }}>
        <Breadcrumbs pageHead="Settlements" />

        <Card sx={{ mt: 2 }} className="form-card">
          <CardContent>
            <SettlementsTable />
          </CardContent>
        </Card>
        <Card
          sx={{
            mt: 2,
            borderRadius: 4
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography variant="h6">Common Redeemption Actions</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  width: '50%',
                  alignItems: 'center'
                }}
              >
                <FormControl
                  size="small"
                  variant="outlined"
                  fullWidth
                  sx={{ mt: 0.5, width: '200px' }}
                >
                  <Select
                    // onChange={handleChange}
                    inputProps={{ 'aria-label': 'Without label' }}
                    id="token"
                    onChange={handleChange}
                    value={selectedToken}
                  >
                    <MenuItem selected key="All" value="all">
                      Select A Pool
                    </MenuItem>
                    {pools &&
                      pools.map((pool) => {
                        return (
                          <MenuItem key={pool.name} value={pool.deployment_address}>
                            {pool.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <EscrowButtonPool original={selectedToken} />
                <WrapXDC poolAddress={selectedToken} />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllSettlemets;
