import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  InputAdornment,
  IconButton
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import Page from 'components/Page';
import React, { useState, useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useCoreTableState } from 'state';

const AddKycProvider = () => {
  const [showPassword, setShowPassword] = useState(false);

  const KYCSchema = Yup.object().shape({
    kyc_provider_name: Yup.string().required('KYC Provider Name is required'),
    country: Yup.string().required('Country is required'),
    address: Yup.string().required('Address is required'),
    kyc_username: Yup.string().required('Username is required'),
    kyc_email: Yup.string().required('Email is required'),
    kyc_password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      kyc_provider_name: '',
      country: '',
      address: '',
      kyc_username: '',
      kyc_email: '',
      kyc_password: ''
    },

    validationSchema: KYCSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log(data);
      if (data) {
        resetForm();
      }
    }
  });
  const { countries } = useCoreTableState();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <Page>
      <Container>
        <Card>
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              KYC Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>KYC Provider Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('kyc_provider_name')}
                        error={Boolean(touched.kyc_provider_name && errors.kyc_provider_name)}
                        helperText={touched.kyc_provider_name && errors.kyc_provider_name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Username</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('kyc_username')}
                        error={Boolean(touched.kyc_username && errors.kyc_username)}
                        helperText={touched.kyc_username && errors.kyc_username}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Email</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('kyc_email')}
                        error={Boolean(touched.kyc_email && errors.kyc_email)}
                        helperText={touched.kyc_email && errors.kyc_email}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Password</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassword} edge="end">
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        {...getFieldProps('kyc_password')}
                        error={Boolean(touched.kyc_password && errors.kyc_password)}
                        helperText={touched.kyc_password && errors.kyc_password}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Country</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('country')}
                          id="country"
                          error={Boolean(touched.country && errors.country)}
                          helperText={touched.country && errors.country}
                        >
                          {countries &&
                            countries.map((country, index) => {
                              return (
                                <MenuItem key={country.iso_code} value={country.iso_code}>
                                  {country.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.country && errors.country}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Address</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('address')}
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                      />
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        console.log('cancel');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      // disabled={!onlyOwner(role)}
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{
                        margin: 1,
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddKycProvider;
