import EnhancedTable from 'components/tables/EnhancedTable';
import React, { useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import GetIssuanceStatus from 'components/GetIssuanceStatus';
import { useInvestmentsState, useWeb3State } from 'state';
import DepositXDC from 'components/DepositXDC';
import EscrowButton from 'components/EscrowButton';
import SwapIssuance from 'components/SwapIssuance';
import GetRedeemptionStatus from 'components/GetRedeemptionStatus';
import SwapRedeemption from 'components/SwapRedeemption';

export default function SettlementsTable() {
  const { escorwOrdersInvestors, fetchAllEscrowOrdersByInvestor } = useInvestmentsState();
  const { account, approveWxdc, paymentTokenGiveAllowance } = useWeb3State();
  useEffect(() => {
    fetchAllEscrowOrdersByInvestor(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  const headCells = useMemo(
    () => [
      {
        accessor: 'token_name',
        Header: 'Token Name',
        show: true,
        width: 100
      },
      // {
      //   accessor: 'investor_name',
      //   Header: 'Investor Name',
      //   show: true,
      //   width: 100
      // },
      {
        accessor: 'trade_token_amount',
        Header: 'Qty',
        show: true,
        width: 60
      },
      {
        accessor: 'order_type',
        Header: 'Order Type',
        show: true,
        width: 100
      },
      {
        accessor: 'conditions',
        Header: 'Conditions',
        show: true,
        width: 100,
        Cell: ({ row: { original } }) => {
          return original.order_type === 'BUY' ? (
            <GetIssuanceStatus orderId={original.escrow_order_id} nonce={Math.random()} />
          ) : (
            <GetRedeemptionStatus orderId={original.escrow_order_id} nonce={Math.random()} />
          );
        }
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        show: true,
        width: 250,
        Cell: ({ row: { original } }) => {
          console.log(original);
          const isIssuanceEscrow = original.order_type === 'BUY';
          return (
            <Box>
              {isIssuanceEscrow && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <DepositXDC />
                  <EscrowButton original={original} />
                  <SwapIssuance original={original} />
                </Box>
              )}
              {!isIssuanceEscrow && <SwapRedeemption original={original} />}
            </Box>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Settlements"
        columns={headCells}
        data={escorwOrdersInvestors}
        refreshFunction={() => {
          fetchAllEscrowOrdersByInvestor(account);
        }}
      />
    </>
  );
}
