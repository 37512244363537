import { LoadingButton } from '@mui/lab';
import { Grid, FormLabel, TextField, Button } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useHttpApi, useWeb3State } from 'state';

const SetMaxSupply = ({ currentToken }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setMaxSupplyToken } = useWeb3State();
  const { updateToken } = useHttpApi();

  const MaturitySchema = Yup.object().shape({
    // old_maturity_date: Yup.string().required('Bar Number is required'),
    new_max_supply: Yup.string().required('New Max Supply is required')
  });

  const formik = useFormik({
    initialValues: {
      current_max_supply: currentToken.max_supply,
      new_max_supply: ''
    },
    validationSchema: MaturitySchema,
    onSubmit: async (data, { resetForm }) => {
      if (data.new_max_supply < currentToken.total_supply) {
        enqueueSnackbar('New Max Supply should be greater than current max supply', {
          variant: 'error'
        });
        return;
      }

      try {
        const res = await setMaxSupplyToken(
          currentToken.deployment_address,
          parseInt(data.new_max_supply, 10)
        );
        if (res) {
          await updateToken(currentToken.id, { max_supply: data.new_max_supply });
          resetForm();
          enqueueSnackbar('Max Supply Updated', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        if (e.message) {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      }
    }
  });

  // eslint-disable-next-line no-unused-vars
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 2
          }}
        >
          <Grid item lg={6} md={6} xs={12}>
            <FormLabel>Current Max Supply</FormLabel>
            <TextField
              fullWidth
              disabled
              sx={{ mt: 1 }}
              {...getFieldProps('current_max_supply')}
              size="small"
              autoComplete="off"
              type="text"
              error={Boolean(touched.current_max_supply && errors.current_max_supply)}
              helperText={touched.current_max_supply && errors.current_max_supply}
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <FormLabel>New</FormLabel>
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              size="small"
              {...getFieldProps('new_max_supply')}
              autoComplete="off"
              type="number"
              error={Boolean(touched.new_max_supply && errors.new_max_supply)}
              helperText={touched.new_max_supply && errors.new_max_supply}
            />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            mt: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <Grid item lg={12} md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              loadingPosition="start"
              variant="gradient"
              type="submit"
              sx={{ mt: 3, height: '2.6rem', width: '7.813rem' }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default SetMaxSupply;
