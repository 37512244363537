import Page from 'components/Page';
import React from 'react';
import { Card, Container, CardContent, Grid, Button } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { useNavigate } from 'react-router';
import TokenTable from 'components/Admin/Tables/TokenTable';

const AllTokens = () => {
  const navigate = useNavigate();

  return (
    <Page title="XDC Staking">
      <Container sx={{ mt: 2 }}>
        <Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
          <Grid item xs={8}>
            <Breadcrumbs pageHead="All Node Tokens" />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              sx={{ color: 'white', fontWeight: 600, backgroundColor: '#2E4AE6' }}
              onClick={() => {
                navigate('/admin/create-token');
              }}
            >
              Create Token
            </Button>
          </Grid>
        </Grid>
        <Card sx={{ mt: 2 }} className="form-card">
          <CardContent>
            <TokenTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllTokens;
