import { AppBar, Box, Container, Button } from '@mui/material';
import logo from '../assets/images/valid.png';
import WalletNavbar from './Wallet/WalletNavbar';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthStateShared } from 'state/useAuthStateShared';
import { useState } from 'react';

const drawerWidth = 80;

const MainNavbar = () => {
  const navigate = useNavigate();
  const [navColor, setNavColor] = useState(false);

  const colorChange = () => {
    if (window.scrollY >= 90) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  };

  window.addEventListener('scroll', colorChange);

  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        width: `calc(100% - ${drawerWidth}px)`,
        ml: `${drawerWidth}px`,
        '@media (max-width: 600px)': { ml: 0, width: '100%' },
        backgroundColor: navColor ? '#fff' : 'transparent',
        height: '5rem',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Container
        sx={{
          backgroundColor: 'inherit',
          height: '3.8rem',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {/* <Toolbar> */}
        <Button
          onClick={() => {
            navigate('/');
          }}
        >
          <Box component="img" src={logo} sx={{ width: '7rem', my: 1 }} alt="XDCS-MVP" />
        </Button>
        <Box
          sx={{
            alignItems: 'center',
            marginLeft: {
              xs: '0%',
              md: '27%',
              lg: '27%',
              xl: '27%'
            }
          }}
        >
          {/* <NavLink
            to="/"
            className="nav-link"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: isActive ? "#ffffff" : "#6A6A6A",
            })}
          >
            Invest
          </NavLink>
          <NavLink
            className="nav-link"
            to="/investor-dashboard"
            style={({ isActive }) => ({
              textDecoration: "none",
              paddingLeft: "1rem",
              color: isActive ? "#ffffff" : "#6A6A6A",
            })}
          >
            Dashboard
          </NavLink> */}
        </Box>

        <Box sx={{ flexGrow: 1.2 }} />

        <WalletNavbar />
      </Container>
    </AppBar>
  );
};

export default MainNavbar;
