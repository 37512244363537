import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useAppState, useCoreTableState, useHttpApi, useWeb3State } from 'state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const ClaimRewards = ({ original }) => {
  const { claimRewards, account, fetchUnClaimedRewards } = useWeb3State();
  const { enqueueSnackbar } = useSnackbar();
  const { tokens } = useCoreTableState();
  const [balance, setBalance] = React.useState(0);
  // const { createEscrow } = useHttpApi();
  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchUnClaimedRewards(original.pool_address, account);
      setBalance(res);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original]);
  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        color="primary"
        disabled={balance === '0'}
        onClick={async () => {
          try {
            console.log('claiming rewards', balance);
            if (balance === '0')
              return enqueueSnackbar('No rewards to claim', { variant: 'error' });
            const res = await claimRewards(original.pool_address);
            enqueueSnackbar('Reward Claimed!', { variant: 'success' });
            return true;
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
            return false;
          }
        }}
      >
        Claim Rewards
      </Button>
    </Box>
  );
};

export default ClaimRewards;
