import React, { useCallback, useEffect, useMemo, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import {
  Box,
  IconButton,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Alert,
  AlertTitle
} from '@mui/material';

import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import { useNavigate } from 'react-router';
import { useHttpApi, useWeb3State, useBusinessEntityState } from 'state';
import { useSnackbar } from 'notistack';
import { HeaderName } from 'helpers/tableHelper';
import { LoadingButton } from '@mui/lab';
import CopyAddress from 'components/CopyAddress';
import { useIsOwner } from 'helpers/rbac';
import { useAppState } from 'state/useAppState';
import SetNodeToken from './SetNodeToken';
import WrapXDC from './WrapXDC';
import AddressFieldTools from 'components/AddressFieldTools';
import { formatDecimal, weiToEth } from 'helpers/numbers';

const ActionButtons = ({
  liquidityPoolId,
  liquidityPoolstatus,
  liquidityPoolAddr,
  liquidityPoolName
}) => {
  const { pools, fetchAllPools, setEditLiquidityPool } = useBusinessEntityState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { getLiquidityPoolById, deleteLiquidityPool } = useHttpApi();
  const navigate = useNavigate();
  const { removeLiquidityPool: removeLiquidityPoolBlockchain } = useWeb3State();
  const { throwErrorMessage } = useAppState();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [loadingState, setLoadingState] = useState(false);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <IconButton
          aria-label="subs detail"
          onClick={async () => {
            // setBusinessEntity(liquidityPoolId);
            const response = await getLiquidityPoolById(liquidityPoolId);
            console.log('ressss', response);
            response.liquidity_pool_xinfin_address = ethToXdcAddress(
              response.liquidity_pool_xinfin_address
            );
            response.liquidity_pool_settlement_address = xdcToEthAddress(
              response.liquidity_pool_settlement_address
            );
            setEditLiquidityPool(response);
            navigate('/masterdata/liquidity-pool/edit-liquidity-pool');
            console.log('edit Pool id', liquidityPoolId);
          }}
        >
          <EditIcon sx={{ fontSize: '2.188rem', m: 0, p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>

        <IconButton aria-label="subs detail" onClick={handleOpen}>
          <Delete sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }} />
        </IconButton>
      </Box>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title">
        <Alert severity="warning">
          <AlertTitle>Delete Liquidity Pool</AlertTitle>
          <DialogContent>
            Are you sure you want to delete Liquidity Pool, {liquidityPoolName}?
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClose}
              sx={{ width: '6.7rem' }}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="outlined"
              color="error"
              loadingPosition="start"
              sx={{ width: '6.7rem' }}
              loading={loadingState}
              onClick={async () => {
                setLoadingState(true);
                console.log('delete Liquidity Pool id', liquidityPoolId, liquidityPoolstatus);
                if (liquidityPoolstatus === 'ONCHAIN') {
                  try {
                    const res = await removeLiquidityPoolBlockchain(
                      xdcToEthAddress(liquidityPoolAddr)
                    );
                    if (res) {
                      enqueueSnackbar('Liquidity Pool is submitted to Delete', {
                        variant: 'info'
                      });
                      fetchAllPools();
                    }
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                } else {
                  await deleteLiquidityPool(liquidityPoolId);
                  enqueueSnackbar('Liquidity Pool deleted successfully', {
                    variant: 'success'
                  });
                  fetchAllPools();
                }
                setLoadingState(false);
                handleClose();
              }}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Alert>
      </Dialog>
    </>
  );
};

const LiquidityPoolChainStatus = ({ status, setlAddress, poolAddress }) => {
  return (
    <Chip
      // onClick={status==="OFFCHAIN"?send:null}
      label={status}
      color={status === 'OFFCHAIN' ? 'warning' : 'success'}
    />
  );
};

const LiquidityPoolTable = () => {
  const { pools, fetchAllPools } = useBusinessEntityState();
  const { fetchContractBalance, fetchWXDCBalance } = useWeb3State();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      // add balance to pool data
      const data = pools.map(async (pool) => {
        let balance = '0';
        let wxdc = '0';
        if (pool.deployment_address !== '0x0000000000000000000000000000000000000000') {
          balance = await fetchContractBalance(pool.deployment_address);
          wxdc = await fetchWXDCBalance(pool.deployment_address);
        }
        return { ...pool, balance, wxdc };
      });
      const resolvedData = await Promise.all(data);
      setData(resolvedData);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pools]);
  useEffect(() => {
    fetchAllPools();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isOwner = useIsOwner();

  const headCells = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Node Name',
        width: 100,
        show: true
      },

      {
        accessor: 'deployment_address',
        Header: 'Node Address',
        width: 100,
        Cell: ({ value }) => {
          return <AddressFieldTools address={value} showCopyButton showInBlockExplorer />;
        },
        show: true
      },
      {
        accessor: 'balance',
        Header: 'XDC Balance',
        show: true,
        width: 100,
        Cell: ({ value }) => {
          return `${formatDecimal(parseFloat(weiToEth(value)))} XDC`;
        }
      },
      {
        accessor: 'wxdc',
        Header: 'WXDC Balance',
        show: true,
        width: 100,
        Cell: ({ value }) => {
          return `${formatDecimal(parseFloat(weiToEth(value)))} WXDC`;
        }
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        width: 200,
        Cell: ({ row: { original } }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row'
              }}
            >
              <SetNodeToken poolAddress={original.deployment_address} />
              <WrapXDC poolAddress={original.deployment_address} />
            </Box>
          );
        },
        show: false
      }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Node"
        columns={headCells}
        refreshFunction={fetchAllPools}
        data={data}
        setData={setData}
        // updateMyData={updateMyData}
        // skipPageReset={skipPageReset}
      />
    </>
  );
};
export default LiquidityPoolTable;
