import EnhancedTable from 'components/tables/EnhancedTable';
import React, { useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import { useInvestmentsState, useWeb3State } from 'state';
import MatureBalance from './MatureBalance';
import RedeemTvT from 'components/RedeemTvT';
import UnClaimedRewards from './UnClaimedRewards';
import ClaimRewards from 'components/ClaimRewards';

export default function RedeemedAllotments() {
  const { account } = useWeb3State();
  const { fetchAllRedeemAllotmentsByInvestor, redeemAllotments } = useInvestmentsState();
  const [timezone, setTimezone] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const updateData = async () => {
    await fetchAllRedeemAllotmentsByInvestor(account);
  };
  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log(timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const headCells = useMemo(
    () => [
      {
        accessor: 'token_name',
        Header: 'Token Name',
        show: true,
        width: 130
      },
      {
        accessor: 'total_alloted',
        Header: 'Quantity',
        show: true,
        width: 100,
        Cell: ({ value }) => {
          return <Box>{value}</Box>;
        }
      },

      {
        accessor: 'maturity_date',
        Header: 'Maturity Date',
        show: true,
        Cell: ({ value }) => {
          return new Date(value).toLocaleString('en', { timeZone: timezone });
        }
      }
    ],
    [timezone]
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Holdings"
        columns={headCells}
        data={redeemAllotments}
        refreshFunction={updateData}
      />
    </>
  );
}
