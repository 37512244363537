import React from 'react';
import EmptyDashboard from 'assets/images/ufo.png';
import { Box, Typography } from '@mui/material';

const DataNotAvailable = ({ sx }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          component="img"
          sx={{
            opacity: 0.5
          }}
          {...sx}
          src={EmptyDashboard}
          alt="Empty Dashboard"
        />
        <Typography sx={{ fontWeight: '600' }}>No records found</Typography>
      </Box>
    </>
  );
};

export default DataNotAvailable;
