import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import { currentNetwork } from 'helpers/web3';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useAppState, useHttpApi, useWeb3State } from 'state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const WrapXDC = ({ poolAddress }) => {
  const { wrapXDC } = useWeb3State();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        color="secondary"
        onClick={async () => {
          try {
            if (poolAddress === 'all') {
              return enqueueSnackbar(`Please select a pool`, {
                variant: 'error'
              });
            }
            console.log('wrapXDC', poolAddress);
            const res = await wrapXDC(poolAddress);
            enqueueSnackbar('Success!', { variant: 'success' });
            return true;
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
            return false;
          }
        }}
      >
        Wrap XDC
      </Button>
    </Box>
  );
};

export default WrapXDC;
