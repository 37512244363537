import Page from '../../components/Page';
import React from 'react';
import { Container } from '@mui/material';
import InvestorTokensTable from 'components/InvestorTokenTable';
import MicroDashboard from './MicroDashboard';

const Home = () => {
  return (
    <Page title="XDC Staking">
      <Container
        sx={{
          py: 6
        }}
      >
        <MicroDashboard />
        <InvestorTokensTable />
      </Container>
    </Page>
  );
};

export default Home;
