import { AppBar, Box, Container, Button } from '@mui/material';
import logo from '../../assets/images/valid.png';
import WalletNavbar from '../Wallet/WalletNavbar';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuthStateShared } from 'state/useAuthStateShared';
import { useAuthState } from 'state/useAuthState';
import { useState } from 'react';

const AdminNavbar = () => {
  const navigate = useNavigate();
  const [navColor, setNavColor] = useState(false);

  const colorChange = () => {
    if (window.scrollY >= 90) {
      setNavColor(true);
    } else {
      setNavColor(false);
    }
  };

  window.addEventListener('scroll', colorChange);

  const { logout } = useAuthState();
  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: navColor ? '#fff' : 'transparent',
        height: '5rem',
        alignItems: 'center',
        py: 1,
        display: 'flex',
        flexDirection: 'row'
      }}
    >
      <Container
        sx={{
          backgroundColor: 'inherit',
          height: '3.8rem',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row'
        }}
      >
        {/* <Toolbar> */}
        <Button
          onClick={() => {
            navigate('/admin');
          }}
        >
          <Box component="img" src={logo} sx={{ width: '7rem', my: 1 }} alt="XDCS-MVP" />
        </Button>
        <Box
          sx={{
            alignItems: 'center',
            marginLeft: {
              xs: '0%',
              md: '0%',
              lg: '10%',
              xl: '10%'
            }
          }}
        >
          {/* <NavLink
            to="/admin"
            style={({ isActive }) => ({
              textDecoration: "none",
              paddingLeft: "1rem",
              color: isActive ? "#ffffff" : "#6A6A6A",
            })}
          >
            Dashboard
          </NavLink>
          <NavLink
            to="/admin/create-token"
            style={({ isActive }) => ({
              textDecoration: "none",
              paddingLeft: "1rem",
              color: isActive ? "#ffffff" : "#6A6A6A",
            })}
          >
            Create Token
          </NavLink>
          <NavLink
            to="/admin/pools"
            style={({ isActive }) => ({
              textDecoration: "none",
              paddingLeft: "1rem",
              color: isActive ? "#ffffff" : "#6A6A6A",
            })}
          >
            Pool
          </NavLink> */}
        </Box>

        <Box sx={{ flexGrow: 1.2 }} />

        <WalletNavbar />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            variant="contained"
            sx={{ color: 'white', fontWeight: 600, backgroundColor: '#2E4AE6' }}
            onClick={() => logout()}
          >
            Logout
          </Button>
        </Box>
      </Container>
    </AppBar>
  );
};

export default AdminNavbar;
