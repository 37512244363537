import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import { currentNetwork } from 'helpers/web3';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useWeb3State } from 'state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const EscrowButtonPool = ({ original }) => {
  const { poolApprove, fetchWXDCBalance } = useWeb3State();
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        variant="outlined"
        color="primary"
        onClick={async () => {
          try {
            console.log('res', original);
            if (original === 'all') {
              return enqueueSnackbar(`Please select a pool`, {
                variant: 'error'
              });
            }
            const allBalance = await fetchWXDCBalance(original);
            console.log('allBalance', allBalance);
            if (allBalance === '0') {
              return enqueueSnackbar(`No WXDC balance to escrow`, {
                variant: 'error'
              });
            }
            const res = await poolApprove(
              original,
              currentNetwork.escrowManagerAddress,
              allBalance
            );
            console.log('res', res);
            enqueueSnackbar(`Approved ${allBalance / 10 ** 18} WXDC to Escrow Manager`, {
              variant: 'success'
            });
            return true;
          } catch (e) {
            console.log(e);
            enqueueSnackbar(e.message, { variant: 'error' });
            return false;
          }
        }}
      >
        Escrow WXDC Pool
      </Button>
    </Box>
  );
};

export default EscrowButtonPool;
