import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import {
  useAppState,
  useCoreTableState,
  useHttpApi,
  useInvestmentsState,
  useWeb3State
} from 'state';

const RedeemTvT = ({ original }) => {
  const { redeemTvT, account } = useWeb3State();
  const { enqueueSnackbar } = useSnackbar();
  const redeemValue = original.is_redeem_requested;
  const [buttonDisable, setButtonDisable] = useState(redeemValue);
  const { updateAllotmentRedeemRequestStatus, indicateRedeemRequest } = useHttpApi();
  const { canIssueResponse, fetchAllAllotmentsByInvestor } = useInvestmentsState();
  const { throwErrorMessage } = useAppState();

  useEffect(() => {
    setButtonDisable(original.is_redeem_requested);
  }, [original]);
  return (
    <Box>
      <LoadingActionButton
        sx={{ m: 0.5 }}
        variant="contained"
        color="primary"
        loadingPosition="start"
        disabled={buttonDisable}
        onClick={async () => {
          try {
            setButtonDisable(true);
            console.log('original', original);
            console.log(original.token_address);
            if (canIssueResponse > 0) {
              throw new Error('Claim Pending Rewards!');
            }
            const res1 = await redeemTvT(original.token_address, `${original.total_alloted}`);
            if (res1) {
              const result = await updateAllotmentRedeemRequestStatus(original.id);
              console.log('flag:', result);
            }
            enqueueSnackbar('Redeemed TvT successfully', {
              variant: 'success'
            });
          } catch (e) {
            throwErrorMessage(e);
            setButtonDisable(false);
          }
        }}
      >
        {original.is_redeemable.type === 'IOI' && 'Apply for '} Redeem
        {original.is_redeemable.type === 'IOI' && 'ption'}
      </LoadingActionButton>
    </Box>
  );
};

export default RedeemTvT;
