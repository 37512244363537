import constate from 'constate';
import { businessEntityPersistence } from 'persistence';
import { useState, useEffect } from 'react';
import { useHttpApi } from 'state/useHttpApi';
import { useAuthState } from './useAuthState';
import { useAuthStateShared } from './useAuthStateShared';

const useBusinessEntityState_ = () => {
  const {
    getAllPools,
    getAllKycProvider,
    getAllInvestors,
    getAllTokens,
    getAllInvestableTokens,
    getAllRewardsHistory,
    getAllRewardsByPool,
    getStats,
    getAllAllotments,
    getAllAllotmentsByToken,
    getAllEscrowOrders
  } = useHttpApi();

  const [pools, setPools] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [currentLiquidityPool, setCurrentLiquidityPool] = useState(null);
  const [rewardHistory, setRewardHistory] = useState([]);
  const [currentAdminToken, setCurrentAdminToken] = useState([]);
  const [rewardsByPool, setRewardsByPool] = useState([]);
  const [currentSelectedToken, setCurrentSelectedToken] = useState(null);
  const [stats, setStats] = useState({});
  const [allotments, setAllotments] = useState([]);
  const [allotmentsByToken, setAllotmentsByToken] = useState([]);
  const [escrowOrders, setEscrowOrders] = useState([]);

  const loadBusinessEntityState = async () => {
    await fetchStats();
  };
  const setEditLiquidityPool = (data) => {
    setCurrentLiquidityPool(data);
  };
  const fetchAllPools = async () => {
    const rest = await getAllPools();
    setPools(rest);
  };
  const fetchStats = async () => {
    const rest = await getStats();
    setStats(rest);
  };
  const setCurrentAdminTokenFunction = (data) => {
    setCurrentAdminToken(data);
  };
  const setCurrentSelectedTokenFunction = (data) => {
    setCurrentSelectedToken(data);
  };

  const fetchAllInvestors = async () => {
    const res = await getAllInvestors();
    setInvestors(res);
  };

  const fetchAllRewardHistory = async () => {
    const res = await getAllRewardsHistory();
    setRewardHistory(res);
  };

  const fetchAllRewardsByPool = async (poolId) => {
    const res = await getAllRewardsByPool(poolId);
    setRewardsByPool(res);
  };
  const fetchAllAllotments = async () => {
    const res = await getAllAllotments();
    console.log('res/allotments', res);
    setAllotments(res);
  };

  const fetchAllAllotmentsByToken = async (tokenID) => {
    const res = await getAllAllotmentsByToken(tokenID);
    console.log('res/allotmentsByToken', res);
    setAllotmentsByToken(res);
  };

  const fetchAllEscrowOrders = async () => {
    const res = await getAllEscrowOrders();
    console.log('res/escrowOrders', res);
    setEscrowOrders(res);
  };
  useEffect(() => {
    loadBusinessEntityState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pools,
    fetchAllPools,
    fetchAllInvestors,
    investors,
    setEditLiquidityPool,
    rewardHistory,
    fetchAllRewardHistory,
    currentAdminToken,
    setCurrentAdminTokenFunction,
    rewardsByPool,
    fetchAllRewardsByPool,
    currentSelectedToken,
    setCurrentSelectedTokenFunction,
    stats,
    fetchStats,
    fetchAllAllotmentsByToken,
    allotmentsByToken,
    allotments,
    fetchAllAllotments,
    escrowOrders,
    fetchAllEscrowOrders
  };
};

export const [BusinessEntityProvider, useBusinessEntityState] = constate(useBusinessEntityState_);
