import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  Button,
  Divider
} from '@mui/material';
import * as Yup from 'yup';
import LoadingButton from '@mui/lab/LoadingButton';
import Page from 'components/Page';
import React, { Component } from 'react';
import {
  useFormik,
  Form,
  FormikProvider,
  useFormikContext,
  FieldAttributes,
  useField
} from 'formik';
import { useHttpApi, useWeb3State } from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { ethToXdcAddress, toChecksumAddress, xdcToEthAddress } from 'helpers/web3';
import { useAppState } from 'state/useAppState';
import { xdcRegex } from 'helpers/regex';
import { de } from 'date-fns/locale';

const DependentField = (props) => {
  const { values, touched, setFieldValue, errors, getFieldProps } = useFormikContext(); // get Formik state and helpers via React Context
  const [field, meta, name] = useField(props); // get the props/info necessary for a Formik <Field> (vs just an <input>)

  React.useEffect(() => {
    // set the values for this field based on those of another
    const sumOfFees =
      100 -
      (parseInt(values.fees_1_percentage > '0' ? values.fees_1_percentage : '0', 10) +
        parseInt(values.fees_2_percentage > '0' ? values.fees_2_percentage : '0', 10));

    console.log('Sum of Fees', sumOfFees);
    setFieldValue(props.name, sumOfFees);
  }, [
    values.fees_1_percentage,
    values.fees_2_percentage,
    touched.fees_1_percentage,
    touched.fees_2_percentage,
    setFieldValue,
    props.name
  ]); // make sure the component will update based on relevant changes

  return (
    <TextField
      sx={{ mt: 1.5 }}
      fullWidth
      size="small"
      autoComplete="off"
      {...getFieldProps(props.name)}
      type="tel"
      inputProps={{
        maxLength: 3,
        type: 'tel',
        max: 100,
        readOnly: true
      }}
      error={Boolean(touched[props.name] && errors[props.name])}
      helperText={touched[props.name] && errors[props.name]}
    />
  );
};

const AddLiquidityPool = () => {
  const { createPool } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();
  const { publishPool, getOwner, account } = useWeb3State();
  const { throwErrorMessage } = useAppState();

  const navigate = useNavigate();
  const PoolSchema = Yup.object().shape({
    name: Yup.string().required('Node name is required'),
    node_owner: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Node owner address is required'),
    hosted_by: Yup.string().required('Hosted by is required'),
    coinbase_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Coinbase address is required'),
    fees_cut_percentage: Yup.number()
      .integer('Fee Cut Percent must be an integer')
      .required('Fee cut percentage is required')
      .min(0, 'Fee Cut Percent cannot be less than 0')
      .max(100, 'Fee Cut Percent cannot be greater than 100'),
    fees_destination_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Fee destination XDCPay Wallet Address is required'),
    nft_haircut_percentage: Yup.number()
      .integer('NFT Haircut Percent must be an integer')
      .required(' NFT Haircut percentage is required')
      .min(1, 'NFT Haircut Percent cannot be less than 1')
      .max(100, 'NFT Haircut Percent cannot be greater than 100'),
    non_nft_haircut_percentage: Yup.number()
      .integer('Non NFT Haircut Percent must be an integer')
      .required('Non NFT Haircut percentage is required')
      .min(1, 'Non NFT Haircut Percent cannot be less than 1')
      .max(100, 'Non NFT Haircut Percent cannot be greater than 100'),
    fees_1_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Dev XDCPay Wallet Address is required'),
    fees_1_percentage: Yup.number()
      .integer('Dev Funds Percent must be an integer')
      .required('Dev Funds percentage is required')
      .min(0, 'Dev Funds Percent cannot be less than 1')
      .max(100, 'Dev Funds Percent cannot be greater than 100'),
    fees_2_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('Prime Number XDCPay Wallet Address is required'),
    fees_2_percentage: Yup.number()
      .integer('Prime Number Fees Percent must be an integer')
      .required('Prime Number Fees is required')
      .min(0, 'Prime Number Fees cannot be less than 1')
      .max(100, 'Prime Number Fees cannot be greater than 100'),
    fees_3_address: Yup.string()
      .matches(xdcRegex, 'Please enter a valid XDCPay Wallet Address')
      .required('XDCS XDCPay Wallet Address is required'),
    fees_3_percentage: Yup.number()
      .integer('XDCS Fund Percent must be an integer')
      .required('XDCS Fund percentage is required')
      .min(1, 'XDCS Fees cannot be less than 1 , Please adjust the other fees')
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      owner: account,
      hosted_by: 'NodeForge',
      node_owner: '',
      coinbase_address: '',
      fees_cut_percentage: '',
      kyc_hash: 'HASH',
      fees_destination_address: '',
      nft_haircut_percentage: '',
      non_nft_haircut_percentage: '',
      fees_1_address: '',
      fees_1_percentage: '',
      fees_2_address: '',
      fees_2_percentage: '',
      fees_3_address: '',
      fees_3_percentage: ''
    },
    validationSchema: PoolSchema,
    validate: (values) => {
      const errors = {};
      if (values.name.length > 50) {
        errors.name = 'Pool name is too long';
      }
      if (values.nft_haircut_percentage && values.non_nft_haircut_percentage) {
        if (
          parseInt(values.nft_haircut_percentage, 10) >
          parseInt(values.non_nft_haircut_percentage, 10)
        ) {
          errors.nft_haircut_percentage =
            'NFT Haircut Percent cannot be greater than Non NFT Haircut Percent';
        }
      }

      // Check if Sum of Dev Pool Fund Percentage, Prime Number Fund Percentage and XDCS Fund Percentage is 100

      if (values.fees_1_percentage && values.fees_2_percentage && values.fees_3_percentage) {
        if (
          parseInt(values.fees_1_percentage, 10) +
            parseInt(values.fees_2_percentage, 10) +
            parseInt(values.fees_3_percentage, 10) !==
          100
        ) {
          errors.fees_1_percentage =
            'Sum of Dev Pool Fund Percentage, Prime Number Fund Percentage and XDCS Fund Percentage must be 100';
          errors.fees_2_percentage =
            'Sum of Dev Pool Fund Percentage, Prime Number Fund Percentage and XDCS Fund Percentage must be 100';
          errors.fees_3_percentage =
            'Sum of Dev Pool Fund Percentage, Prime Number Fund Percentage and XDCS Fund Percentage must be 100';
        }
        if (values.fees_3_percentage < 1) {
          errors.fees_2_percentage =
            'Please adjust this field such that XDCS Fund Percentage is greater than 1';
          errors.fees_1_percentage =
            'Please adjust this field such that XDCS Fund Percentage is greater than 1';
        }
      }
      return errors;
    },
    onSubmit: async (data, { resetForm }) => {
      try {
        // convert address from XDC to ETG
        const contractOwner = await getOwner();
        if (contractOwner !== account) {
          throw new Error('You are not the owner of the contract');
        }

        data.owner = toChecksumAddress(xdcToEthAddress(account));
        data.coinbase_address = toChecksumAddress(xdcToEthAddress(data.coinbase_address));
        data.fees_destination_address = toChecksumAddress(
          xdcToEthAddress(data.fees_destination_address)
        );

        data.node_owner = toChecksumAddress(xdcToEthAddress(data.node_owner));
        data.fees_1_address = toChecksumAddress(xdcToEthAddress(data.fees_1_address));
        data.fees_2_address = toChecksumAddress(xdcToEthAddress(data.fees_2_address));
        data.fees_3_address = toChecksumAddress(xdcToEthAddress(data.fees_3_address));

        // Django Call
        const _pool = await createPool(data);

        if (_pool) {
          resetForm();
          enqueueSnackbar('Node created successfully', {
            variant: 'success'
          });
          navigate('/admin');
        }
        // BlockChain Call
        console.log('data', data);
        const res = await publishPool(
          data.coinbase_address,
          parseInt(data.fees_cut_percentage, 10),
          parseInt(data.nft_haircut_percentage, 10),
          parseInt(data.non_nft_haircut_percentage, 10),
          data.fees_destination_address,
          data.node_owner,
          { addr: data.fees_1_address, percentage: parseInt(data.fees_1_percentage, 10) },
          { addr: data.fees_2_address, percentage: parseInt(data.fees_2_percentage, 10) },
          { addr: data.fees_3_address, percentage: parseInt(data.fees_3_percentage, 10) }
        );

        console.log('response', res);
      } catch (e) {
        console.log(e);
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page sx={{ mt: 3 }}>
      <Container>
        <Card className="form-card">
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Node Details
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Node Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    {/* <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Owner</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('owner')}
                        error={Boolean(touched.owner && errors.owner)}
                        helperText={touched.owner && errors.owner}
                      />
                    </Grid> */}
                    {account && (
                      <Grid item lg={6} md={6} xs={12}>
                        <FormLabel>Staking Pool Owner</FormLabel>
                        <TextField
                          value={ethToXdcAddress(account)}
                          inputProps={{ readOnly: true }}
                          sx={{ mt: 1.5 }}
                          fullWidth
                          size="small"
                          autoComplete="Eth Address"
                          type="text"
                          error={Boolean(touched.owner && errors.owner)}
                          helperText={touched.owner && errors.owner}
                        />
                      </Grid>
                    )}
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Node Owner Wallet</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('node_owner')}
                        error={Boolean(touched.node_owner && errors.node_owner)}
                        helperText={touched.node_owner && errors.node_owner}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Node Hosted by</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('hosted_by')}
                        error={Boolean(touched.hosted_by && errors.hosted_by)}
                        helperText={touched.hosted_by && errors.hosted_by}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Coinbase Address</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('coinbase_address')}
                        error={Boolean(touched.coinbase_address && errors.coinbase_address)}
                        helperText={touched.coinbase_address && errors.coinbase_address}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} sx={{ width: '100%', mt: 2 }}>
                    <Grid item lg={12} md={12} xs={12} sm={12}>
                      <Typography variant="h5">Fees Setup</Typography>
                      <hr />
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>KYC / KYB Fees Address</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('fees_destination_address')}
                        error={Boolean(
                          touched.fees_destination_address && errors.fees_destination_address
                        )}
                        helperText={
                          touched.fees_destination_address && errors.fees_destination_address
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>KYC / KYB Fees Cut (%)</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        {...getFieldProps('fees_cut_percentage')}
                        type="tel"
                        inputProps={{
                          maxLength: 3,
                          max: 100
                        }}
                        error={Boolean(touched.fees_cut_percentage && errors.fees_cut_percentage)}
                        helperText={touched.fees_cut_percentage && errors.fees_cut_percentage}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>NFT Haircut (%)</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        {...getFieldProps('nft_haircut_percentage')}
                        type="tel"
                        inputProps={{
                          maxLength: 3,
                          max: 100
                        }}
                        error={Boolean(
                          touched.nft_haircut_percentage && errors.nft_haircut_percentage
                        )}
                        helperText={touched.nft_haircut_percentage && errors.nft_haircut_percentage}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Non NFT Haircut Cut (%)</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="tel"
                        inputProps={{
                          maxLength: 3,
                          max: 100
                        }}
                        {...getFieldProps('non_nft_haircut_percentage')}
                        error={Boolean(
                          touched.non_nft_haircut_percentage && errors.non_nft_haircut_percentage
                        )}
                        helperText={
                          touched.non_nft_haircut_percentage && errors.non_nft_haircut_percentage
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Dev Pool Fund Address</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('fees_1_address')}
                        error={Boolean(touched.fees_1_address && errors.fees_1_address)}
                        helperText={touched.fees_1_address && errors.fees_1_address}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Dev Pool Fund (%)</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="tel"
                        inputProps={{
                          maxLength: 3,
                          max: 100
                        }}
                        {...getFieldProps('fees_1_percentage')}
                        error={Boolean(touched.fees_1_percentage && errors.fees_1_percentage)}
                        helperText={touched.fees_1_percentage && errors.fees_1_percentage}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Prime Number Fund Address</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('fees_2_address')}
                        error={Boolean(touched.fees_2_address && errors.fees_2_address)}
                        helperText={touched.fees_2_address && errors.fees_2_address}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Prime Number Fund (%)</FormLabel>

                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="tel"
                        inputProps={{
                          maxLength: 3,
                          max: 100
                        }}
                        {...getFieldProps('fees_2_percentage')}
                        error={Boolean(touched.fees_2_percentage && errors.fees_2_percentage)}
                        helperText={touched.fees_2_percentage && errors.fees_2_percentage}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>XDCS Fund Address</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('fees_3_address')}
                        error={Boolean(touched.fees_3_address && errors.fees_3_address)}
                        helperText={touched.fees_3_address && errors.fees_3_address}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>XDCS Fund (%)</FormLabel>
                      <DependentField name="fees_3_percentage" />
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        navigate('/admin');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      // disabled={!onlyOwner(role)}
                      fullWidth
                      size="large"
                      loadingPosition="start"
                      loading={isSubmitting}
                      sx={{ margin: 1, px: 3, width: '7.75rem', height: '2.5rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default AddLiquidityPool;
