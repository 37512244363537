import CreateProfileForm from 'components/Investor/CreateProfileForm';
import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';

const CreateProfile = () => {
  return (
    <Grid>
      <Paper
        sx={{
          padding: 5,
          height: 'auto',
          width: '545px',
          margin: '40px auto',
          boxShadow: '8px 8px 15px #454545',
          borderRadius: 8
        }}
      >
        <Typography variant="h4" sx={{ color: '#3449DD' }}>
          Create Profile
        </Typography>
        <Typography variant="h7" sx={{ mb: 4, color: '#A3A3A3' }}>
          Being a first time investor you need to create profile to continue
        </Typography>
        <CreateProfileForm />
      </Paper>
    </Grid>
  );
};

export default CreateProfile;
