import { useState, useEffect } from 'react';
import { useWeb3State } from 'state';
import { Chip } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

export default function GetIssuanceStatus({ orderId, nonce }) {
  const { getIssuanceEscrowCondition } = useWeb3State();
  const [canIssueResponse, setcanIssueResponse] = useState('0');

  useEffect(() => {
    const fetchdata = async () => {
      const response = await getIssuanceEscrowCondition(orderId);
      console.log('response', response);
      setcanIssueResponse(response);
    };

    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonce]);

  switch (canIssueResponse) {
    case '0':
      return <Chip label="Loading" color="default" />;
    case true:
      return <CheckCircleOutlineOutlinedIcon color="success" />;
    default:
      return <CancelIcon color="error" />;
  }
}
