export const getErrorMessage = (e) => {
  console.log(typeof e);
  console.log(Object.keys(e));
  console.log(Object.values(e));
  console.log(e.code);
  console.log(e.message);
  if (e.code) return e.message;
  if (e.error) return e.error;
  if (e.response) {
    const { data } = e.response;
    const firstKey = Object.keys(data)[0];
    if (Array.isArray(data[firstKey])) {
      return `${firstKey} : ${data[firstKey][0]}`;
    } else {
      return data[firstKey];
    }
  }
  return e.message;
};

export const getErrorMessageAlternative = (msg) => {
  switch (msg) {
    case 'Error: MetaMask Tx Signature: User denied transaction signature.':
      return 'Transaction rejected';
    case 'Promise was rejected with a falsy value':
      return 'Transaction rejected';
    default:
      return msg;
  }
};
