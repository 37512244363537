import React, { useState } from 'react';
import { LoadingButton } from '@mui/lab';

const LoadingActionButton = ({
  children,
  onClick,
  sx,
  variant,
  loadingPosition,
  disabled,
  color,
  ...props
}) => {
  const [loadingState, setLoadingState] = useState(false);

  return (
    <LoadingButton
      loadingPosition={loadingPosition}
      variant={variant}
      sx={sx}
      color={color}
      loading={loadingState}
      disabled={disabled}
      onClick={async () => {
        setLoadingState(true);
        try {
          await onClick();
        } finally {
          setLoadingState(false);
        }
      }}
    >
      {children}
    </LoadingButton>
  );
};
export default LoadingActionButton;
