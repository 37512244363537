import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Alert,
  Box,
  FormLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useAuthState } from 'state/useAuthState';
import { useAppState } from 'state/useAppState';

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required')
  });
  const { login } = useAuthState();
  const { throwErrorMessage } = useAppState();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async ({ username, password }) => {
      try {
        console.log('check', username, password);
        await login(username, password);
        navigate('/admin');
      } catch (e) {
        console.log(e);
        const errorMessage = await throwErrorMessage(e);
        setError(errorMessage);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Stack spacing={3} sx={{ mt: 2 }}>
          <FormLabel>Username</FormLabel>
          <TextField
            fullWidth
            autoComplete="username"
            id="username"
            type="text"
            placeholder="Enter username"
            {...getFieldProps('username')}
            error={Boolean(touched.username && errors.username)}
            helperText={touched.username && errors.username}
          />
          <FormLabel>Password</FormLabel>

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            placeholder="Enter Your Password"
            id="password"
            sx={{
              outline: 'none'
            }}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />
        </Stack> */}

        <LoadingButton
          fullWidth
          size="large"
          sx={{
            mt: 3,
            backgroundColor: '#2E4AE6',
            color: 'white',
            '&:hover': {
              color: '#3449DD',
              backgroundColor: '#fff'
            }
          }}
          type="submit"
          loadingPosition="start"
          loading={isSubmitting}
          id="login-button"
        >
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
