import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import { currentNetwork } from 'helpers/web3';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHttpApi, useWeb3State } from 'state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const SwapIssuance = ({ original }) => {
  const { swapIssuanceOrder } = useWeb3State();
  const { completeIssuance } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();
  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        color="primary"
        onClick={async () => {
          try {
            const res = await swapIssuanceOrder(original.escrow_order_id);
            console.log('res', res);
            if (res) {
              enqueueSnackbar('Swap Issuance successfully', {
                variant: 'success'
              });
            }
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        }}
      >
        Swap
      </Button>
    </Box>
  );
};

export default SwapIssuance;
