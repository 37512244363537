import { useState, useEffect } from 'react';
import { useHttpApi } from './useHttpApi';
import constate from 'constate';

const CoreTableState = () => {
  const {
    getAllCountries,
    getAllRewardFrequency,
    getAllPaymentTokens,
    getAllKycProvider,
    getAllTokens,
    getAllInvestableTokens,
    getAllTokensByName,
    getAllFaqs
  } = useHttpApi();

  const [countries, setCountries] = useState([]);
  const [rewardFrequency, setRewardFrequency] = useState([]);
  const [paymentTokens, setPaymentTokens] = useState([]);
  const [kycProviders, setKycProviders] = useState([]);
  const [tokens, setTokens] = useState([]);
  const [currentToken, setCurrentToken] = useState([]);
  const [tokensByName, setTokensByName] = useState([]);
  const [investableTokens, setInvestableTokens] = useState([]);
  const [faqs, setFaqs] = useState([]);

  const fetchAllCountries = async () => {
    const rest = await getAllCountries();
    setCountries(rest);
  };
  const fetchAllRewardFrequency = async () => {
    const rest = await getAllRewardFrequency();
    setRewardFrequency(rest);
  };

  const fetchAllPaymentTokens = async () => {
    const rest = await getAllPaymentTokens();
    setPaymentTokens(rest);
  };
  const fetchAllKycProviders = async () => {
    const rest = await getAllKycProvider();
    setKycProviders(rest);
  };

  const fetchAllTokens = async () => {
    const rest = await getAllTokens();
    setTokens(rest);
  };

  const fetchAllTokensByName = async (name) => {
    const rest = await getAllTokensByName(name);
    setTokensByName(rest);
  };

  const setCurrentTokenFunction = (data) => {
    setCurrentToken(data);
  };

  const fetchAllInvestableTokens = async () => {
    const rest = await getAllInvestableTokens();
    const sorted = rest.sort((a, b) => b.is_investable - a.is_investable);
    setInvestableTokens(sorted);
  };

  const fetchAllFaqs = async () => {
    const rest = await getAllFaqs();
    setFaqs(rest);
  };

  useEffect(() => {
    fetchAllCountries();
    fetchAllRewardFrequency();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    countries,
    fetchAllCountries,
    rewardFrequency,
    fetchAllRewardFrequency,
    paymentTokens,
    fetchAllPaymentTokens,
    fetchAllKycProviders,
    kycProviders,
    tokens,
    fetchAllTokens,
    currentToken,
    setCurrentTokenFunction,
    fetchAllTokensByName,
    tokensByName,
    fetchAllInvestableTokens,
    investableTokens,
    fetchAllFaqs,
    faqs
  };
};

export const [CoreTableProvider, useCoreTableState] = constate(CoreTableState);
