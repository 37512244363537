import EnhancedTable from '../../tables/EnhancedTable';
import React, { useMemo, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useBusinessEntityState, useInvestmentsState } from 'state';
import AddressFieldTools from 'components/AddressFieldTools';

export default function AllotmentsTable({ tokenID }) {
  const { allotments, fetchAllAllotments, allotmentsByToken, fetchAllAllotmentsByToken } =
    useBusinessEntityState();

  const updateData = (tokenID) => {
    if (tokenID !== 'all') {
      fetchAllAllotmentsByToken(tokenID);
    } else {
      fetchAllAllotments();
    }
  };

  useEffect(() => {
    updateData(tokenID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenID]);

  const headCells = useMemo(
    () => [
      {
        accessor: 'token_name',
        Header: 'Token Name',
        show: true
      },
      // {
      //   accessor: 'investor_name',
      //   Header: 'Investor Name',
      //   show: true,
      //   width: 200
      // },
      {
        accessor: 'total_alloted',
        Header: 'Total Alloted',
        show: true
      },

      {
        accessor: 'investor_address',
        Header: 'Investor Address',
        show: true,
        Cell: ({ value }) => {
          return (
            <AddressFieldTools decimals={0} address={value} showInBlockExplorer showCopyButton />
          );
        }
      }
    ],
    []
  );
  //   useEffect(() => {
  //     updateData();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, []);

  return (
    <>
      <Grid className="form-card">
        <EnhancedTable
          tableTitle="Transactions"
          columns={headCells}
          data={tokenID === 'all' ? allotments : allotmentsByToken}
          refreshFunction={() => {
            updateData(tokenID);
          }}
        />
      </Grid>
    </>
  );
}
