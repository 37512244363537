import { LoadingButton } from '@mui/lab';
import { Grid, FormLabel, TextField, Button } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useHttpApi, useWeb3State } from 'state';
import { useEffect } from 'react';

const toUTCDateTime = (d) => {
  const date = new Date(d);
  console.log('date', date.toISOString);
  return date.toISOString();
};
const toEpoch = (date) => {
  // create a new javascript Date object using timezone as UTC
  const d = new Date(date);
  const utcDate = new Date(d.toUTCString());
  const epoch = utcDate.getTime() / 1000;
  return epoch;
};

const UpdateMaturityDate = ({ currentToken }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateToken } = useHttpApi();
  const { changeMaturityDate } = useWeb3State();

  const MaturitySchema = Yup.object().shape({
    // old_maturity_date: Yup.string().required('Bar Number is required'),
    new_maturity_date: Yup.string().required('New Maturity Date is required')
  });

  const formik = useFormik({
    initialValues: {
      current_maturity_date: currentToken.maturity_date,
      new_maturity_date: ''
    },
    validationSchema: MaturitySchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('DATA', data);

      const matureDate = toUTCDateTime(data.new_maturity_date);
      console.log('matureDate', toEpoch(data.new_maturity_date));
      console.log('currentToken.maturity_date', toEpoch(currentToken.maturity_date));
      if (toEpoch(currentToken.maturity_date) >= toEpoch(data.new_maturity_date)) {
        enqueueSnackbar('New Maturity Date should be greater than current maturity date', {
          variant: 'error'
        });
        return;
      }
      try {
        const res = await changeMaturityDate(
          currentToken.deployment_address,
          toEpoch(data.new_maturity_date)
        );
        if (res) {
          await updateToken(currentToken.id, {
            maturity_date: toUTCDateTime(data.new_maturity_date)
          });
          console.log('Done');
          resetForm();
          enqueueSnackbar('Maturity Date Updated', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        if (e.message) {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      }
    }
  });

  // eslint-disable-next-line no-unused-vars
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 2
          }}
        >
          <Grid item lg={6} md={6} xs={12}>
            <FormLabel>Current Maturity Date/Time</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                disablePast
                disabled
                value={formik.values.current_maturity_date}
                onChange={(newDate) => {
                  formik.setFieldValue(
                    'current_maturity_date',
                    format(newDate, 'yyyy-MM-dd HH:mm:ss')
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    size="small"
                    {...params}
                    {...getFieldProps('maturity_date')}
                    id="current_maturity_date"
                    error={Boolean(touched.current_maturity_date && errors.current_maturity_date)}
                    helperText={touched.current_maturity_date && errors.current_maturity_date}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item lg={6} md={6} xs={12}>
            <FormLabel>New</FormLabel>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                disablePast
                value={formik.values.new_maturity_date}
                onChange={(newDate) => {
                  formik.setFieldValue('new_maturity_date', format(newDate, 'yyyy-MM-dd HH:mm:ss'));
                }}
                renderInput={(params) => (
                  <TextField
                    sx={{ mt: 1 }}
                    fullWidth
                    size="small"
                    {...params}
                    {...getFieldProps('new_maturity_date')}
                    id="new_maturity_date"
                    error={Boolean(touched.new_maturity_date && errors.new_maturity_date)}
                    helperText={touched.new_maturity_date && errors.new_maturity_date}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            mt: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <Grid item lg={12} md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              loadingPosition="start"
              variant="gradient"
              type="submit"
              sx={{ mt: 3, height: '2.6rem', width: '7.813rem' }}
              loading={isSubmitting}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default UpdateMaturityDate;
