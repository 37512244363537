import { Box } from '@mui/material';
import { currentNetwork } from 'helpers/web3';
import React, { useState } from 'react';
import { useWeb3State } from 'state';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useLocation } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const EscrowButton = ({ original }) => {
  const { approveWxdc } = useWeb3State();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  return (
    <>
      {location.pathname === '/buy-token' ? (
        <LoadingButton
          variant="contained"
          size="large"
          color="secondary"
          sx={{
            width: '20%',
            lineHeight: 1.5
          }}
          onClick={async () => {
            try {
              setIsSubmitting(true);
              console.log('res', original);
              const res = await approveWxdc(
                currentNetwork.escrowManagerAddress,
                original.payment_token_amount
              );
              console.log('res', res);
              enqueueSnackbar('Escrow XDC successfully', { variant: 'success' });
              setIsSubmitting(false);
            } catch (e) {
              console.log(e);
              enqueueSnackbar(e.message, { variant: 'error' });
              setIsSubmitting(false);
            }
          }}
          startIconLoadingStart={{
            color: '#000'
          }}
          loadingPosition="center"
          loading={isSubmitting}
        >
          Escrow WXDC
        </LoadingButton>
      ) : (
        <Box>
          <LoadingButton
            sx={{ m: 0.5 }}
            variant="outlined"
            color="primary"
            onClick={async () => {
              try {
                setIsSubmitting(true);
                console.log('res', original);
                const res = await approveWxdc(
                  currentNetwork.escrowManagerAddress,
                  original.payment_token_amount
                );
                console.log('res', res);
                enqueueSnackbar('Escrow XDC successfully', { variant: 'success' });
                setIsSubmitting(false);
              } catch (e) {
                console.log(e);
                enqueueSnackbar(e.message, { variant: 'error' });
                setIsSubmitting(false);
              }
            }}
            loadingPosition="center"
            loading={isSubmitting}
          >
            Escrow WXDC
          </LoadingButton>
        </Box>
      )}
    </>
  );
};

export default EscrowButton;
