import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import { currentNetwork } from 'helpers/web3';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useAppState, useHttpApi, useWeb3State } from 'state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const SwapRedeemption = ({ original }) => {
  const { swapRedeemptionOrder, getRedeemptionEscrowCondition, fetchUnClaimedRewards, account } =
    useWeb3State();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        color="primary"
        onClick={async () => {
          try {
            const unclaimedRewards = await fetchUnClaimedRewards(
              original.pool_address,
              original.investor_address
            );
            const message =
              account.toLowerCase() === original.investor_address.toLowerCase()
                ? 'Please claim your rewards first'
                : 'Investor has not claimed rewards yet';
            if (unclaimedRewards > 0) {
              enqueueSnackbar(message, { variant: 'error' });
              return;
            }
            const response = await getRedeemptionEscrowCondition(original.escrow_order_id);
            console.log('response', response);
            if (!response) {
              enqueueSnackbar('Redeemption is not allowed', { variant: 'error' });
              return;
            }
            const res = await swapRedeemptionOrder(original.escrow_order_id);
            console.log('res', res);
            if (res) {
              enqueueSnackbar('Redeemed!', { variant: 'success' });
            }
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        }}
      >
        Swap
      </Button>
    </Box>
  );
};

export default SwapRedeemption;
