import React from 'react';
import Countdown from 'react-countdown';
import { Typography, Grid, Chip } from '@mui/material';

const CountdownTimer = ({ maturityDate, simple = false }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Grid>
          <Typography>{simple ? 'Matured' : 'Closed'}</Typography>
        </Grid>
      );
    } else {
      return (
        <Grid sx={{ width: '100%' }}>
          {simple ? (
            <Typography>
              {days}d {hours}h {minutes}m {seconds}s
            </Typography>
          ) : (
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={3}>
                <Chip label={days} className="counter-chip" />
                <Typography sx={{ fontSize: '0.9rem' }}>Days</Typography>
              </Grid>
              <Grid item xs={3}>
                <Chip label={hours} className="counter-chip" />
                <Typography sx={{ fontSize: '0.9rem' }}>Hours</Typography>
              </Grid>
              <Grid item xs={3}>
                <Chip label={minutes} className="counter-chip" />
                <Typography sx={{ fontSize: '0.9rem' }}>Minutes</Typography>
              </Grid>
              <Grid item xs={3}>
                <Chip label={seconds} className="counter-chip" />
                <Typography sx={{ fontSize: '0.9rem' }}>Seconds</Typography>
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    }
  };
  return (
    <Grid>
      <Countdown date={maturityDate} renderer={renderer} />
    </Grid>
  );
};

export default CountdownTimer;
