import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  FormControl,
  Select,
  MenuItem
} from '@mui/material';
import { useCoreTableState } from 'state';
import PieChartData from 'components/PieChartData';
import CircleIcon from '@mui/icons-material/Circle';
import { useParams, useNavigate } from 'react-router-dom';
import DataNotAvailable from 'helpers/DataNotAvailable';

export const NodeCard = ({ value, title }) => {
  return (
    <>
      <Grid item xs={6}>
        <Card
          sx={{
            backgroundColor: 'blue',
            pl: 1,
            borderRadius: 0
          }}
        >
          <Card
            sx={{
              px: 2,
              py: 0.5,
              borderRadius: 0
            }}
          >
            <Typography fontWeight="bold" display="block" gutterBottom>
              {value == null ? '0' : value}
            </Typography>
            <Typography
              variant="caption"
              fontWeight="bold"
              color="grey"
              display="block"
              gutterBottom
            >
              {title}
            </Typography>
          </Card>
        </Card>
      </Grid>
    </>
  );
};

export const ColorChart = ({ color, name }) => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell
            sx={{
              p: 0
            }}
          >
            <CircleIcon
              sx={{
                color
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              p: 0
            }}
          >
            <Typography variant="h6" fontWeight="bold" color="grey">
              {name}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

const NodeDashboard = () => {
  // "#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#000000"

  const navigate = useNavigate();
  const COLORS = [
    { color: '#0088FE', name: '0-100k' },
    { color: '#00C49F', name: '100k-499k' },
    { color: '#FFBB28', name: '500k-1M' },
    { color: '#FF8042', name: '1M-1.49M' },
    { color: '#000000', name: '1.5M-1.99M' },
    { color: '#6f4e7c', name: '2M-2.49M' },
    { color: '#8dddd0', name: '2.5M+' }
  ];
  const { tname } = useParams();
  const [selected, setSelected] = useState(tname);

  const handleChange = (selectedToken) => {
    setSelected(selectedToken.target.value);
    navigate(`/node-dashboard/${selectedToken.target.value}`);
  };

  const { tokens, fetchAllTokens, tokensByName, fetchAllTokensByName } = useCoreTableState();
  useEffect(() => {
    fetchAllTokensByName(selected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    if (tname) {
      setSelected(tname);
    } else {
      setSelected('');
    }
  }, [tname]);

  useEffect(() => {
    fetchAllTokens();
    console.log('TOKENS Name', tname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page>
      <Container
        maxWidth="lg"
        sx={{
          pt: 3,
          justifyContent: 'center',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <Card
          sx={{
            p: 4,
            borderRadius: 8,

            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)'
          }}
        >
          <Grid container display="flex" justifyContent="space-between" alignItems="center" sx={{}}>
            {tokensByName.length === 0 ? (
              <>
                <Box>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: '1rem',
                        mt: 1.5,
                        mr: 2
                      }}
                    >
                      Please Select A Token
                    </Typography>
                    <FormControl
                      size="small"
                      variant="outlined"
                      fullWidth
                      sx={{ mt: 1.5, width: '325px' }}
                    >
                      <Select
                        onChange={handleChange}
                        inputProps={{ 'aria-label': 'Without label' }}
                        id="token"
                        value={selected}
                      >
                        {tokens &&
                          tokens.map((token) => {
                            return (
                              <MenuItem key={token.name} value={token.name}>
                                {token.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      m: 5
                    }}
                  >
                    <DataNotAvailable sx={{ width: 130, opacity: 0.5 }} />
                  </Box>
                </Box>
              </>
            ) : (
              <>
                <Grid item xs={7} display="flex" justifyContent="center" alignItems="center">
                  {tokensByName.map((token) => (
                    <Box key={token.id}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between'
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: '1.5rem'
                          }}
                        >
                          {token.name}
                        </Typography>
                        <FormControl
                          size="small"
                          variant="outlined"
                          fullWidth
                          sx={{ mt: 1.5, width: '325px' }}
                        >
                          <Select
                            onChange={handleChange}
                            inputProps={{ 'aria-label': 'Without label' }}
                            id="token"
                            value={selected}
                          >
                            {tokens &&
                              tokens.map((token) => {
                                return (
                                  <MenuItem key={token.name} value={token.name}>
                                    {token.name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </Box>
                      <Grid
                        container
                        spacing={3}
                        sx={{
                          py: 2
                        }}
                      >
                        <NodeCard value="5%-7%" title="APY" />
                        <NodeCard value="Standby" title="TYPE" />
                        <NodeCard value={token.status} title="STATUS" />
                        <NodeCard value={token.host} title="HOST" />
                        <NodeCard value={token.total_supply} title="TOKEN IN ESCROW" />
                        <NodeCard
                          value={`${token.max_supply - token.total_supply} / ${token.max_supply}`}
                          title="AVAILABLE SPACE"
                        />
                        <NodeCard
                          value={token.minimum_subscription * token.value}
                          title="MINIMUM STAKE"
                        />
                        <NodeCard value={token.tokens_alloted} title="PARTICIPANTS" />
                      </Grid>
                    </Box>
                  ))}
                </Grid>
                <Grid item xs={5} display="flex" justifyContent="center" alignItems="center">
                  <Box
                    sx={{
                      justifyContent: 'center',
                      display: 'flex-column'
                    }}
                  >
                    {tokensByName &&
                      tokensByName.map((token) => {
                        const stats = token.investor_stats;
                        console.log('TOKENS', stats);
                        console.log('TOKENS', token.header);
                        return stats['0-100k'] === 0 &&
                          stats['100k-499k'] === 0 &&
                          stats['500k-1M'] === 0 &&
                          stats['1M-1.49M'] === 0 &&
                          stats['1.5M-1.99M'] === 0 &&
                          stats['2M-2.49M'] === 0 &&
                          stats['2.5M+'] === 0 ? (
                          <Box
                            sx={{
                              height: '200px',
                              weight: '200px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            <Typography fontWeight="bold" textAlign="center">
                              NO DATA TO SHOW
                            </Typography>
                          </Box>
                        ) : (
                          <PieChartData
                            zero={stats['0-100k']}
                            hundred={stats['100k-499k']}
                            five100={stats['500k-1M']}
                            oneM={stats['1M-1.49M']}
                            onepoint5={stats['1.5M-1.99M']}
                            twoM={stats['2M-2.49M']}
                            two5plus={stats['2.5M+']}
                          />
                        );
                      })}
                    {COLORS &&
                      COLORS.map((data) => (
                        <ColorChart key={data.name} name={data.name} color={data.color} />
                      ))}
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Container>
    </Page>
  );
};

export default NodeDashboard;
