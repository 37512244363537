import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Button,
  Divider
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DateTimePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Page from 'components/Page';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useCoreTableState, useHttpApi, useWeb3State } from 'state';
import { useSnackbar } from 'notistack';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useAppState } from 'state/useAppState';
import { useNavigate } from 'react-router';
import { format, getTime } from 'date-fns';
import { titleCase } from 'helpers/text';

// Convert date to epoch
const toEpoch = (date) => {
  // create a new javascript Date object using timezone as UTC
  const d = new Date(date);
  const utcDate = new Date(d.toUTCString());
  const epoch = utcDate.getTime() / 1000;
  return epoch;
};
const toUTCDateTime = (d) => {
  const date = new Date(d);

  return date.toISOString();
};
const CreateToken = () => {
  const {
    rewardFrequency,
    paymentTokens,
    fetchAllPaymentTokens,
    fetchAllKycProviders,
    kycProviders
  } = useCoreTableState();
  const { getOwner, account } = useWeb3State();
  const { pools, fetchAllPools } = useBusinessEntityState();
  const { getLiquidityPoolsWithoutToken, deleteToken } = useHttpApi();
  const [liquidityPools, setLiquidityPools] = useState([]);
  const _fetchLiquidityPool = async () => {
    const _liquidityPools = await getLiquidityPoolsWithoutToken();
    setLiquidityPools(_liquidityPools);
    console.log('pools hi', liquidityPools);
  };

  const toOnlyDate = (d) => {
    const date = new Date(d);
    return format(date, 'yyyy-MM-dd');
  };

  useEffect(() => {
    fetchAllPaymentTokens();
    fetchAllKycProviders();
    fetchAllPools();
    _fetchLiquidityPool();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { createToken, checkTokenNameAndSymbol } = useHttpApi();
  const { publishToken } = useWeb3State();
  const { enqueueSnackbar } = useSnackbar();
  const contractDoc = useRef(null);
  const navigate = useNavigate();
  const { throwErrorMessage } = useAppState();
  const TokenSchema = Yup.object().shape({
    name: Yup.string()
      .required('Token Name is required')
      .min(3, "Token Name can't be less than 3 characters")
      .max(18, "Token Name can't be more than 18 characters"),
    symbol: Yup.string()
      .required('Token Symbol is required')
      .min(3, "Token Symbol can't be less than 3 characters")
      .max(18, "Token Symbol can't be more than 18 characters"),
    max_supply: Yup.number().required('Max Supply is required'),
    issue_size: Yup.number().required('Issue Size is required'),
    minimum_subscription: Yup.number().required('Minimum Subscription is required'),
    settlement_cycle_days: Yup.number().required('Settlement Cycle Days is required'),
    maturity_date: Yup.date()
      .required('Maturity Date is required')
      .min(new Date(), 'Maturity Date should be greater than today'),
    subscription_end_date: Yup.date()
      .required('Subscription End Date is required')
      .min(new Date(), 'Subscription End Date should be greater than today'),
    reward_frequency: Yup.string().required('Reward Frequency is required'),
    redeem_ioi_date: Yup.date().required('Redeem IOI Date is required'),
    redeem_ioi_end_date: Yup.date().required('Redeem IOI End Date is required'),
    value: Yup.number().required('Value is required'),
    contract_doc_hash: Yup.string().required('Document is required'),
    pool: Yup.string().required('Pool is required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      symbol: '',
      max_supply: '10000000',
      issue_size: '10000000',
      minimum_subscription: '1',
      settlement_cycle_days: '2',
      maturity_date: '',
      subscription_end_date: '',
      reward_frequency: '',
      redeem_ioi_date: '',
      redeem_ioi_end_date: '',
      value: '1',
      contract_doc_hash: '',
      pool: ''
    },
    validationSchema: TokenSchema,
    validate: (values) => {
      const errors = {};
      // add file size validation

      if (values.contract_doc_hash) {
        if (contractDoc && contractDoc.current.files[0].size > 2000000) {
          errors.contract_doc_hash = 'File size greater than 2MB';
        }
      }

      if (values.maturity_date && values.subscription_end_date) {
        if (values.maturity_date < values.subscription_end_date) {
          errors.subscription_end_date = 'Subscription End Date should be less than Maturity Date';
        }
      }
      if (values.maturity_date && values.redeem_ioi_date) {
        if (values.maturity_date <= values.redeem_ioi_date) {
          errors.redeem_ioi_date = 'Redeem IOI Date should be less than Maturity Date';
        }
      }
      if (values.maturity_date && values.redeem_ioi_end_date) {
        if (values.maturity_date <= values.redeem_ioi_end_date) {
          errors.redeem_ioi_end_date = 'Redeem IOI End Date should be less than Maturity Date';
        }
      }

      if (values.subscription_end_date && values.redeem_ioi_end_date) {
        if (values.redeem_ioi_end_date < toOnlyDate(values.subscription_end_date)) {
          errors.redeem_ioi_end_date =
            'Redeem IOI End Date should not be less than Subscription End Date';
        }
      }
      if (values.subscription_end_date && values.redeem_ioi_date) {
        if (values.redeem_ioi_date < toOnlyDate(values.subscription_end_date)) {
          errors.redeem_ioi_date = 'Redeem IOI  Date should not be less than Subscription End Date';
        }
      }

      if (values.redeem_ioi_date && values.redeem_ioi_end_date) {
        if (values.redeem_ioi_end_date < values.redeem_ioi_date) {
          errors.redeem_ioi_end_date = 'Redeem IOI End Date should be greater than Redeem IOI Date';
        }
      }

      if (values.name) {
        if (values.name.length > 50) {
          errors.name = 'Token Name is too long';
        }
      }

      if (values.symbol) {
        if (values.symbol.length > 20) {
          errors.symbol = 'Token Symbol is too long';
        }
      }

      if (values.minimum_subscription) {
        if (values.max_supply % values.minimum_subscription !== 0) {
          errors.minimum_subscription = 'Minimum Subscription should be a factor of max supply';
        }
      }

      return errors;
    },
    onSubmit: async (data, { resetForm }) => {
      let token;
      try {
        const contractOwner = await getOwner();
        if (contractOwner !== account) {
          throw new Error('You are not the owner of the contract');
        }
        const dataToken = {
          token_name: data.name,
          token_symbol: data.symbol
        };
        await checkTokenNameAndSymbol(dataToken);
        console.log(data);
        const covertedValue = parseInt(data.value, 10) * 10 ** 18;
        const contractDocFile = contractDoc.current.files[0];
        data.contract_doc_hash = contractDocFile;
        const pool = liquidityPools.find((pool) => pool.id === data.pool);

        if (pool?.deployment_address === '0x0000000000000000000000000000000000000000') {
          enqueueSnackbar('Pool Deployment Address Not Available', { variant: 'error' });
          return;
        }
        const subscriptionEndDate = toEpoch(data.subscription_end_date);
        const maturityDate = toEpoch(data.maturity_date);

        data.maturity_date = toUTCDateTime(data.maturity_date);
        data.subscription_end_date = toUTCDateTime(data.subscription_end_date);
        token = await createToken(data);
        const res = await publishToken(
          data.name,
          data.symbol,
          `${covertedValue}`,
          data.max_supply,
          kycProviders[0].address,
          data.minimum_subscription,
          [paymentTokens[0].address],
          `${subscriptionEndDate}`,
          `${maturityDate}`,
          [],
          [false, false, false],
          false,
          pool.deployment_address
        );

        formik.setFieldValue('contract_doc_hash', '');
        resetForm();
        navigate('/admin', { replace: true });
        enqueueSnackbar(`Token Created Successfully!`, {
          variant: 'success'
        });
        navigate('/admin');
      } catch (e) {
        formik.setFieldValue('contract_doc_hash', '');
        throwErrorMessage(e);
        if (token.id) {
          await deleteToken(token.id);
        }
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  useEffect(() => {
    if (values.max_supply && values.value) {
      formik.setFieldValue('issue_size', values.max_supply * values.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.max_supply, values.value]);
  return (
    <Page sx={{ mt: 3 }}>
      <Container>
        <Card className="form-card">
          <Container>
            <Typography
              sx={{
                fontSize: '1.125rem',
                fontWeight: 'bold',
                mt: 4,
                mb: 1,
                ml: 4
              }}
            >
              Create Token
            </Typography>
            <Divider
              sx={{
                width: '100%',
                backgroundColor: '#f5f8fa',
                border: '2px solid #f5f8fa'
              }}
            />
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <CardContent sx={{ m: 2 }}>
                  <Grid container spacing={3} sx={{ width: '100%' }}>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Node Token Name</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('name')}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Token Symbol</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="text"
                        {...getFieldProps('symbol')}
                        error={Boolean(touched.symbol && errors.symbol)}
                        helperText={touched.symbol && errors.symbol}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Max Supply</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="number"
                        inputProps={{ readOnly: true }}
                        {...getFieldProps('max_supply')}
                        error={Boolean(touched.max_supply && errors.max_supply)}
                        helperText={touched.max_supply && errors.max_supply}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Token Value (XDC)</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="number"
                        inputProps={{ readOnly: true }}
                        {...getFieldProps('value')}
                        error={Boolean(touched.value && errors.value)}
                        helperText={touched.value && errors.value}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Issue Size</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="number"
                        inputProps={{ readOnly: true }}
                        {...getFieldProps('issue_size')}
                        error={Boolean(touched.issue_size && errors.issue_size)}
                        helperText={touched.issue_size && errors.issue_size}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Minimum Subscription</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="number"
                        {...getFieldProps('minimum_subscription')}
                        error={Boolean(touched.minimum_subscription && errors.minimum_subscription)}
                        helperText={touched.minimum_subscription && errors.minimum_subscription}
                      />
                    </Grid>
                    {/* <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Settlement Cycle (Days)</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="number"
                        {...getFieldProps('settlement_cycle_days')}
                        error={Boolean(
                          touched.settlement_cycle_days && errors.settlement_cycle_days
                        )}
                        helperText={touched.settlement_cycle_days && errors.settlement_cycle_days}
                      />
                    </Grid> */}
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Maturity Date</FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {/* <DateTimePicker
                          disablePast
                          value={formik.values.maturity_date}
                          onChange={(newDate) => {
                            formik.setFieldValue(
                              'maturity_date',
                              format(newDate, 'yyyy-MM-dd HH:mm:ss')
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              size="small"
                              {...params}
                              {...getFieldProps('maturity_date')}
                              id="maturity_date"
                              error={Boolean(touched.maturity_date && errors.maturity_date)}
                              helperText={touched.maturity_date && errors.maturity_date}
                            />
                          )}
                        /> */}
                        <TextField
                          type="datetime-local"
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ mt: 1 }}
                          fullWidth
                          size="small"
                          {...getFieldProps('maturity_date')}
                          id="maturity_date"
                          inputProps={{
                            max: '9999-12-31T23:59', // Set the maximum date and time with a 4-digit year
                            min: '1000-01-01T00:00' // Set the minimum date and time with a 4-digit year
                          }}
                          error={Boolean(touched.maturity_date && errors.maturity_date)}
                          helperText={touched.maturity_date && errors.maturity_date}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Subscription End Date</FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {/* <DateTimePicker
                          disablePast
                          value={formik.values.subscription_end_date}
                          onChange={(newDate) => {
                            formik.setFieldValue(
                              'subscription_end_date',
                              format(newDate, 'yyyy-MM-dd HH:mm:ss')
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              size="small"
                              {...params}
                              {...getFieldProps('subscription_end_date')}
                              id="subscription_end_date"
                              error={Boolean(
                                touched.subscription_end_date && errors.subscription_end_date
                              )}
                              helperText={
                                touched.subscription_end_date && errors.subscription_end_date
                              }
                            />
                          )}
                        /> */}
                        <TextField
                          type="datetime-local"
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ mt: 1 }}
                          fullWidth
                          size="small"
                          {...getFieldProps('subscription_end_date')}
                          id="subscription_end_date"
                          inputProps={{
                            max: '9999-12-31T23:59', // Set the maximum date and time with a 4-digit year
                            min: '1000-01-01T00:00' // Set the minimum date and time with a 4-digit year
                          }}
                          error={Boolean(
                            touched.subscription_end_date && errors.subscription_end_date
                          )}
                          helperText={touched.subscription_end_date && errors.subscription_end_date}
                        />
                      </LocalizationProvider>
                    </Grid>

                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Redeem IOI Date</FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {/* <DesktopDatePicker
                          disablePast
                          value={formik.values.redeem_ioi_date}
                          onChange={(newDate) => {
                            formik.setFieldValue('redeem_ioi_date', format(newDate, 'yyyy-MM-dd'));
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              size="small"
                              {...params}
                              {...getFieldProps('redeem_ioi_date')}
                              id="redeem_ioi_date"
                              error={Boolean(touched.redeem_ioi_date && errors.redeem_ioi_date)}
                              helperText={touched.redeem_ioi_date && errors.redeem_ioi_date}
                            />
                          )}
                        /> */}
                        <TextField
                          type="date"
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ mt: 1 }}
                          fullWidth
                          size="small"
                          {...getFieldProps('redeem_ioi_date')}
                          id="redeem_ioi_date"
                          inputProps={{
                            max: '9999-12-31T23:59', // Set the maximum date and time with a 4-digit year
                            min: '1000-01-01T00:00' // Set the minimum date and time with a 4-digit year
                          }}
                          error={Boolean(touched.redeem_ioi_date && errors.redeem_ioi_date)}
                          helperText={touched.redeem_ioi_date && errors.redeem_ioi_date}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Redeem IOI End Date</FormLabel>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {/* <DesktopDatePicker
                          disablePast
                          value={formik.values.redeem_ioi_end_date}
                          onChange={(newDate) => {
                            formik.setFieldValue(
                              'redeem_ioi_end_date',
                              format(newDate, 'yyyy-MM-dd')
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ mt: 1 }}
                              fullWidth
                              size="small"
                              {...params}
                              {...getFieldProps('redeem_ioi_end_date')}
                              id="redeem_ioi_end_date"
                              error={Boolean(
                                touched.redeem_ioi_end_date && errors.redeem_ioi_end_date
                              )}
                              helperText={touched.redeem_ioi_end_date && errors.redeem_ioi_end_date}
                            />
                          )}
                        /> */}
                        <TextField
                          type="date"
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ mt: 1 }}
                          fullWidth
                          size="small"
                          {...getFieldProps('redeem_ioi_end_date')}
                          id="redeem_ioi_end_date"
                          inputProps={{
                            max: '9999-12-31T23:59', // Set the maximum date and time with a 4-digit year
                            min: '1000-01-01T00:00' // Set the minimum date and time with a 4-digit year
                          }}
                          error={Boolean(touched.redeem_ioi_end_date && errors.redeem_ioi_end_date)}
                          helperText={touched.redeem_ioi_end_date && errors.redeem_ioi_end_date}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Reward Frequency</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('reward_frequency')}
                          id="reward_frequency"
                          error={Boolean(touched.reward_frequency && errors.reward_frequency)}
                          helperText={touched.reward_frequency && errors.reward_frequency}
                        >
                          {rewardFrequency &&
                            rewardFrequency.map((reward) => {
                              return (
                                <MenuItem key={reward.name} value={reward.name}>
                                  {titleCase(reward.name)}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.reward_frequency && errors.reward_frequency}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Contract Document</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        type="file"
                        name="contract_doc_hash"
                        inputProps={{ accept: '.pdf' }}
                        inputRef={contractDoc}
                        onChange={(event) => {
                          formik.setFieldValue('contract_doc_hash', event.target.files[0]);
                        }}
                        id="contract_doc_hash"
                        error={Boolean(touched.contract_doc_hash && errors.contract_doc_hash)}
                        helperText={touched.contract_doc_hash && errors.contract_doc_hash}
                      />
                      <Grid container sx={{ width: '100%', mt: 2 }}>
                        <Grid item lg={4} md={4} sm={4}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'left',
                              color: '#161c2d'
                            }}
                          >
                            Maximum 2MB file size
                          </Typography>
                        </Grid>
                        <Grid item lg={8} md={8} sm={8}>
                          <Typography
                            sx={{
                              fontSize: '0.688rem',
                              textAlign: 'right',
                              color: '#8e8ea7'
                            }}
                          >
                            Accepted File Type .doc, .docx, and .pdf only
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Pools</FormLabel>
                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          inputProps={{ 'aria-label': 'Without label' }}
                          {...getFieldProps('pool')}
                          id="pool"
                          error={Boolean(touched.pool && errors.pool)}
                          helperText={touched.pool && errors.pool}
                        >
                          {liquidityPools &&
                            liquidityPools.map((stakepool) => {
                              return (
                                <MenuItem key={stakepool.id} value={stakepool.id}>
                                  {stakepool.name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.pool && errors.pool}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      mt: 4,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      sx={{
                        margin: 1,
                        borderColor: '#24ABDF',
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        navigate('/admin');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      type="submit"
                      variant="gradient"
                      // disabled={!onlyOwner(role)}
                      fullWidth
                      size="large"
                      loading={isSubmitting}
                      sx={{
                        margin: 1,
                        px: 3,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                    >
                      Save
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Form>
            </FormikProvider>
          </Container>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateToken;
