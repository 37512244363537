import { LoadingButton } from '@mui/lab';
import { Grid, FormLabel, TextField, Button, Checkbox, Box, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useHttpApi, useWeb3State } from 'state';
import { color } from '@mui/system';

const ResignToken = ({ currentToken }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateToken } = useHttpApi();

  return (
    <Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          mt: 2
        }}
      >
        <Grid item lg={12} md={12} xs={12}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 'bold',
              color: '#ff0000'
            }}
          >
            Resign Token(Token will be declared as closed, all the investors will be able to
            withdraw)
          </Typography>
          <Typography
            variant="h5"
            sx={{
              mt: 2,
              fontWeight: 'bold'
            }}
          >
            Current Status: {currentToken.resign_token ? 'Resigned' : 'Active'}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          width: '100%',
          mt: 2,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end'
        }}
      >
        <Grid item lg={12} md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LoadingButton
            loadingPosition="start"
            variant="gradient"
            type="button"
            sx={{ mt: 3, height: '2.6rem', width: '7.813rem' }}
            onClick={async () => {
              try {
                await updateToken(currentToken.id, { resign_token: true, issueOpen: false });
                enqueueSnackbar('Token Resigned!', { variant: 'success' });
              } catch (e) {
                console.log(e);
                if (e.message) {
                  enqueueSnackbar(e.message, { variant: 'error' });
                }
              }
            }}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ResignToken;
