import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import Accordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { useCoreTableState } from 'state';
import ReactMarkdown from 'react-markdown';
import { Container } from '@mui/material';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const FAQ = () => {
  const { faqs, fetchAllFaqs } = useCoreTableState();

  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1)
    }
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
  }));

  const QuesAns = ({ id, question, answer }) => {
    return (
      <Accordion
        disableGutters
        elevation={0}
        square
        sx={{
          bgcolor: 'transparent'
        }}
        expanded={expanded === id}
        onChange={handleChange(id)}
      >
        <AccordionSummary aria-controls="panel1d-content" id={id}>
          <Typography fontWeight="bold">{question}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <ReactMarkdown
              escapeHtml={false}
              rehypePlugins={[rehypeRaw]}
              linkTarget="_blank"
              remarkPlugins={[gfm]}
            >
              {answer}
            </ReactMarkdown>
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  };

  useEffect(() => {
    fetchAllFaqs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      sx={{
        mt: 10
      }}
    >
      {faqs.map((faq) => {
        return <QuesAns key={faq.id} id={faq.id} question={faq.question} answer={faq.answer} />;
      })}
    </Container>
  );
};

export default FAQ;
