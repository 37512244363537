import { AppBar, Box, Container, Button, IconButton, Tooltip } from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import HomeIcon from '@mui/icons-material/Home';
import PieChartIcon from '@mui/icons-material/PieChart';
import ListIcon from '@mui/icons-material/List';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import HelpIcon from '@mui/icons-material/Help';

const SideBar = ({ location }) => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: '100%',
        pt: 2
      }}
    >
      <Tooltip title="Home">
        <NavLink
          className="nav-link"
          style={({ isActive }) => ({
            color: isActive && '#ffffff',
            backgroundColor: isActive && '#4b92f0'
          })}
          to="/"
        >
          <HomeIcon sx={{ fontSize: '2rem' }} />
        </NavLink>
      </Tooltip>
      <Tooltip title="Node Dashboard">
        <NavLink
          className="nav-link"
          style={() => ({
            color: location.pathname.startsWith('/node-dashboard/') && '#ffffff',
            backgroundColor: location.pathname.startsWith('/node-dashboard/') && '#4b92f0'
          })}
          to="/node-dashboard/:token"
        >
          <PieChartIcon sx={{ fontSize: '2rem' }} />
        </NavLink>
      </Tooltip>
      <Tooltip title="Portfolio">
        <NavLink
          className="nav-link"
          style={({ isActive }) => ({
            color: isActive && '#ffffff',
            backgroundColor: isActive && '#4b92f0'
          })}
          to="/investor-dashboard"
        >
          <ListIcon sx={{ fontSize: '2rem' }} />
        </NavLink>
      </Tooltip>
      <Tooltip title="Swap">
        <NavLink
          className="nav-link"
          style={({ isActive }) => ({
            color: isActive && '#ffffff',
            backgroundColor: isActive && '#4b92f0'
          })}
          to="/swap"
        >
          <SwapHorizIcon
            sx={{
              borderRadius: 30,
              fontSize: '2rem'
            }}
          />
        </NavLink>
      </Tooltip>
      <Box sx={{ flexGrow: 1 }} />
      <Tooltip title="FAQ">
        <NavLink
          className="nav-link"
          style={({ isActive }) => ({
            color: isActive && '#ffffff',
            backgroundColor: isActive && '#4b92f0'
          })}
          to="/faq"
        >
          <HelpIcon sx={{ fontSize: '2rem' }} />
        </NavLink>
      </Tooltip>
    </Container>
  );
};

export default SideBar;
