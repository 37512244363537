import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import MainNavbar from '../components/MainNavbar';
import WalletRequireOverlay from 'components/Wallet/WalletRequireOverlay';
import SideBar from 'components/SideBar';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
// import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import { Button } from '@mui/material';
// import Toolbar from '@mui/material/Toolbar';

const drawerWidth = 80;

const MainLayout = () => {
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const resize = () => {
    const currentHideNav = window.outerWidth;

    console.log('CurrentScreenWidth', currentHideNav);

    // Are we mobile?
    if (currentHideNav <= 600) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    resize();
    window.addEventListener('resize', resize);
    // console.log('CurrentScreenWidth', hideNav);
  }, []);

  return (
    <>
      <MainNavbar />
      <Box sx={{ display: 'flex', flex: 1, position: 'relative' }}>
        {/* <CssBaseline /> */}
        {location.pathname !== '/login' &&
          !location.pathname.startsWith('/forgot-password') &&
          location.pathname !== '/request-forgot-password' && (
            <Drawer
              BackdropProps={{ invisible: true }}
              elevation={0}
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box'
                }
              }}
              open={open}
              variant={open ? 'persistent' : 'temporary'}
              anchor="left"
            >
              {/* <Toolbar /> */}
              <SideBar location={location} />
            </Drawer>
          )}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {location.pathname !== '/' && location.pathname !== '/faq' && <WalletRequireOverlay />}
          <Outlet />
          {/* <Toolbar /> */}
        </Box>
        {/* <Footer /> */}
      </Box>
      <Footer />
    </>
  );
};

export default MainLayout;
