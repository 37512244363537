import EnhancedTable from '../../tables/EnhancedTable';
import React, { useMemo, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useBusinessEntityState, useCoreTableState } from 'state';
import AddressFieldTools from 'components/AddressFieldTools';
import EscrowButtonTradeToken from 'components/EscrowButtonTradeToken';
import ManageToken from 'components/ManageToken';
import RadioTokenTable from 'components/tables/RadioTokenTable';
import DistributeRewards from '../manage-token/DistributeRewards';

export default function TokenTable() {
  const { tokens, fetchAllTokens } = useCoreTableState();
  const { currentSelectedToken, setCurrentSelectedTokenFunction } = useBusinessEntityState();

  const updateData = async () => {
    fetchAllTokens();
  };

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells = useMemo(
    () => [
      {
        accessor: 'name',
        Header: 'Token Name',
        show: true,
        width: 100
      },
      {
        accessor: 'symbol',
        Header: 'Token Symbol',
        width: 100,
        show: true
      },
      {
        accessor: 'pool_name',
        Header: 'Node Name',
        width: 100,
        show: true
      },
      {
        accessor: 'redeem_pressure',
        Header: 'Redeem Pressure',
        show: true,
        width: 80
      },
      {
        accessor: 'deployment_address',
        Header: 'Address',
        show: true,
        Cell: ({ value, row: { original } }) => {
          return (
            <AddressFieldTools
              decimals={0}
              symbol={original.symbol}
              address={value}
              showCopyButton
              showAddToWallet
              showInBlockExplorer
            />
          );
        }
      }
      // {
      //   accessor: 'actions',
      //   Header: 'Actions',
      //   show: true,
      //   width: 200,
      //   Cell: ({ row: { original } }) => {
      //     return (
      //       <Box display="flex">
      //         <EscrowButtonTradeToken original={original} />
      //         <ManageToken original={original} />
      //       </Box>
      //     );
      //   }
      // }
    ],
    []
  );

  return (
    <>
      <Grid className="form-card">
        <RadioTokenTable
          tableTitle="Token Table"
          columns={headCells}
          refreshFunction={updateData}
          setSelectedRowsToState={setCurrentSelectedTokenFunction}
          data={tokens}
        />
        {currentSelectedToken === null || currentSelectedToken?.length === 0 ? null : (
          <Box
            display="flex"
            justifyContent="end"
            sx={{
              pt: 2
            }}
          >
            <EscrowButtonTradeToken original={currentSelectedToken[0]} />
            <DistributeRewards token={currentSelectedToken[0]} />
            <ManageToken original={currentSelectedToken[0]} />
          </Box>
        )}
      </Grid>
    </>
  );
}
