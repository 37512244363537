import EnhancedTable from '../../tables/EnhancedTable';
import React, { useMemo, useEffect } from 'react';
import { Button, Box } from '@mui/material';
import { useBusinessEntityState, useInvestmentsState } from 'state';
import GetIssuanceStatus from 'components/GetIssuanceStatus';
import GetRedeemptionStatus from 'components/GetRedeemptionStatus';
import SwapIssuance from 'components/SwapIssuance';
import SwapRedeemption from 'components/SwapRedeemption';
import EscrowButtonPool from 'components/EscrowButtonPool';
import WrapXDC from '../pool/WrapXDC';

export default function SettlementsTable() {
  const { escrowOrders, fetchAllEscrowOrders } = useBusinessEntityState();
  // const { account, approveWxdc, paymentTokenGiveAllowance } = useWeb3State();
  useEffect(() => {
    fetchAllEscrowOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headCells = useMemo(
    () => [
      {
        accessor: 'token_name',
        Header: 'Token Name',
        show: true,
        width: 100
      },
      {
        accessor: 'pool_name',
        Header: 'Node Name',
        show: true,
        width: 80
      },
      // {
      //   accessor: 'investor_name',
      //   Header: 'Investor Name',
      //   show: true,
      //   width: 100
      // },

      {
        accessor: 'trade_token_amount',
        Header: 'Qty',
        show: true,
        width: 60
      },
      {
        accessor: 'order_type',
        Header: 'Order Type',
        show: true,
        width: 60
      },
      {
        accessor: 'conditions',
        Header: 'Conditions',
        show: true,
        width: 80,
        Cell: ({ row: { original } }) => {
          return original.order_type === 'BUY' ? (
            <GetIssuanceStatus orderId={original.escrow_order_id} nonce={Math.random()} />
          ) : (
            <GetRedeemptionStatus orderId={original.escrow_order_id} nonce={Math.random()} />
          );
        }
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        show: true,
        width: 100,
        Cell: ({ row: { original } }) => {
          const isIssuanceEscrow = original.order_type === 'BUY';
          return (
            <Box>
              {isIssuanceEscrow && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    width: '100%'
                  }}
                >
                  <Box sx={{ flexGrow: 1.2 }} />
                  <SwapIssuance original={original} />
                </Box>
              )}
              {!isIssuanceEscrow && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                  }}
                >
                  <SwapRedeemption original={original} />
                  {/* <EscrowButtonPool original={original} />
                  <WrapXDC poolAddress={original.pool_address} /> */}
                </Box>
              )}
            </Box>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Holdings"
        columns={headCells}
        data={escrowOrders}
        refreshFunction={() => fetchAllEscrowOrders()}
      />
    </>
  );
}
