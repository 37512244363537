import AuthRequired from 'components/AuthRequired';
import AdminLayout from 'layouts/AdminLayout';
import Home from 'pages/Investor/Home';
import Login from 'pages/Admin/Login';
import AddKycProvider from 'pages/Admin/AddKycProvider';
import AddLiquidityPool from 'pages/Admin/pool/AddLiquidityPool';
import CreateToken from 'pages/Admin/CreateToken';
import PageNotFound from 'pages/PageNotFound';
import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import AdminDashboard from './pages/AdminDashboard';
import AllLiquidityPool from 'pages/Admin/pool/AllLiqudityPool';
import CreateProfile from 'pages/Investor/CreateProfile';
import InvestToken from 'pages/Investor/InvestToken';
import LoadingInvestorAccount from 'components/Investor/LoadingInvestorAccount';
import InvestorDashboard from 'pages/Investor/InvestorDashboard';
import AllTokens from 'pages/Admin/AllTokens';
import AllTokenWhitelist from 'pages/Admin/AllTokenWhitelist';
import AllSettlemets from 'pages/Admin/AllSettlements';
import NodeDashboard from 'pages/Investor/NodeDashboard';
import AllRewardHistory from 'pages/Admin/AllRewardHistory';
import RequestForgotPassword from 'pages/RequestForgotPassword';
import ForgotPassword from 'pages/ForgotPassword';
import AllotmentsTable from 'components/Admin/Tables/AllotmentsTable';
import Allotments from 'pages/Admin/Allotments';
import ManageTokenDashboard from 'pages/Admin/ManageTokenDashboard';
import SwapDashboard from 'pages/Investor/SwapDashboard';
import FAQ from 'pages/FAQ';

const Router = () => {
  return useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <Home /> },
        { path: '/create-profile', element: <CreateProfile /> },
        { path: '/login', element: <Login /> },
        { path: '/buy-token', element: <InvestToken /> },
        { path: '/loading-account', element: <LoadingInvestorAccount /> },
        { path: '/request-forgot-password', element: <RequestForgotPassword /> },
        { path: '/forgot-password/:token', element: <ForgotPassword /> },
        { path: '/investor-dashboard', element: <InvestorDashboard /> },
        { path: '/node-dashboard/:tname', element: <NodeDashboard /> },
        { path: '/swap', element: <SwapDashboard /> },
        { path: '/faq', element: <FAQ /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/admin',
      element: (
        <AuthRequired>
          <AdminLayout />
        </AuthRequired>
      ),
      children: [
        { path: '', element: <AdminDashboard /> },
        { path: 'add-pool', element: <AddLiquidityPool /> },
        { path: 'pools', element: <AllLiquidityPool /> },
        { path: 'kyc', element: <AddKycProvider /> },
        { path: 'create-token', element: <CreateToken /> },
        { path: 'tokens', element: <AllTokens /> },
        { path: 'whitelist-manager', element: <AllTokenWhitelist /> },
        { path: 'settlements', element: <AllSettlemets /> },
        { path: 'rewards', element: <AllRewardHistory /> },
        { path: 'allotments', element: <Allotments /> },
        { path: 'manage-token', element: <ManageTokenDashboard /> }
      ]
    },
    { path: '404', element: <PageNotFound /> },
    { path: '*', element: <Navigate to="/404" /> }
  ]);
};

export default Router;
