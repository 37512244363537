import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useAppState, useWeb3State } from 'state';
import { ref } from 'yup';
import RefreshInvestors from './RefreshInvestors';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  borderRadius: '0.5rem',
  boxShadow: 24,
  p: 4
};
const DistributeRewards = ({ token }) => {
  const { refreshInvestors, checkInvestorRefresh, sendEth, account } = useWeb3State();
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [refreshedRecently, setRefreshedRecently] = React.useState(false);

  const handleClear = () => {
    setOpen(false);
    setAmount(0);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await checkInvestorRefresh(token?.deployment_address);
        console.log(res);
        setRefreshedRecently(res);
      } catch (e) {
        console.log(e);
      }
    };
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [token?.deployment_address, checkInvestorRefresh]);

  return (
    <Box>
      <Button sx={{ m: 0.5 }} variant="contained" color="primary" onClick={() => setOpen(true)}>
        Distribute Rewards
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          {refreshedRecently ? (
            <>
              <Typography variant="h6" component="div" gutterBottom>
                Enter Reward Amount to Distribute
              </Typography>
              <TextField size="small" type="number" onBlur={(e) => setAmount(e.target.value)} />
              <Box>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={account !== token?.node_owner}
                  sx={{ mt: 2 }}
                  onClick={async () => {
                    try {
                      console.log('SENDING ETH');
                      if (amount <= 0) {
                        throw new Error('Please enter a valid amount greater than 0');
                      }
                      await sendEth(token?.pool_address, amount);
                      enqueueSnackbar('Reward Distributed successfully', {
                        variant: 'success'
                      });
                      handleClear();
                    } catch (e) {
                      console.log(e);
                      handleClear();
                      enqueueSnackbar(e.message, { variant: 'error' });
                    }
                  }}
                >
                  Send Reward
                </Button>
                {account !== token?.node_owner && (
                  <Typography variant="body2" component="div" gutterBottom sx={{ mt: 2 }}>
                    Please connect with owner of the node to distribute rewards
                  </Typography>
                )}
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h5" component="div" gutterBottom>
                Please refresh investor list before distributing node reward
              </Typography>
              <RefreshInvestors
                currentToken={{
                  deployment_address: token?.deployment_address
                }}
              />
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default DistributeRewards;
