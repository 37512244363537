import './App.css';
import Router from './routes';
import React from 'react';
import ThemeConfig from './theme';
import { GlobalAppStateProvider } from 'state';
import { Web3ReactProvider } from '@web3-react/core';
import { getWeb3Library } from './helpers/web3';
import { SnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import { Web3OnboardProvider, init } from '@web3-onboard/react';
import { initWeb3Onboard } from 'config/onboard';

function App() {
  const notistackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <Web3OnboardProvider web3Onboard={initWeb3Onboard}>
      <Web3ReactProvider getLibrary={getWeb3Library}>
        <ThemeConfig>
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)}>
                <CancelIcon />
              </IconButton>
            )}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <GlobalAppStateProvider>
              <Router />
            </GlobalAppStateProvider>
          </SnackbarProvider>
        </ThemeConfig>
      </Web3ReactProvider>
    </Web3OnboardProvider>
  );
}

export default App;
