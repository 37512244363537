import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Divider,
  TablePagination,
  Card,
  Button,
  Radio,
  Typography
} from '@mui/material';
import TablePaginationActions from './TablePaginationActions';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PropTypes from 'prop-types';
import TableToolbar from './TableToolbar';
import Checkbox from '@mui/material/Checkbox';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFlexLayout,
  useResizeColumns
} from 'react-table';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  const { disabled } = rest;
  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Radio disabled={disabled} sx={{ m: 0, p: 0 }} size="small" ref={resolvedRef} {...rest} />
    </>
  );
});

const RadioTokenTable = ({
  columns,
  data,
  updateMyData,
  skipPageReset,
  tableTitle,
  refreshFunction,
  setSelectedRowsToState
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    toggleAllRowsSelected,
    toggleRowSelected,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter }
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      autoResetSelectedRows: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,

    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          minWidth: 100,
          width: '100px',
          maxWidth: 100,
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox.  Pagination is a problem since this will select all
          // rows even though not all rows are on the current page.  The solution should
          // be server side pagination.  For one, the clients should not download all
          // rows in most cases.  The client should only download data for the current page.
          // In that case, getToggleAllRowsSelectedProps works fine.
          Header: ({ getToggleAllRowsSelectedProps }) => <>Select</>,
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              onClick={() => {
                toggleAllRowsSelected(false);
                toggleRowSelected(row.id, true);
              }}
            />
          )
        },
        ...columns
      ]);
    }
  );

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAllSelectedRows = () => {
    const _selectedRows = [];
    Object.keys(selectedRowIds).forEach((key) => {
      _selectedRows.push(data[key]);
    });
    return _selectedRows;
  };

  // const [updateSelect, setUpdateSelect] = useState(getAllSelectedRows());

  // useMemo(() => setUpdateSelect(getAllSelectedRows()), [getAllSelectedRows]);

  // handle checkbox selection
  useEffect(() => {
    const res = getAllSelectedRows();
    setSelectedRowsToState(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  // Render the UI for your table
  return (
    <Box>
      <TableToolbar
        refreshFunction={refreshFunction}
        tableTitle={tableTitle}
        numSelected={Object.keys(selectedRowIds).length}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
      <TableContainer>
        <Table size="small" className="table" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow sx={{ m: 0, p: 0 }} key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    sx={{
                      color: '#8e8ea7'
                    }}
                    padding="normal"
                    key={column.id}
                    align={column.align}
                    {...column.getHeaderProps()}
                  >
                    <Box
                      {...column.getSortByToggleProps()}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        mr: 1
                      }}
                    >
                      {column.render('Header')}
                      {column.id !== 'selection' ? (
                        <TableSortLabel
                          active={column.isSorted}
                          // react-table has a unsorted state which is not treated here
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      ) : null}
                    </Box>

                    <Box
                      sx={{
                        display: 'inline-block',
                        width: '1.25rem', // 20px
                        height: '100%',
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        transition: 'translateX(50%)',
                        zIndex: '1'
                      }}
                      {...column.getResizerProps()}
                      className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                    >
                      <OpenInFullIcon
                        sx={{ fontSize: '0.875rem', mt: 1.5, transform: 'rotate(45deg)' }}
                      />
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, key) => {
                    return (
                      <TableCell
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          my: -0.3,
                          mb: 0
                        }}
                        padding="normal"
                        size="small"
                        key={key}
                        {...cell.getCellProps()}
                      >
                        <Box
                          sx={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden'
                          }}
                        >
                          {cell.render('Cell')}
                        </Box>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
                count={data.length}
                rowsPerPage={Number(pageSize)}
                page={pageIndex}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' }
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

RadioTokenTable.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func,
  skipPageReset: PropTypes.bool,
  refreshFunction: PropTypes.func.isRequired,
  setSelectedRowsToState: PropTypes.func.isRequired
};

export default RadioTokenTable;
