import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import { currentNetwork } from 'helpers/web3';
import React from 'react';
import { useAppState, useWeb3State } from 'state';
import { useSnackbar } from 'notistack';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const EscrowButtonTradeToken = ({ original }) => {
  const { approveTradeToken } = useWeb3State();
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        variant="outlined"
        color="primary"
        onClick={async () => {
          try {
            const res = await approveTradeToken(
              original.deployment_address,
              currentNetwork.escrowManagerAddress,
              `${original.max_supply}`
            );
            console.log('res', res);
            enqueueSnackbar(`Approved ${original.max_supply}  Tokens successfully`, {
              variant: 'success'
            });
          } catch (e) {
            console.log(e);
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        }}
      >
        Escrow Token
      </Button>
    </Box>
  );
};

export default EscrowButtonTradeToken;
