import EnhancedTable from 'components/tables/EnhancedTable';
import React, { useMemo, useEffect } from 'react';
import { Button, Box, Grid } from '@mui/material';
import { useInvestmentsState, useWeb3State } from 'state';
import { formatDecimal } from 'helpers/numbers';

export default function RewardsDistributionTable() {
  // const { getRewardsLogs, account } = useWeb3State();
  // const [rows, setRows] = React.useState([]);
  const { rewardsInvestor, fetchAllRewardsByInvestor } = useInvestmentsState();
  const [timezone, setTimezone] = React.useState('UTC');
  const { account } = useWeb3State();

  useEffect(() => {
    fetchAllRewardsByInvestor(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  useEffect(() => {
    setTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    console.log(timezone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  // const fetchData = async () => {
  //   if (pools.length > 0 && account) {
  //     setRows([]);
  //     pools.map(async (pool) => {
  //       const data = await getRewardsLogs(pool.pool_address);
  //       for (let i = 0; i < data.length; i += 1) {
  //         const log = {
  //           time: data[i][0],
  //           value: data[i][1],
  //         };
  //         setRows((prev) => [...prev, log]);
  //       }
  //     });
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, [account, pools]);

  // const fetchData = async () => {
  //   fetchAllRewardsByInvestor();
  // };

  const headCells = useMemo(
    () => [
      {
        accessor: 'reward_date',
        Header: 'Distribution Date',
        show: true,
        Cell: ({ value }) => {
          // Show date time in human readable format
          return new Date(value).toLocaleString('en', { timeZone: timezone });
        }
      },
      {
        accessor: 'token_name',
        Header: 'Token',
        show: true
      },
      {
        accessor: 'reward_amount',
        Header: 'Rewards(XDC)',
        show: true,
        Cell: ({ value }) => {
          return `${formatDecimal(value)} XDC`;
        }
      }
    ],
    [timezone]
  );

  return (
    <>
      <Grid>
        <EnhancedTable
          tableTitle="Holdings"
          columns={headCells}
          data={rewardsInvestor}
          refreshFunction={() => fetchAllRewardsByInvestor(account)}
        />
      </Grid>
    </>
  );
}
