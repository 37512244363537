import EnhancedTableCheck from 'components/tables/EnhancedTableCheck';
import React, { useMemo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  FormLabel
} from '@mui/material';
import {
  useAppState,
  useBusinessEntityState,
  useCoreTableState,
  useHttpApi,
  useWeb3State
} from 'state';
import { useSnackbar } from 'notistack';
import AddressFieldTools from 'components/AddressFieldTools';

export default function WhitelistManager() {
  const { investors, fetchAllInvestors } = useBusinessEntityState();
  const { addWhiteList, removeWhiteList } = useWeb3State();
  const { throwErrorMessage } = useAppState();
  const { addToWhitelist, removeFromWhitelist } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();

  const headCells = useMemo(
    () => [
      // {
      //   accessor: 'name',
      //   Header: 'Investor Name',
      //   show: true
      // },
      {
        accessor: 'xinfin_account',
        Header: 'Investor Xinfin Address',
        show: true,
        Cell: ({ value }) => {
          return (
            <AddressFieldTools address={value} showAddress showCopyButton showInBlockExplorer />
          );
        }
      },
      {
        accessor: 'kyc_status',
        Header: 'KYC Status',
        show: true
      },
      {
        accessor: 'is_whitelisted',
        Header: 'Whitelisted',
        show: true,
        Cell: ({ value }) => {
          return value ? 'Yes' : 'No';
        }
      }
    ],
    []
  );

  const [data, setData] = useState(investors);
  const updateData = async () => {
    setData(investors);
  };
  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [investors]);
  useEffect(() => {
    fetchAllInvestors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const [rowsSelected, setRowsSelected] = useState([]);
  const [filter, setFilter] = useState('all');
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  return (
    <>
      <Grid>
        <EnhancedTableCheck
          tableTitle="CurrentWhitelist"
          columns={headCells}
          refreshFunction={fetchAllInvestors}
          setSelectedRowsToState={setRowsSelected}
          data={data}
          setData={setData}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          isRoleAllowed
        />
      </Grid>
      <Grid>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
          <Button
            sx={{ mx: 1 }}
            color="error"
            variant="contained"
            onClick={async () => {
              try {
                const subscriptionsIds = rowsSelected.map((row) => row.id);
                const addressList = rowsSelected.map((row) => row.xinfin_account);
                const res = await removeWhiteList(addressList);
                if (res) {
                  await removeFromWhitelist({
                    investor_ids: subscriptionsIds
                  });
                  fetchAllInvestors();
                }
              } catch (e) {
                console.log(e);
                throwErrorMessage(e);
              }
            }}
          >
            Remove From Whitelist
          </Button>
          <Button
            sx={{ mx: 1 }}
            color="primary"
            variant="contained"
            onClick={async () => {
              try {
                const filteredSubscriptions = rowsSelected.filter(
                  (investor) => investor.kyc_status === 'VERIFIED'
                );
                if (rowsSelected.length !== filteredSubscriptions.length) {
                  enqueueSnackbar('Accounts which have not completed KYC will be excluded!', {
                    variant: 'info'
                  });
                }
                const subscriptionsIds = filteredSubscriptions.map((row) => row.id);
                const addressList = filteredSubscriptions.map((row) => row.xinfin_account);
                console.table(subscriptionsIds);
                console.table(addressList);
                if (filteredSubscriptions.length === 0) {
                  enqueueSnackbar('No accounts to add to whitelist', {
                    variant: 'error'
                  });
                  return;
                } else {
                  const res = await addWhiteList(addressList);
                  console.log(res);
                  if (res) {
                    const djangores = await addToWhitelist({
                      investor_ids: subscriptionsIds
                    });
                    fetchAllInvestors();
                  }
                }
              } catch (e) {
                console.log(e);
                throwErrorMessage(e);
              }
            }}
          >
            Add to Whitelist
          </Button>
        </Box>
      </Grid>
    </>
  );
}
