import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionLayout = ({ title, content, defaultExpanded }) => {
  return (
    <>
      <Accordion
        defaultExpanded={defaultExpanded}
        square
        sx={{
          background: ' #ffffff 0% 0% no-repeat padding-box',
          boxShadow: '0px 3px 20px #00000005',
          borderRadius: 4,
          px: 4,
          py: 1,
          mt: '29px'
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              fontSize: '1.125rem',
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>{content}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default AccordionLayout;
