import React, { useEffect, useState } from 'react';
import {
  Grid,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Card,
  Box,
  Typography,
  Container,
  ListItemIcon,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  IconButton
} from '@mui/material';
import xdcPayIcon from 'assets/images/icon-xdcpay.png';
import TollIcon from '@mui/icons-material/Toll';
import GradeIcon from '@mui/icons-material/Grade';
import CachedIcon from '@mui/icons-material/Cached';
import { useWeb3State, useInvestmentsState } from 'state';
import { intervalToDuration, formatDuration } from 'date-fns';
import DataNotAvailable from 'helpers/DataNotAvailable';
import { formatDecimal } from 'helpers/numbers';
import CountdownTimer from 'components/Investor/CountdownTimer';

export const DenseTable = () => {
  const { account } = useWeb3State();
  const { fetchAllAllotmentsByInvestor, allotmentsInvestor } = useInvestmentsState();

  useEffect(() => {
    fetchAllAllotmentsByInvestor(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <TableContainer
      sx={{
        mt: 1.5,
        maxHeight: 190,
        scrollbarColor: '#42a5f5 white',
        '&::-webkit-scrollbar': {
          width: 3
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'white'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#42a5f5',
          borderRadius: 2
        }
      }}
    >
      <Table stickyHeader size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', zIndex: 0, backgroundColor: '#fff' }}>
              Nodes
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', zIndex: 0, backgroundColor: '#fff' }}>
              Stake
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', zIndex: 0, backgroundColor: '#fff' }}>
              Contract Cycle Ends
            </TableCell>
          </TableRow>
        </TableHead>
        {allotmentsInvestor?.length > 0 ? (
          <TableBody>
            {allotmentsInvestor.map((alloted) => (
              <TableRow key={alloted.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{alloted.token_name}</TableCell>
                <TableCell>{alloted.total_alloted}</TableCell>
                <TableCell>
                  <CountdownTimer maturityDate={alloted.maturity_date} simple />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <TableBody>
            <TableRow>
              <TableCell align="center" colSpan={3}>
                <DataNotAvailable sx={{ width: 110 }} />
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  );
};

export const Stats = () => {
  const { account } = useWeb3State();
  const { fetchStatsByInvestor, statsInvestor } = useInvestmentsState();

  useEffect(() => {
    fetchStatsByInvestor(account);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  return (
    <Box>
      <ListItem>
        <ListItemAvatar>
          <TollIcon />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Typography
              variant="caption"
              fontWeight="bold"
              color="grey"
              display="block"
              gutterBottom
            >
              Total XDC Staked
            </Typography>
          }
          secondary={
            <Typography variant="subtitle2" display="block" gutterBottom>
              {statsInvestor.total_alloted == null
                ? 'No Stake'
                : formatDecimal(statsInvestor?.total_alloted)}
            </Typography>
          }
        />
      </ListItem>

      <ListItem>
        <ListItemAvatar>
          <GradeIcon />
        </ListItemAvatar>
        <ListItemText
          disableTypography
          primary={
            <Typography
              variant="caption"
              fontWeight="bold"
              color="grey"
              display="block"
              gutterBottom
            >
              XDC Rewards To Date
            </Typography>
          }
          secondary={
            <Typography variant="subtitle2" display="block" gutterBottom>
              {statsInvestor.total_rewards == null
                ? 'No rewards'
                : parseInt(statsInvestor?.total_rewards, 10)}
            </Typography>
          }
        />
      </ListItem>
    </Box>
  );
};

const MicroDashboard = () => {
  return (
    <>
      <Container maxWidth="md">
        <Card
          sx={{
            p: 2,
            display: { xs: 'none', sm: 'block' },
            borderRadius: 4,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)'
          }}
        >
          {/* <IconButton
          onClick={() => {
            updateData();
          }}
        >
          <CachedIcon />
        </IconButton> */}
          <Grid container>
            <Grid item xs={5}>
              <Box display="flex" alignItems="center">
                <ListItemIcon>
                  <Box
                    component="img"
                    src={xdcPayIcon}
                    alt="XDC Pay"
                    sx={{ width: '2.0rem', height: '2.0rem', m: 1 }}
                  />
                </ListItemIcon>
                <Typography fontWeight="bold" fontSize="1.5rem">
                  XDC
                </Typography>
              </Box>
              <Divider
                sx={{
                  mr: 5,
                  ml: 2
                }}
              />
              <Stats />
            </Grid>
            <Grid item xs={7}>
              <DenseTable />
            </Grid>
          </Grid>
        </Card>
      </Container>
      <Card
        sx={{
          p: 2,
          display: { xs: 'block', sm: 'none' },
          borderRadius: 4,
          boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)'
        }}
      >
        {/* <IconButton
          onClick={() => {
            updateData();
          }}
        >
          <CachedIcon />
        </IconButton> */}
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <ListItemIcon>
                <Box
                  component="img"
                  src={xdcPayIcon}
                  alt="XDC Pay"
                  sx={{ width: '2.0rem', height: '2.0rem', m: 1 }}
                />
              </ListItemIcon>
              <Typography fontWeight="bold" fontSize="1.5rem">
                XDC
              </Typography>
            </Box>
            <Divider />
            <Stats />
          </Grid>
          <Grid item xs={12}>
            <DenseTable />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default MicroDashboard;
