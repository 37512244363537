import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import React from 'react';
import { useSnackbar } from 'notistack';
import { useAppState, useWeb3State } from 'state';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const DepositXDC = () => {
  const { depositXDC } = useWeb3State();
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();

  const handleClear = () => {
    setOpen(false);
    setAmount(0);
  };

  return (
    <Box>
      <Button sx={{ m: 0.5 }} variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Deposit XDC
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Typography variant="h6" component="div" gutterBottom>
            Enter Amount
          </Typography>
          <TextField size="small" onBlur={(e) => setAmount(e.target.value)} />
          <Box>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mt: 2 }}
              onClick={async () => {
                try {
                  console.log('amount', amount);
                  const res = await depositXDC(amount);
                  console.log('res', res);
                  handleClear();
                  enqueueSnackbar('Deposit XDC successfully', {
                    variant: 'success'
                  });
                } catch (e) {
                  console.log(e);
                  handleClear();
                  enqueueSnackbar(e.message, { variant: 'error' });
                }
              }}
            >
              Deposit {amount} XDC
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DepositXDC;
