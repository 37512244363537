import { LoadingButton } from '@mui/lab';
import { Grid, FormLabel, TextField, Button, Checkbox, Switch, Typography } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useHttpApi, useWeb3State } from 'state';

const ToggleIssueOpen = ({ currentToken }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { updateToken } = useHttpApi();

  const MaturitySchema = Yup.object().shape({
    // old_maturity_date: Yup.string().required('Bar Number is required'),
    issueOpen: Yup.boolean()
  });

  const formik = useFormik({
    initialValues: {
      issueOpen: ''
    },
    validationSchema: MaturitySchema,
    onSubmit: async (data, { resetForm }) => {
      try {
        await updateToken(currentToken.id, { issueOpen: data.issueOpen });
        resetForm();
        if (data.issueOpen) {
          enqueueSnackbar('Token Available for investment', { variant: 'success' });
        } else {
          enqueueSnackbar('Token Closed for investment', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        if (e.message) {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      }
    }
  });

  // eslint-disable-next-line no-unused-vars
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  useEffect(() => {
    formik.setFieldValue('issueOpen', currentToken.issueOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken]);
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mt: 2
          }}
        >
          {currentToken.resign_token ? (
            <Grid item lg={12} md={12} xs={12}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 'bold'
                }}
              >
                You can not toggle token issuance as token is resigned
              </Typography>
            </Grid>
          ) : (
            <Grid item lg={6} md={6} xs={12}>
              <FormLabel>Toggle Token Issuance</FormLabel>
              <Switch
                {...getFieldProps('issueOpen')}
                checked={values.issueOpen}
                onChange={(e) => {
                  formik.setFieldValue('issueOpen', e.target.checked);
                }}
                color="primary"
                name="issueOpen"
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          sx={{
            width: '100%',
            mt: 2,
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end'
          }}
        >
          <Grid item lg={12} md={12} xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton
              loadingPosition="start"
              variant="gradient"
              type="submit"
              sx={{ mt: 3, height: '2.6rem', width: '7.813rem' }}
              loading={isSubmitting}
              disabled={currentToken.resign_token}
            >
              Submit
            </LoadingButton>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
};

export default ToggleIssueOpen;
