import React from 'react';
import { styled, Card, Typography, Box, Grid, Button, Paper } from '@mui/material';
import LoginForm from 'components/Admin/LoginForm';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  return (
    <Grid>
      <Paper
        sx={{
          padding: 5,
          height: '70vh',
          width: 500,
          margin: '20px auto',
          boxShadow: '0px 3px 20px #00000005'
        }}
      >
        <Typography variant="h4" sx={{ mb: 4, color: '#3449DD' }}>
          Login Form
        </Typography>

        <LoginForm />
        <Box
          sx={{
            mx: 'auto',
            p: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Button
            onClick={() => {
              navigate('/request-forgot-password');
            }}
          >
            Forgot Password?
          </Button>
        </Box>
      </Paper>
    </Grid>
  );
};

export default Login;
