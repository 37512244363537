import EnhancedTable from 'components/tables/EnhancedTable';
import React, { useMemo, useEffect, useState } from 'react';
import { Box, Chip, IconButton } from '@mui/material';
import { useInvestmentsState, useWeb3State } from 'state';
import MatureBalance from './MatureBalance';
import RedeemTvT from 'components/RedeemTvT';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { useSnackbar } from 'notistack';
import ClaimRewards from 'components/ClaimRewards';
import AddressFieldTools from 'components/AddressFieldTools';
import { formatDecimal, weiToEth } from 'helpers/numbers';

export default function HoldingsInvestor() {
  const { fetchAllAllotmentsByInvestor, allotmentsInvestor } = useInvestmentsState();
  const [data, setData] = useState([]);
  const { fetchUnClaimedRewards, account, matureBalance, web3 } = useWeb3State();
  const { enqueueSnackbar } = useSnackbar();
  const updateData = async () => {
    const _rows = await Promise.all(
      allotmentsInvestor.map(async (row) => {
        const condition = await fetchUnClaimedRewards(row.pool_address, row.investor_address);
        console.log(condition);
        const condition1 = await matureBalance(row.token_address);
        row.unclaimed_rewards = (
          <Chip
            label={`${formatDecimal(parseFloat(weiToEth(condition)))} XDC `}
            color={condition > 0 ? 'success' : 'warning'}
          />
        );
        row.mature_balance = (
          <Chip
            label={condition1 > 0 ? 'YES' : 'NO'}
            color={condition1 > 0 ? 'success' : 'error'}
          />
        );
        return row;
      })
    );
    setData(_rows);
  };
  useEffect(() => {
    const fetchData = async () => {
      await fetchAllAllotmentsByInvestor(account);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);
  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allotmentsInvestor]);

  const headCells = useMemo(
    () => [
      {
        accessor: 'token_name',
        Header: 'Token Name',
        show: true,
        width: 130,
        Cell: ({ value, row: { original } }) => {
          console.log('original', original);
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              {value}
              <AddressFieldTools
                showAddress={false}
                showInBlockExplorer
                showAddToWallet
                decimals={0}
                address={original.token_address}
                symbol={original.token_name}
              />
            </Box>
          );
        }
      },
      {
        accessor: 'total_alloted',
        Header: 'Qty',
        show: true,
        width: 80,
        Cell: ({ value }) => {
          return <Box>{value}</Box>;
        }
      },

      {
        accessor: 'maturity_date',
        Header: 'Maturity Date',
        show: true,
        width: 100,
        Cell: ({ value }) => {
          return <Box>{new Date(value).toLocaleDateString()}</Box>;
        }
      },
      {
        accessor: 'mature_balance',
        Header: 'Matured',
        width: 100,
        show: true,
        Cell: ({ row: { original } }) => {
          return <MatureBalance tokenAddress={original.token_address} nonce={Math.random()} />;
        }
      },
      {
        accessor: 'unclaimed_rewards',
        Header: 'Unclaimed Rewards',
        show: true,
        width: 100
      },
      {
        accessor: 'actions',
        Header: 'Actions',
        width: 200,
        show: true,
        Cell: ({ row: { original } }) => {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}
            >
              <ClaimRewards original={original} />
              {original.is_redeemable.status && <RedeemTvT original={original} />}
            </Box>
          );
        }
      }
    ],
    []
  );

  return (
    <>
      <EnhancedTable
        tableTitle="Holdings"
        columns={headCells}
        data={data}
        refreshFunction={() => {
          fetchAllAllotmentsByInvestor(account);
        }}
      />
    </>
  );
}
