import { useMemo, useState } from 'react';
import axios from 'axios';
import constate from 'constate';
import { useSetLoggedOutState } from 'state/useAuthStateShared';
import { API_URL } from 'config';
import { toFormData } from 'helpers/http';
import userTokenPersistence from 'persistence/userTokenPersistence';

const buildApi = (setLoggedOutState, setIsLoading) => {
  const processResponse = async (callInstance) => {
    try {
      setIsLoading(true);

      const { data } = await callInstance;
      if (data && data.error) {
        throw new Error(data.error);
      }

      return data;
    } catch (e) {
      if (e.response && e.response.status) {
        switch (e.response.status) {
          case 401:
            setLoggedOutState();
            break;
          default:
        }
      }

      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  const getAuthHeaders = () => {
    const token = userTokenPersistence.get();

    if (!token) {
      return {};
    }

    return { Authorization: `Token ${token}` };
  };

  const callAxiosMethod = (methodName, path, ...args) =>
    processResponse(
      axios[methodName](`${API_URL}${path}`, ...args, {
        headers: { ...getAuthHeaders() }
      })
    );

  const get = (...args) => callAxiosMethod('get', ...args);
  const post = (...args) => callAxiosMethod('post', ...args);
  // // eslint-disable-next-line no-unused-vars
  const put = (...args) => callAxiosMethod('put', ...args);
  const patch = (...args) => callAxiosMethod('patch', ...args);
  // // eslint-disable-next-line no-unused-vars
  const Delete = (...args) => callAxiosMethod('delete', ...args);

  // user API's
  const userLogin = (data) => post('user/login/', data);
  const userLogout = (username) => get(`user/logout/${username}/`);
  const getAllPaymentTokens = () => get('user/get-all-payment-tokens/');
  const userForgotPassword = (data) => post('user/reset-password/', data);
  const confirmForgotPassword = (data) => post('user/reset-password/confirm/', data);
  const getCurrentUser = (id) => get(`user/getuser/`);

  // coretables API's
  const getAllCountries = () => get('coretables/get-all-countries/');
  const getAllRewardFrequency = () => get('coretables/get-all-reward-frequencies/');
  const getStats = () => get('coretables/get-stats-count/');
  // entities API's
  const getAllPools = () => get('entities/get-all-pools/');
  const createPool = (data) => post('entities/create-pool/', toFormData(data));
  const getAllInvestors = () => get('entities/get-all-investor/');
  const getAllKycProvider = () => get('entities/get-all-kyc-providers/');
  const addToWhitelist = (data) => post('entities/add-to-whitelist/', data);
  const removeFromWhitelist = (data) => post('entities/remove-from-whitelist/', data);
  const getLiquidityPoolsWithoutToken = () => get(`entities/get-liquidity-pool-without-token/`);
  // tokens
  const createToken = (data) => post('token/create-token/', toFormData(data));
  const getAllTokens = () => get('token/get-all-tokens/');
  const getAllTokensByName = (tokenName) => get(`token/get-all-tokens-by-name/${tokenName}`);
  const getAllInvestableTokens = () => get('token/get-all-investable-tokens/');
  const checkTokenNameAndSymbol = (data) =>
    post('token/check-token-name-and-symbol-availability/', data);

  const updateToken = (tokenID, data) => patch(`token/update-token/${tokenID}/`, data);
  const getAllRewardsByPool = (poolID) => get(`token/get-reward-distributions-by-pool/${poolID}/`);
  const deleteToken = (tokenID) => Delete(`token/delete-token/${tokenID}/`);
  // investments
  const getAllEscrowOrders = () => get('investments/get-all-escrow-orders/');
  const getAllAllotmentsByInvestor = (address) =>
    get(`investments/get-all-allotments-by-investor/${address}/`);
  const getAllEscrowOrdersByInvestor = (address) =>
    get(`investments/get-escrow-order-by-investor/${address}/`);
  const getAllRewardsByInvestor = (address) =>
    get(`investments/get-all-rewards-by-investor/${address}/`);
  const getStatsByInvestor = (address) => get(`investments/get-investor-stats/${address}/`);
  const updateAllotmentRedeemRequestStatus = (id) =>
    put(`investments/update-allotment-redeem-request/${id}/`);
  const getAllRewardsHistory = () => get(`token/get-reward-distributions-history/`);
  const getAllAllotments = () => get('investments/get-all-allotments/');
  const getAllAllotmentsByToken = (tokenID) =>
    get(`investments/get-allotments-by-token-id/${tokenID}/`);
  const getAllRedeemAllotmentsByInvestor = (address) =>
    get(`investments/get-all-redeemed-allotments-by-investor/${address}/`);
  const indicateRedeemRequest = (data) => post('investments/indicate-redeem-request/', data);
  // investor
  const createInvestorProfile = (data) =>
    post('entities/create-investor-profile/', toFormData(data));
  const uploadKyc = (data) => post('entities/upload-kyc-file/', toFormData(data));
  const getAInvestorByAddress = (address) =>
    get(`entities/get-investor-by-xinfin-account/${address}/`);
  const checkActiveRedeemRequest = (address, tokenUuid) =>
    get(`investments/check-active-redeem-escrow/${address}/${tokenUuid}/`);

  // content
  const getAllFaqs = () => get('content/get-all-faqs/');

  return {
    // user
    userLogin,
    userLogout,
    userForgotPassword,
    confirmForgotPassword,
    getCurrentUser,
    // entities
    createPool,
    getAllPools,
    getAllInvestors,
    addToWhitelist,
    removeFromWhitelist,
    createInvestorProfile,
    uploadKyc,
    getLiquidityPoolsWithoutToken,
    // coretables
    getAllCountries,
    getAllRewardFrequency,
    getAllPaymentTokens,
    getAllKycProvider,
    createToken,
    getAllTokens,
    getAllInvestableTokens,
    getAllRewardsHistory,
    checkTokenNameAndSymbol,
    getStats,
    deleteToken,
    // investments
    getAllEscrowOrders,
    getAllAllotmentsByInvestor,
    getAllEscrowOrdersByInvestor,
    getAllRewardsByInvestor,
    getStatsByInvestor,
    getAllTokensByName,
    updateAllotmentRedeemRequestStatus,
    getAInvestorByAddress,
    getAllAllotmentsByToken,
    getAllAllotments,
    getAllRedeemAllotmentsByInvestor,
    updateToken,
    indicateRedeemRequest,
    getAllRewardsByPool,
    checkActiveRedeemRequest,
    // content
    getAllFaqs
  };
};

export const useHttpApi_ = () => {
  const setLoggedOutState = useSetLoggedOutState();
  const [isLoading, setIsLoading] = useState(false);

  const api = useMemo(() => buildApi(setLoggedOutState, setIsLoading), [setLoggedOutState]);

  return { api, isLoading };
};

export const [HttpApiProvider, useHttpApi, useHttpApiLoading] = constate(
  useHttpApi_,
  ({ api }) => api,
  ({ isLoading }) => isLoading
);
