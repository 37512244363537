import { useState, useEffect } from 'react';
import { useAppState, useWeb3, useWeb3State } from 'state';
import { accordionActionsClasses, Chip } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';

export default function MatureBalance({ tokenAddress, nonce }) {
  const { matureBalance, account } = useWeb3State();
  const [canIssueResponse, setcanIssueResponse] = useState('none');

  useEffect(() => {
    const fetchdata = async () => {
      const response = await matureBalance(tokenAddress);
      console.log('mature', response);
      setcanIssueResponse(response);
    };
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonce]);

  switch (canIssueResponse) {
    case 'none':
      return <Chip label="Checking.." color="default" />;
    case '0':
      return <CancelIcon color="warning" />;
    default:
      return <CheckCircleOutlineOutlinedIcon color="success" />;
  }
}
