import { Container } from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import AccordionLayout from 'components/AccordionLayout';
import HoldingsInvestor from 'components/Investor/HoldingsInvestor';
import SettlementsTable from 'components/Investor/SettlementsTable';
import RewardsDistributionTable from 'components/Investor/RewardsDistributionTable';
import RedeemedAllotments from 'components/Investor/RedeemedAllotments';

const InvestorDashboard = () => {
  return (
    <>
      <Page title="XDC Staking">
        <Container sx={{ my: 2 }}>
          {/* Investor Dashboard */}
          <AccordionLayout defaultExpanded title="Holdings" content={<HoldingsInvestor />} />
          <AccordionLayout defaultExpanded title="Settlements" content={<SettlementsTable />} />
          <AccordionLayout
            defaultExpanded
            title="Rewards Distribution"
            content={<RewardsDistributionTable />}
          />
          <AccordionLayout title="Past Node Investments" content={<RedeemedAllotments />} />
        </Container>
      </Page>
    </>
  );
};

export default InvestorDashboard;
