import * as React from 'react';
import {
  TableRow,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Grid,
  Button,
  Typography,
  Chip,
  Box,
  Card,
  CardContent,
  IconButton
} from '@mui/material';

import { useBusinessEntityState, useCoreTableState, useHttpApi, useWeb3State } from 'state';
import { makeStyles } from '@mui/styles';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import CountdownTimer from './Investor/CountdownTimer';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import DataNotAvailable from 'helpers/DataNotAvailable';
import { useSnackbar } from 'notistack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DescriptionIcon from '@mui/icons-material/Description';
import { API_URL } from 'config';

const useStyles = makeStyles({
  root: {
    borderBottom: 'none',
    border: 'none !important;'
  },
  body: {
    background: '#ffffff 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 20px #00000005',
    borderRadius: 20
  }
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  width: 200,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8'
  }
}));

const InvestorTokenTable = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { investableTokens, fetchAllInvestableTokens, setCurrentTokenFunction } =
    useCoreTableState();

  const { account } = useWeb3State();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const { checkActiveRedeemRequest } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    fetchAllInvestableTokens();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const BuyToken = async (token) => {
    const result = await checkActiveRedeemRequest(account, token.id);
    if (result.status === true) {
      enqueueSnackbar('You have an active redeem request for this token', {
        variant: 'error'
      });
      return;
    }
    console.log('result', result);
    setCurrentTokenFunction(token);
    // if (!account) {
    //   setOpen(true);
    // } else {
    navigate('/loading-account');
    // }
  };

  const [today, setToday] = useState(new Date());

  return (
    <>
      <TableContainer sx={{ mt: 5, display: { xs: 'none', sm: 'block' } }}>
        <Table
          className={classes.root}
          sx={{
            borderCollapse: 'separate',
            borderSpacing: '0px 12px'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ borderBottom: 'none' }} className="token-table-header">
                Nodes
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="token-table-header">
                APY
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="token-table-header">
                Total Staked
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="token-table-header">
                Contract Cycle Ends
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="token-table-header">
                T & C
              </TableCell>
              <TableCell style={{ borderBottom: 'none' }} className="token-table-header">
                Stake
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {investableTokens.map((token) => {
              let status;
              if (token.is_investable) {
                status = 'Stake';
              } else if (token.total_supply === token.max_supply) {
                status = 'Sold out';
              } else {
                status = 'Closed';
              }

              return (
                <TableRow
                  sx={{
                    background: '#ffffff',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)',
                    borderRadius: 20,
                    border: '1px solid #000 '
                  }}
                  key={token.id}
                  // className={classes.body}

                  aria-label="customized table"
                >
                  <TableCell
                    onClick={() => {
                      navigate(`/node-dashboard/${token.name}`);
                    }}
                    style={{ borderBottom: 'none' }}
                    sx={{
                      py: 2,
                      cursor: 'pointer',
                      borderTopLeftRadius: 20,
                      borderBottomLeftRadius: 20
                    }}
                  >
                    {token.pool_name}
                  </TableCell>

                  <TableCell style={{ borderBottom: 'none' }}>
                    <Chip
                      label="5% - 7%"
                      color="secondary"
                      sx={{
                        fontWeight: 600,
                        fontSize: '0.9rem',
                        backgroundColor: '#4a69bd'
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ borderBottom: 'none' }}>
                    <Typography
                      sx={{ fontSize: '0.9rem' }}
                    >{`${token.total_supply} / ${token.max_supply}`}</Typography>
                    <Grid sx={{ display: 'flex' }}>
                      <BorderLinearProgress
                        variant="determinate"
                        value={
                          100 - Math.round(100 - (token.total_supply / token.max_supply) * 100)
                        }
                      />
                      <Typography color="textSecondary" sx={{ ml: 1 }}>
                        {/* calculate investable supply in percentage for the token */}
                        {Math.round((token.total_supply / token.max_supply) * 100)} %
                      </Typography>
                    </Grid>
                  </TableCell>

                  {new Date(token.subscription_end_date) <= today &&
                  token.total_supply !== token.max_supply ? (
                    <TableCell style={{ borderBottom: 'none' }}>
                      <Typography sx={{ fontSize: '0.9rem' }}>
                        {token.issueOpen ? 'Open For Investment' : 'Closed'}
                      </Typography>
                    </TableCell>
                  ) : (
                    <TableCell style={{ borderBottom: 'none' }}>
                      {token.issueOpen ? (
                        <Typography sx={{ fontSize: '0.9rem' }}>Open For Investment</Typography>
                      ) : (
                        <CountdownTimer
                          maturityDate={
                            new Date(token.subscription_end_date) > today &&
                            token.total_supply !== token.max_supply
                              ? token.subscription_end_date
                              : token.maturity_date
                          }
                        />
                      )}
                    </TableCell>
                  )}

                  <TableCell>
                    <IconButton
                      sx={{
                        backgroundColor: '#ecf0f1',
                        color: '#2c3e50'
                      }}
                      onClick={() => {
                        window.open(`${token.contract_doc_hash}`, '_blank');
                      }}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell
                    style={{ borderBottom: 'none' }}
                    sx={{
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20
                    }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      sx={{
                        px: 2,
                        py: 1,
                        fontSize: '1rem',
                        fontWeight: 600,
                        backgroundColor: '#2E4AE6',
                        width: '120px'
                      }}
                      onClick={() => {
                        if (!account) {
                          enqueueSnackbar('Please connect your wallet', {
                            variant: 'error'
                          });
                          return;
                        }
                        BuyToken(token);
                      }}
                      disabled={!token.is_investable}
                    >
                      {status}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {investableTokens.length === 0 && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <DataNotAvailable sx={{ width: 180, opacity: 0.5 }} />
          </Box>
        )}
      </TableContainer>

      {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <InvestorWalletRequire />
      </Modal> */}
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'space-between', mt: 5 }}
      >
        <Grid display="flex" justifyContent="center" alignItems="center" item xs={6}>
          <Typography fontWeight="bold">Nodes</Typography>
        </Grid>
        <Grid display="flex" justifyContent="center" alignItems="center" item xs={6}>
          <Typography fontWeight="bold">APY</Typography>
        </Grid>
      </Grid>
      {investableTokens.map((token) => {
        return (
          <>
            <Accordion
              sx={{
                p: 1,
                mt: 2,
                borderRadius: 3,
                display: { xs: 'block', sm: 'none' }
              }}
              square
              expanded={expanded === token.id}
              onChange={handleChange(token.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Grid
                  spacing={2}
                  container
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Typography fontWeight="bold" color="grey" display="block" gutterBottom>
                      {token.pool_name}
                    </Typography>
                  </Grid>
                  <Grid
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    item
                    xs={6}
                  >
                    <Chip
                      label="5% - 7%"
                      color="secondary"
                      sx={{
                        fontWeight: 600,
                        fontSize: '0.9rem',
                        backgroundColor: '#4a69bd'
                      }}
                    />
                  </Grid>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Grid display="flex" alignItems="center" item xs={12}>
                  <Typography fontWeight="bold" display="block" gutterBottom>
                    Total Staked -&nbsp;
                  </Typography>
                  <Typography sx={{ fontSize: '0.9rem' }}>
                    {`${token.total_supply} / ${token.max_supply}`}
                  </Typography>
                </Grid>
                <Grid display="flex" alignItems="center" item xs={12}>
                  <Typography fontWeight="bold" display="block" gutterBottom>
                    Contract Cycle -&nbsp;
                  </Typography>
                  {new Date(token.subscription_end_date) <= today &&
                  token.total_supply !== token.max_supply ? (
                    <Typography sx={{ fontSize: '0.9rem' }}>
                      {token.issueOpen ? 'Open For Investment' : 'Closed'}
                    </Typography>
                  ) : (
                    <>
                      {token.issueOpen ? (
                        <Typography sx={{ fontSize: '0.9rem' }}>Open For Investment</Typography>
                      ) : (
                        <CountdownTimer
                          maturityDate={
                            new Date(token.subscription_end_date) > today &&
                            token.total_supply !== token.max_supply
                              ? token.subscription_end_date
                              : token.maturity_date
                          }
                        />
                      )}
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </>
  );
};

export default InvestorTokenTable;
