import {
  Typography,
  Stack,
  TextField,
  Box,
  Grid,
  FormLabel,
  Button,
  Dialog,
  DialogContent,
  Alert,
  Paper,
  Link,
  AlertTitle
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useAppState, useHttpApi } from 'state';

const RequestForgotPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { throwErrorMessage } = useAppState();
  const { userForgotPassword } = useHttpApi();
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid Email Format').required('Email is required')
  });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        console.log(values.email);
        await userForgotPassword(values);
        handleOpen();
        // navigate('/admin', { replace: true });
      } catch (e) {
        console.log(e);
        throwErrorMessage(e);
        setError(e);
        resetForm();
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <Grid>
      <Paper
        sx={{
          padding: 5,
          height: '70vh',
          width: 500,
          margin: '20px auto',
          boxShadow: '0px 3px 20px #00000005'
        }}
      >
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Typography sx={{ fontWeight: 600, mb: 2, fontSize: '1.25rem', textAlign: 'center' }}>
              Reset Password of your Account
            </Typography>
            {error && (
              <Box mb={4}>
                <Alert severity="error">{error}</Alert>
              </Box>
            )}
            <Typography sx={{ mb: 4, fontSize: '1rem', color: '#8e8ea7' }}>
              Enter Email associated with your account and we'll send you an email with instructions
              to reset your password.
            </Typography>
            <Stack spacing={3}>
              <FormLabel>Email Address</FormLabel>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                placeholder="Enter Email address"
              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              loadingPosition="start"
              loading={isSubmitting}
              sx={{
                mt: 3,
                backgroundColor: '#2E4AE6',
                color: 'white',
                '&:hover': {
                  color: '#3449DD',
                  backgroundColor: '#fff'
                }
              }}
            >
              Submit
            </LoadingButton>
          </Form>
        </FormikProvider>
        <Box
          sx={{
            mx: 'auto',
            p: 1,
            textAlign: 'center',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Button
            onClick={() => {
              navigate('/login');
            }}
          >
            Login form
          </Button>
        </Box>
        <Dialog open={open} onClose={handleClose} sx={{ textAlign: 'center' }}>
          <Alert severity="success">
            <AlertTitle sx={{ fontWeight: 600, fontSize: '1.2rem', fontFamily: 'Quicksand' }}>
              Email Sent Successfully
            </AlertTitle>
          </Alert>
          <DialogContent sx={{ fontWeight: 500, fontSize: '1rem', fontFamily: 'Quicksand' }}>
            Check your mail, we have sent a password recover link to your email.
          </DialogContent>
          <DialogContent>
            <Button
              color="secondary"
              sx={{ backgroundColor: '#2E4AE6', color: 'white', width: '13rem', padding: '10px' }}
              onClick={() => {
                setOpen(false);
                navigate('/login');
              }}
            >
              Login Page
            </Button>
          </DialogContent>
          <DialogContent
            sx={{ fontSize: '0.8rem', color: '#8e8ea7', mt: 2, fontFamily: 'Quicksand' }}
          >
            Did not receive the email? Check your spam folder or
            <Link
              sx={{ fontSize: '0.82rem', cursor: 'pointer', ml: '0.1875rem' }}
              onClick={handleClose}
              underline="hover"
            >
              entered email address is correct.
            </Link>
          </DialogContent>
        </Dialog>
      </Paper>
    </Grid>
  );
};

export default RequestForgotPassword;
