import { Button, Modal, TextField, Box, Typography } from '@mui/material';
import { currentNetwork } from 'helpers/web3';
import React from 'react';
import { useAppState, useBusinessEntityState, useCoreTableState, useWeb3State } from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #f3f3f3',
  boxShadow: 24,
  p: 4
};
const ManageToken = ({ original }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { setCurrentAdminTokenFunction } = useBusinessEntityState();

  const navigate = useNavigate();

  return (
    <Box>
      <Button
        sx={{ m: 0.5 }}
        variant="contained"
        color="secondary"
        onClick={async () => {
          navigate('/admin/manage-token');
          console.log('ORIGINAL', original);
          setCurrentAdminTokenFunction(original);
        }}
      >
        Manage Token
      </Button>
    </Box>
  );
};

export default ManageToken;
