import { Container, Table, TableBody, TableCell, TableHead, TableRow, Box } from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import AccordionLayout from 'components/AccordionLayout';
import UpdateMaturityDate from 'components/Admin/manage-token/UpdateMaturityDate';
import SetMaxSupply from 'components/Admin/manage-token/SetMaxSupply';
import { useBusinessEntityState } from 'state';
import ToggleIssueOpen from 'components/Admin/manage-token/ToggleIssueOpen';
import RefreshInvestors from 'components/Admin/manage-token/RefreshInvestors';
import ResignToken from 'components/Admin/manage-token/ResignToken';

const ManageTokenDashboard = () => {
  const { currentAdminToken } = useBusinessEntityState();
  return (
    <>
      <Page title="XDC Staking">
        <Container
          sx={{
            my: 4
          }}
        >
          {/* Manage Token Dashboard */}
          <Table
            sx={{
              background: ' #ffffff 0% 0% no-repeat padding-box',
              boxShadow: '0px 3px 20px #00000005',
              borderRadius: 2
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  Token Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  Token Symbol
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  Node Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 'bold'
                  }}
                >
                  Redeem Pressure
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{currentAdminToken.name}</TableCell>
                <TableCell>{currentAdminToken.symbol}</TableCell>
                <TableCell>{currentAdminToken.pool_name}</TableCell>
                <TableCell>{currentAdminToken.redeem_pressure}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Container>
        <Container sx={{ my: 2 }}>
          {/* Manage Token Dashboard */}
          <AccordionLayout
            defaultExpanded
            title="Maturity Date"
            content={<UpdateMaturityDate currentToken={currentAdminToken} />}
          />
          <AccordionLayout
            defaultExpanded
            title="Max Supply"
            content={<SetMaxSupply currentToken={currentAdminToken} />}
          />
          <AccordionLayout
            defaultExpanded
            title="Token Investable"
            content={<ToggleIssueOpen currentToken={currentAdminToken} />}
          />
          <AccordionLayout
            defaultExpanded
            title="Refresh Investors NFT / NON NFT Buckets"
            content={<RefreshInvestors currentToken={currentAdminToken} />}
          />
          <AccordionLayout
            title="Resign Token"
            content={<ResignToken currentToken={currentAdminToken} />}
          />
        </Container>
      </Page>
    </>
  );
};

export default ManageTokenDashboard;
