import { Typography, Divider, Box } from '@mui/material';
import React from 'react';

const Footer = () => {
  return (
    <Box
      sx={{
        position: 'static',
        left: 0,
        bottom: 0,
        width: '100%',
        background: 'linear-gradient(180deg, #86b4f8 2%, #126cf2 50%)'
      }}
    >
      {/* <Divider
        sx={{
          mt: 4
        }}
      /> */}
      <Typography
        variant="body1"
        align="center"
        sx={{
          py: 2,
          // color: '#161c2d'
          color: '#fff'
        }}
      >
        &copy; {new Date().getFullYear()}, Powered By XDC Network
      </Typography>
    </Box>
  );
};

export default Footer;
