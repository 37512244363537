import Page from 'components/Page';
import React from 'react';
import { Grid, Card, Container, CardContent, Button } from '@mui/material';
import LiquidityPoolTable from 'components/Admin/pool/LiquidityPoolTable';
import Breadcrumbs from 'components/Breadcrumbs';
import { useNavigate } from 'react-router';

const AllLiquidityPool = () => {
  const navigate = useNavigate();

  return (
    <Page title="XDC Staking">
      <Container sx={{ mt: 2 }}>
        <Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
          <Grid item xs={8}>
            <Breadcrumbs pageHead="All Nodes" />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              sx={{ color: 'white', fontWeight: 600, backgroundColor: '#2E4AE6' }}
              onClick={() => {
                navigate('/admin/add-pool');
              }}
            >
              Add Node
            </Button>
          </Grid>
        </Grid>

        <Card sx={{ mt: 2 }} className="form-card">
          <CardContent>
            <LiquidityPoolTable />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllLiquidityPool;
