import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import { Card, Container, CardContent, Grid, FormControl, MenuItem, Select } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import AllotmentsTable from 'components/Admin/Tables/AllotmentsTable';
import { useCoreTableState } from 'state';

const Allotments = () => {
  const { tokens, fetchAllTokens } = useCoreTableState();
  const [selectedToken, setSelectedToken] = useState('all');

  const handleChange = (event) => {
    setSelectedToken(event.target.value);
    console.log('SELECTED TOKEN', event.target.value);
  };

  useEffect(() => {
    fetchAllTokens();
    console.log('TOKENS', tokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="XDC Staking">
      <Container sx={{ mt: 2 }}>
        <Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
          <Grid item xs={8}>
            <Breadcrumbs pageHead="Allotments" />
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="end">
            <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5, width: '325px' }}>
              <Select
                // onChange={handleChange}
                inputProps={{ 'aria-label': 'Without label' }}
                id="token"
                onChange={handleChange}
                value={selectedToken}
              >
                <MenuItem selected key="All" value="all">
                  All
                </MenuItem>
                {tokens &&
                  tokens.map((token) => {
                    return (
                      <MenuItem key={token.name} value={token.id}>
                        {token.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Card sx={{ mt: 2 }} className="form-card">
          <CardContent>
            <AllotmentsTable tokenID={selectedToken} />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default Allotments;
