import EnhancedTableCheck from 'components/tables/EnhancedTableCheck';
import React, { useMemo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
  FormLabel
} from '@mui/material';
import {
  useAppState,
  useBusinessEntityState,
  useCoreTableState,
  useHttpApi,
  useWeb3State
} from 'state';
import EnhancedTable from 'components/tables/EnhancedTable';
import { formatDecimal } from 'helpers/numbers';

export default function RewardHistoryTable({ poolID }) {
  const { fetchAllRewardHistory, rewardHistory, rewardsByPool, fetchAllRewardsByPool } =
    useBusinessEntityState();
  const { throwErrorMessage } = useAppState();
  const [timezone, setTimezone] = React.useState(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const headCells = useMemo(
    () => [
      {
        accessor: 'pool',
        Header: 'Pool Name',
        show: true
      },
      {
        accessor: 'reward_amount',
        Header: 'Reward Amount',
        show: true
      },
      {
        accessor: 'fees_cut',
        Header: 'Fees Cut',
        show: true,
        Cell: ({ value }) => {
          return formatDecimal(parseFloat(value));
        }
      },
      {
        accessor: 'fees_1',
        Header: 'Devs Fees',
        show: true,
        Cell: ({ value }) => {
          return value.toString().slice(0, 6);
        }
      },
      {
        accessor: 'fees_2',
        Header: 'Prime Fees',
        show: true,
        Cell: ({ value }) => {
          return value.toString().slice(0, 6);
        }
      },
      {
        accessor: 'fees_3',
        Header: 'XDCS Fees',
        show: true,
        Cell: ({ value }) => {
          return value.toString().slice(0, 6);
        }
      },
      {
        accessor: 'nft_reward_rate',
        Header: 'NFT Reward Rate / XDC',
        show: true,
        Cell: ({ value }) => {
          return value.toString().slice(0, 6);
        }
      },
      {
        accessor: 'non_nft_reward_rate',
        Header: 'Non NFT Reward Rate / XDC',
        show: true,
        Cell: ({ value }) => {
          return value.toString().slice(0, 6);
        }
      },
      {
        accessor: 'reward_date',
        Header: 'Reward Date',
        show: true,
        Cell: ({ value }) => {
          return new Date(value).toLocaleString('en', { timeZone: timezone });
        }
      }
    ],
    [timezone]
  );

  useEffect(() => {
    console.log('POOL ID', poolID);
    updateRewardData(poolID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolID]);

  const [data, setData] = useState(rewardHistory);
  const updateRewardData = (poolID) => {
    if (poolID !== 'all') {
      fetchAllRewardsByPool(poolID);
    } else {
      fetchAllRewardHistory();
    }
  };

  // const updateData = async () => {
  //   setData(rewardHistory);
  // };
  // useEffect(() => {
  //   updateData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [rewardHistory]);
  // useEffect(() => {
  //   fetchAllRewardHistory();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  const [rowsSelected, setRowsSelected] = useState([]);
  const [filter, setFilter] = useState('all');
  const [skipPageReset, setSkipPageReset] = React.useState(false);

  return (
    <>
      <Grid>
        <EnhancedTable
          tableTitle="Reward History"
          columns={headCells}
          refreshFunction={() => {
            updateRewardData(poolID);
          }}
          setSelectedRowsToState={setRowsSelected}
          data={poolID === 'all' ? rewardHistory : rewardsByPool}
          setData={setData}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          isRoleAllowed
        />
      </Grid>
    </>
  );
}
