import { useState, useEffect } from 'react';
import { useWeb3State } from 'state';
import { Box, Chip, Typography } from '@mui/material';
import { formatDecimal, weiToEth } from 'helpers/numbers';
import { Big } from 'big.js';

export default function WXDCBalance({ requiredBalance, nonce }) {
  const { wxdcBalanceOf, account } = useWeb3State();
  const [canIssueResponse, setcanIssueResponse] = useState(0);

  useEffect(() => {
    const fetchdata = async () => {
      const response = await wxdcBalanceOf();
      console.log('response', response);
      console.log(response.length);
      setcanIssueResponse(response);
    };
    fetchdata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonce]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      {canIssueResponse !== 0 && (
        <Chip
          label={
            weiToEth(canIssueResponse.toString()).split('.')[1] === undefined
              ? parseFloat(weiToEth(canIssueResponse.toString())).toFixed(3)
              : `${weiToEth(canIssueResponse.toString()).split('.')[0]}.${weiToEth(
                  canIssueResponse.toString()
                )
                  .split('.')[1]
                  .slice(0, 2)}`
          }
        />
      )}

      <Chip
        label={
          new Big(weiToEth(canIssueResponse.toString())).gte(new Big(requiredBalance))
            ? 'Enough Balance'
            : 'Low Balance'
        }
        color={
          new Big(weiToEth(canIssueResponse.toString())).gte(new Big(requiredBalance))
            ? 'success'
            : 'error'
        }
      />
    </Box>
  );
}
