import { LoadingButton } from '@mui/lab';
import { Box, Modal, Typography, Button, TextField } from '@mui/material';
import { set } from 'date-fns';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useAppState, useWeb3State } from 'state';

const SetNodeToken = ({ poolAddress }) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #f3f3f3',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    p: 4
  };
  const [feesDestination, setFeesDestination] = React.useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setNodeTokenAddress } = useWeb3State();

  const handleClear = () => {
    setFeesDestination('');
    setOpen(false);
  };

  return (
    <Box>
      <Button variant="outlined" color="secondary" onClick={() => setOpen(true)}>
        Node Token Setup
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Typography>Set Node Token</Typography>
          <TextField
            sx={{ m: 0.5 }}
            label="Address"
            onBlur={(e) => setFeesDestination(e.target.value)}
          />
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={async () => {
              setLoading(true);
              try {
                await setNodeTokenAddress(poolAddress, feesDestination);
                handleClear();
                enqueueSnackbar('Set terms successfully', { variant: 'success' });
                setLoading(false);
              } catch (e) {
                handleClear();
                enqueueSnackbar(e.message, { variant: 'error' });
                setLoading(false);
              }
            }}
          >
            Set Token
          </LoadingButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default SetNodeToken;
