import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';
import Footer from '../components/Footer';
import AdminNavbar from 'components/Admin/AdminNavbar';
import WalletRequireOverlay from 'components/Wallet/WalletRequireOverlay';
import { BusinessEntityProvider } from 'state/useBusinessEntityState';

const AdminLayout = () => {
  return (
    <>
      <BusinessEntityProvider>
        <AdminNavbar />
        <Box sx={{ position: 'relative', flex: '1', py: 3 }}>
          <WalletRequireOverlay />
          <Outlet />
        </Box>
        <Footer />
      </BusinessEntityProvider>
    </>
  );
};

export default AdminLayout;
