import { Card, Box, Typography, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useHttpApi, useInvestmentsState, useWeb3State } from 'state';
import { useNavigate } from 'react-router';
import loading from '../../assets/images/Loading.gif';
import kyc from '../../assets/images/kyc.png';

const LoadingInvestorAccount = () => {
  const { setCurrentInvestorFunction } = useInvestmentsState();
  const { getAInvestorByAddress } = useHttpApi();
  const { account } = useWeb3State();
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInvestor = async () => {
      const investors = await getAInvestorByAddress(account);
      const res = investors?.length > 0 ? investors[0] : null;
      setCurrentInvestorFunction(res);
      console.log('Checking Matched investor', res);

      if (res && res.is_whitelisted === true) {
        setTimeout(() => {
          navigate('/buy-token');
        }, 2000);
      } else if (res && res.is_whitelisted === false) {
        setPending(true);
      } else {
        setTimeout(() => {
          navigate('/create-profile');
        }, 2000);
      }
    };
    fetchInvestor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <Box
      severity="warning"
      sx={{
        position: 'fixed',
        top: '20vh',
        left: 'calc(50vw - 550px / 2)',
        width: '550px'
      }}
    >
      <Card className="form-card" sx={{ padding: 3 }}>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          {pending ? (
            <>
              <Grid item xs={12}>
                <Box component="img" src={kyc} sx={{ height: '24rem' }} alt="kyc-gif" />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: 'center', fontWeight: 600, color: 'warning.main' }}
                >
                  Your KYC is pending. Please wait for the approval.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Box component="img" src={loading} sx={{ height: '6rem' }} alt="loading-gif" />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h4" sx={{ fontWeight: 600, mt: 2 }}>
                  Checking Your Account details...
                </Typography>
              </Grid>
            </>
          )}
        </Grid>
      </Card>
    </Box>
  );
};

export default LoadingInvestorAccount;
