import { Box, Button, Card, Container, Typography } from '@mui/material';
import notfound from 'assets/images/ufo.png';
import { userNamePersistence } from 'persistence';
import React from 'react';
import { useNavigate } from 'react-router';

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mt: 18
      }}
    >
      <Card
        sx={{
          p: 4,
          borderRadius: 10,
          py: 6,
          height: '60%',
          width: '40%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Box
          component="img"
          src={notfound}
          sx={{
            width: '20rem',
            opacity: 0.5
          }}
        />
        <Typography textAlign="center" fontSize="2rem" fontWeight="bold">
          OOPs...
        </Typography>
        <Typography
          sx={{
            pb: 1
          }}
          textAlign="center"
          fontSize="1rem"
          fontWeight="bold"
        >
          ...maybe the page you looking for is not here or never existed.
        </Typography>
        <Button
          onClick={() => {
            navigate('/');
          }}
          sx={{
            mt: 2,
            width: '30%'
          }}
          variant="contained"
          color="primary"
          size="large"
        >
          Home
        </Button>
      </Card>
    </Container>
  );
};

export default PageNotFound;
