import { useEffect, useState } from 'react';
import {
  Chip,
  FormLabel,
  Grid,
  TextField,
  Button,
  Step,
  Stepper,
  Box,
  Typography,
  StepButton,
  FormControlLabel,
  FormControl,
  Card,
  Switch,
  FormGroup
} from '@mui/material';
import Web3 from 'web3';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import WXDCBalance from './WXDCBalance';
import { useSnackbar } from 'notistack';
import { Big } from 'big.js';
import { currentNetwork } from 'helpers/web3';

// import EscrowButton from './EscrowButton';
import {
  useBusinessEntityState,
  useCoreTableState,
  useHttpApi,
  useWeb3State,
  useInvestmentsState
} from 'state';
import { formatFloat, weiToEth } from 'helpers/numbers';
import InvestorWalletRequire from 'components/Wallet/InvestorWalletRequire';
import WalletRequireOverlay from 'components/Wallet/WalletRequireOverlay';
import { useNavigate } from 'react-router';
import EscrowButton from 'components/EscrowButton';

const steps = ['Declare', 'Escrow', 'Submit'];

export default function InvestToken() {
  const { currentToken: token } = useCoreTableState();
  const navigate = useNavigate();
  const { currentInvestor } = useInvestmentsState();
  const [isDeposit, setIsDeposit] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [qty, setQty] = useState(0);

  const CreateOrderSchema = Yup.object().shape({
    qty: Yup.number().integer('Please enter whole quantity!').required('Quantity is required')
  });
  const createOrderFormik = useFormik({
    initialValues: {
      qty: ''
    },
    validate: (values) => {
      const errors = {};
      if (values.qty < token?.minimum_subscription) {
        errors.qty = `Quantity cannot be less than Minimum Subscription i.e ${token?.minimum_subscription}`;
      }
      if (values.qty % token?.minimum_subscription !== 0) {
        errors.qty = `Quantity must be multiple of Minimum Subscription i.e ${token?.minimum_subscription}`;
      }
      if (values.qty > token?.max_supply - token?.total_supply) {
        errors.qty = 'Quantity cannot be greater than Available Space';
      }

      // if (values.qty <= 0) {
      //   errors.qty = "Quantity must be greater than 0";
      // }
      return errors;
    },
    validationSchema: CreateOrderSchema,
    onSubmit: (values, { resetForm }) => {
      setQty(values.qty);
      checkRequirements(values.qty * token?.value);
      resetForm();
      handleNext();
    }
  });

  useEffect(() => {
    if (!token.id) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = async () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;

    if (activeStep === 1) {
      const balance = weiToEth(await wxdcBalanceOf());
      const allowance = weiToEth(await wxdcAllowance());
      console.log('BALANCE', balance);
      console.log('ALLOWANCE', allowance);
      if (balance < qty * token?.value) {
        enqueueSnackbar('Insufficient balance', { variant: 'error' });
        return;
      }
      if (allowance < qty * token?.value) {
        enqueueSnackbar('Insufficient allowance', { variant: 'error' });
        return;
      }
      setActiveStep(newActiveStep);
    } else {
      setActiveStep(newActiveStep);
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    navigate('/');
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  const {
    errors: createOrderErrors,
    touched: createOrderTouched,
    values,
    isSubmitting,
    handleSubmit: createOrderHandleSubmit,
    getFieldProps: createOrderGetFieldProps
  } = createOrderFormik;

  const { wxdcBalanceOf, depositXDC, account, tvtIssue, wxdcAllowance, approveWxdc } =
    useWeb3State();
  const { enqueueSnackbar } = useSnackbar();
  const { balance } = useWeb3State();
  const balanceStr = formatFloat(parseFloat(Web3.utils.fromWei(balance, 'ether')), 3);

  // const { createEscrow, completeIssuance } = useHttpApi();
  const [loadingState, setLoadingState] = useState(false);
  const [disableDeposit, setDisableDeposit] = useState(true);
  const [disableAllowance, setDisableAllowance] = useState(true);
  const [isAllowance, setIsAllowance] = useState(false);

  // useEffect(() => {
  //   if (!account) return <WalletRequireOverlay />;
  // }, [account]);
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setDisableDeposit(true);
    setDisableAllowance(true);
  };

  // useEffect(() => {
  //   if (account !== currentInvestor) {
  //     navigate('/loading-account');
  //   }
  // }, [account, currentInvestor]);

  const checkRequirements = async (totalValue) => {
    const balance = weiToEth(await wxdcBalanceOf());
    const allowance = weiToEth(await wxdcAllowance());

    if (Big(balance).gte(new Big(totalValue))) {
      setDisableDeposit(true);
    } else {
      setDisableDeposit(false);
      return true;
    }
    if (Big(allowance).gte(new Big(totalValue))) {
      setDisableAllowance(true);
    } else {
      setDisableAllowance(false);
      return true;
    }
    return false;
  };

  return (
    <Card
      sx={{
        padding: 5,
        width: '60%',
        margin: '40px auto',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.3)',
        borderRadius: '20px'
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box>
        {allStepsCompleted() ? (
          <Box>
            <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box>
          </Box>
        ) : (
          <Box>
            {activeStep === 0 && (
              <Box>
                <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
                  Declare your stake
                </Typography>
                <Grid container>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
                      Available Space
                    </Typography>
                    <Typography fontWeight="bold" fontSize="1.8rem" sx={{ mb: 1 }}>
                      {token?.length === 0 ? 0 : token?.max_supply - token?.total_supply}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight="bold" sx={{ mt: 2, mb: 1 }}>
                      Your Wallet Balance
                    </Typography>
                    <Typography fontWeight="bold" fontSize="1.8rem" sx={{ mb: 1 }}>
                      {`${balanceStr} XDC`}
                    </Typography>
                  </Grid>
                </Grid>
                <FormikProvider value={createOrderFormik}>
                  <Form autoComplete="off" noValidate onSubmit={createOrderHandleSubmit}>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pl: 0.5 }}>
                      <FormLabel
                        sx={{
                          fontWeight: 'bold'
                        }}
                      >
                        Enter your stake
                      </FormLabel>
                      <TextField
                        key="qty"
                        sx={{ mt: 1 }}
                        fullWidth
                        size="small"
                        {...createOrderGetFieldProps('qty')}
                        autoComplete="off"
                        type="number"
                        error={Boolean(createOrderTouched.qty && createOrderErrors.qty)}
                        helperText={createOrderTouched.qty && createOrderErrors.qty}
                      />
                    </Grid>
                    <Typography variant="body1" sx={{ mt: 2, mb: 1 }}>
                      You are purchasing {createOrderFormik.values.qty} {token?.name} tokens for{' '}
                      {token?.value} XDC each.
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        pt: 2,
                        justifyContent: 'end'
                      }}
                    >
                      <LoadingButton
                        loadingPosition="start"
                        variant="contained"
                        type="submit"
                        sx={{ mt: 2, height: '2.6rem', width: '7.813rem' }}
                        loading={isSubmitting}
                      >
                        Next
                      </LoadingButton>
                    </Box>
                  </Form>
                </FormikProvider>
              </Box>
            )}
            {activeStep === 1 && (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <Typography variant="h5" sx={{ mt: 2, mb: 1 }}>
                    Setting Up WXDC For Your Purchase
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 2,
                    p: 2,
                    backgroundColor: '#f5f5f5'
                  }}
                >
                  <Typography
                    sx={{
                      color: '#2d3436',
                      fontWeight: 'bold'
                    }}
                  >
                    You Need to hold
                    <Chip color="warning" label={`${qty * token?.value} WXDC`} />
                    to complete this order.
                  </Typography>
                  <Box>
                    <WXDCBalance requiredBalance={qty * token?.value} nonce={Math.random()} />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: 1
                  }}
                >
                  <Card
                    sx={{
                      width: '70%'
                    }}
                  >
                    <Typography
                      sx={{
                        p: 1
                      }}
                      fontWeight="bold"
                    >
                      I acknowledge my XDC will be held in escrow until I withdraw or until a
                      masternode is created
                    </Typography>
                  </Card>
                  <LoadingButton
                    loadingPosition="start"
                    loading={isDeposit}
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={disableDeposit}
                    sx={{
                      lineHeight: 1.5,
                      width: '20%'
                    }}
                    onClick={async () => {
                      try {
                        setIsDeposit(true);
                        let wxdcBalance = await wxdcBalanceOf();
                        wxdcBalance = weiToEth(wxdcBalance);
                        const amount = qty * token?.value;
                        console.log({ amount, wxdcBalance });
                        const amountToDeposit = new Big(amount).minus(new Big(wxdcBalance));
                        if (wxdcBalance > amount) {
                          enqueueSnackbar('You already have enough WXDC to complete this order.', {
                            variant: 'success'
                          });
                          setIsDeposit(false);
                          return;
                        }
                        // if (wxdcBalance < amount) {
                        //   amountToDeposit = amount - wxdcBalance;
                        // }

                        const bigRound = Big(amountToDeposit).round(0, 3);
                        console.log({ bigRound });
                        console.table({ amountToDeposit });
                        console.log({ amountToDeposit });
                        const tx = await depositXDC(bigRound);
                        enqueueSnackbar(`Deposit ${bigRound.toString()}  XDC successfully`, {
                          variant: 'success'
                        });
                        setIsDeposit(false);
                        checkRequirements(qty * token?.value);
                      } catch (e) {
                        console.log(e);
                        enqueueSnackbar(e.message, { variant: 'error' });
                        setIsDeposit(false);
                      }
                    }}
                  >
                    Deposit XDC
                  </LoadingButton>
                </Box>

                {/* <Typography sx={{ color: "#2d3436", fontWeight: "bold" }}>
                    Deposit XDC to fulfill this order.
                  </Typography> */}

                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: 1
                  }}
                >
                  <Card
                    sx={{
                      width: '70%'
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      sx={{
                        p: 1
                      }}
                    >
                      I allow Valid Protocol to transfer my tokens from escrow to the masternode to
                      be locked up for 365 days
                    </Typography>
                  </Card>

                  <LoadingButton
                    variant="contained"
                    size="large"
                    color="primary"
                    sx={{
                      width: '20%',
                      lineHeight: 1.5
                    }}
                    disabled={disableAllowance}
                    onClick={async () => {
                      try {
                        setIsAllowance(true);
                        const res = await approveWxdc(
                          currentNetwork.escrowManagerAddress,
                          qty * token?.value
                        );
                        console.log('res', res);
                        enqueueSnackbar('Escrow XDC successfully', { variant: 'success' });
                        setIsAllowance(false);
                        checkRequirements(qty * token?.value);
                      } catch (e) {
                        console.log(e);
                        enqueueSnackbar(e.message, { variant: 'error' });
                        setIsAllowance(false);
                      }
                    }}
                    startIconLoadingStart={{
                      color: '#000'
                    }}
                    loadingPosition="center"
                    loading={isAllowance}
                  >
                    Escrow WXDC
                  </LoadingButton>
                </Box>
              </Box>
            )}
            {activeStep === 2 && (
              <Box>
                <Typography variant="h4" fontWeight="bold" sx={{ mt: 6, mb: 3 }}>
                  Mint your stXDC
                </Typography>
                {/* <Typography>
                  You are purchasing {qty} {token?.name} tokens for{" "}
                  {token?.value} XDC each.
                </Typography> */}
                <Typography>You will receive 1 stXDC for every 1 XDC you've staked</Typography>
                <br />
                <Typography>
                  The stXDC will act as your receipt and will need these if you choose to withdraw
                  your XDC
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2
                  }}
                >
                  {/* <LoadingButton
                    loadingPosition="start"
                    loading={loadingState}
                    variant="gradient"
                    sx={{
                      mx: 2,
                      px: 3,
                      fontWeight: 500,
                      width: '7.75rem'
                    }}
                    color="primary"
                    onClick={async () => {
                      try {
                        setLoadingState(true);
                        const data = {};
                        console.log(`Address: ${account} Qty: ${qty}`);
                        const res = await tvtIssue(token.deployment_address, account, qty);
                        if (res) {
                          enqueueSnackbar('Token Minted Successfully', {
                            variant: 'success'
                          });
                        }
                        setLoadingState(false);
                        handleComplete();
                      } catch (e) {
                        enqueueSnackbar(e.message, { variant: 'error' });
                        setLoadingState(false);
                      }
                    }}
                  >
                    Mint stXDC
                  </LoadingButton> */}
                </Box>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                mt: 2,
                justifyContent: 'space-between'
              }}
            >
              {activeStep === 0 ? null : (
                <Button
                  color="secondary"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ height: '2.6rem', width: '7.813rem' }}
                >
                  Previous
                </Button>
              )}
              {/* {activeStep === 1 && (
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              )} */}
              {/* {activeStep === steps.length - 1 ? (
                <Button variant="contained" onClick={handleComplete()}>
                  Finish
                </Button>
              ) : activeStep === 0 ? null : (
                <Button variant="contained" onClick={handleNext}>
                  Next
                </Button>
              )} */}
              {activeStep === steps.length - 1 && (
                <Box display="flex" justifyContent="center" alignItems="center">
                  <LoadingButton
                    loadingPosition="center"
                    loading={loadingState}
                    variant="contained"
                    sx={{
                      mx: 2,
                      px: 3,
                      fontWeight: 500
                    }}
                    color="primary"
                    onClick={async () => {
                      try {
                        setLoadingState(true);
                        const data = {};
                        console.log(`Address: ${account} Qty: ${qty}`);
                        const res = await tvtIssue(token.deployment_address, account, qty);
                        if (res) {
                          enqueueSnackbar('Token Minted Successfully', {
                            variant: 'success'
                          });
                        }
                        setLoadingState(false);
                        handleComplete();
                      } catch (e) {
                        enqueueSnackbar(e.message, { variant: 'error' });
                        setLoadingState(false);
                      }
                    }}
                  >
                    Mint & Finish
                  </LoadingButton>
                </Box>
              )}
              {activeStep < steps.length - 1 && activeStep !== 0 ? (
                <Button
                  sx={{ mt: 2, height: '2.6rem', width: '20%' }}
                  variant="contained"
                  onClick={handleNext}
                  disabled={!disableDeposit || !disableAllowance}
                >
                  Next
                </Button>
              ) : null}
            </Box>
          </Box>
        )}
      </Box>
    </Card>
  );
}
