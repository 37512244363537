import React from 'react';
import { Box, Card, CardContent, Typography, Skeleton, Container, Button } from '@mui/material';
import { useNavigate } from 'react-router';

const EntityCard = ({
  title,
  buttonName,
  buttonRoute,
  buttonName2,
  buttonRoute2,
  count,
  icon,
  loading
}) => {
  const navigate = useNavigate();
  return (
    <Box>
      <Card
        sx={{
          height: '260px',
          width: '270px',
          borderRadius: '6px'
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
              // flexDirection: "row",
            }}
          >
            <Box
              sx={{
                m: 1,
                height: '60px',
                width: '60px',
                borderRadius: '6px',
                backgroundColor: '#fff',
                color: '#000',
                boxShadow: '0 18px 16px 0 rgba(0, 0, 0, 0.16)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              {icon}
            </Box>
            <Box>
              <Box>
                <Button
                  variant="contained"
                  sx={{
                    color: 'white',
                    fontWeight: 600,
                    width: '7rem',
                    backgroundColor: '#2E4AE6'
                  }}
                  onClick={() => navigate(buttonRoute)}
                >
                  {buttonName}
                </Button>
              </Box>
              {buttonName2 && (
                <Box>
                  <Button
                    variant="contained"
                    sx={{
                      color: 'white',
                      fontWeight: 600,
                      width: '7rem',
                      backgroundColor: '#2E4AE6',
                      mt: 1
                    }}
                    onClick={() => navigate(buttonRoute2)}
                  >
                    {buttonName2}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Card
            sx={{
              mt: 2,
              backgroundColor: '#f5f8fa',
              height: '116px',
              boxShadow: 'none',
              borderRadius: '4px'
            }}
          >
            <CardContent sx={{ p: 2 }}>
              <Typography sx={{ fontSize: '1.125rem', color: '#161c2d', fontWeight: 600 }}>
                {title}
              </Typography>
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  width={70}
                  height={65}
                  sx={{ borderRadius: '12px' }}
                />
              ) : (
                <Typography sx={{ fontSize: '2.375rem', color: '#161646' }}>{count}</Typography>
              )}
            </CardContent>
          </Card>
        </CardContent>
      </Card>
    </Box>
  );
};

export default EntityCard;
