import { CoreTableProvider } from './useCoreTableState';
import { Web3StateProvider } from './useWeb3';
import { HttpApiProvider } from './useHttpApi';
import { AuthStateProvider } from './useAuthState';
import { AppStateProvider } from './useAppState';
import { AuthStateSharedProvider } from './useAuthStateShared';
import { BusinessEntityProvider } from './useBusinessEntityState';
import { InvestmentsProvider } from './useInvestmentsState';

export const GlobalAppStateProvider = ({ children }) => {
  const Providers = [
    AuthStateSharedProvider,
    Web3StateProvider,
    HttpApiProvider,
    AuthStateProvider,
    CoreTableProvider,
    AppStateProvider,
    InvestmentsProvider
  ];

  let element = children;

  while (Providers.length > 0) {
    const Component = Providers.pop();
    element = <Component>{element}</Component>;
  }
  return element;
};

export { useCoreTableState } from './useCoreTableState';
export { useBusinessEntityState } from './useBusinessEntityState';
export { useWeb3State } from './useWeb3';
export { useAuthStateShared } from './useAuthStateShared';
export { useHttpApi, useHttpApiLoading } from './useHttpApi';
export { useAuthState } from './useAuthState';
export { useAppState } from './useAppState';
export { useInvestmentsState } from './useInvestmentsState';
