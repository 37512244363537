import { Container, Card, Grid, Box, CardContent, Typography } from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import EntityCard from 'components/Admin/EntitiyCard';
import { useBusinessEntityState } from 'state/useBusinessEntityState';
import { useCoreTableState, useInvestmentsState, useWeb3State } from 'state';
import newSubscription from 'assets/images/dashboard-icon/newSubscription.svg';
import escrowSettlement from 'assets/images/dashboard-icon/escrowSettlement.svg';
import token from 'assets/images/masterdata-dashboard/cryptoAssetTxn.svg';
import cash from 'assets/images/masterdata-dashboard/cashTxn.svg';
import allotements from 'assets/images/dashboard-icon/allotments.svg';
import node from 'assets/images/node.jpg';
import PieChartIcon from '@mui/icons-material/PieChart';

const AdminDashboard = () => {
  const { stats, fetchStats } = useBusinessEntityState();

  const [loading, setloading] = useState(true);
  const { account } = useWeb3State();

  useEffect(() => {
    fetchStats();
    setloading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Page title="XDC Staking">
        <Container sx={{ my: 2 }}>
          {/* <Breadcrumbs pageHead="Masterdata" aria-label="breadcrumb" /> */}

          <Card sx={{ my: 3, boxShadow: 'none' }}>
            <CardContent>
              <Typography sx={{ fontSize: '1.275rem', fontWeight: 600 }}>Stats</Typography>
            </CardContent>
          </Card>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                loading={loading}
                buttonRoute="/admin/pools"
                buttonRoute2="/admin/add-pool"
                title="Nodes"
                buttonName="View"
                buttonName2="Add"
                count={stats ? stats.pool_count : 0}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={node}
                  />
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                loading={loading}
                buttonRoute="/admin/tokens"
                buttonRoute2="/admin/create-token"
                title="Node Tokens"
                buttonName="View"
                buttonName2="Add"
                count={stats ? stats.token_count : 0}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={token}
                  />
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                loading={loading}
                buttonRoute="/admin/settlements"
                title="Settlements"
                buttonName="View"
                count={stats ? stats.escrow_order_count : 0}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={escrowSettlement}
                  />
                }
              />
            </Grid>
            {/* <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                loading={loading}
                buttonRoute="/admin/whitelist-manager"
                title="Whitelisting"
                buttonName="View"
                count={stats ? stats.investor_count : 0}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={newSubscription}
                  />
                }
              />
            </Grid> */}
            <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                loading={loading}
                buttonRoute="/admin/rewards"
                title="Node Rewards"
                buttonName="View"
                count={stats ? stats.reward_distribution_count : 0}
                icon={
                  <Box
                    component="img"
                    sx={{
                      p: 1
                    }}
                    src={cash}
                  />
                }
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6}>
              <EntityCard
                loading={loading}
                buttonRoute="/admin/allotments"
                title="Allotments"
                buttonName="View"
                count={stats ? stats.allotment_count : 0}
                icon={
                  <PieChartIcon
                    sx={{
                      fontSize: '2.0rem'
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default AdminDashboard;
