import * as Yup from 'yup';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import MuiPhoneInput from 'material-ui-phone-number';
import parsePhoneNumber from 'libphonenumber-js';

import { useSnackbar } from 'notistack';
import {
  Button,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Alert,
  Box,
  FormLabel,
  FormHelperText,
  MenuItem,
  Select,
  FormControl
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useAuthState } from 'state/useAuthState';
import { useAppState } from 'state/useAppState';
import { getErrorMessage } from 'helpers/error';
import {
  useBusinessEntityState,
  useCoreTableState,
  useHttpApi,
  useInvestmentsState,
  useWeb3State
} from 'state';
import { debounce } from 'helpers/handlers';
import FocusError from 'helpers/FocusError';

const CreateProfileForm = () => {
  const { setCurrentInvestorFunction } = useInvestmentsState();
  const [error, setError] = useState(false);
  const { account } = useWeb3State();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  console.log(account);
  const [invalidPhone, setInvalidPhone] = useState(false);
  const { throwErrorMessage } = useAppState();
  const { countries } = useCoreTableState();
  const { createInvestorProfile } = useHttpApi();

  const CreateProfileSchema = Yup.object().shape({
    name: Yup.string()
      .required('Name is required')
      .max(30, 'Name cannot be more than 30 characters'),
    email_id: Yup.string().email('Invalid Email Format').required('Email is required'),
    // country: Yup.string().required('Country is required'),
    termsAndConditions: Yup.boolean().required('You must accept the terms and conditions')
  });
  const formik = useFormik({
    initialValues: {
      name: '',
      email_id: '',
      // country: '',
      termsAndConditions: false
    },
    validate: (values) => {
      const errors = {};
      if (values.termsAndConditions === false) {
        errors.termsAndConditions = 'You must accept the terms and conditions';
      }
      return errors;
    },
    validationSchema: CreateProfileSchema,
    onSubmit: async (data, { resetForm, setErrors }) => {
      try {
        data.xinfin_account = account;
        const res = await createInvestorProfile(data);
        setCurrentInvestorFunction(res);
        resetForm();
        enqueueSnackbar(`Registered Successfully`, {
          variant: 'success'
        });
        navigate('/loading-account', { replace: true });
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });

  const phoneNumberHandler = (value) => {
    let countryCode;
    const errors = {};
    setInvalidPhone(true);
    errors.phone_no = 'Enter Valid Phone Number';
    const phoneNumber = parsePhoneNumber(value);
    if (phoneNumber) {
      countryCode = phoneNumber.country;
      if (countryCode) {
        const _phoneNumberValidityParse = parsePhoneNumber(value, countryCode);
        if (_phoneNumberValidityParse.isValid()) {
          formik.setFieldValue('phone_no', _phoneNumberValidityParse.number);
          delete errors.phone_no;
          console.log('phone details', _phoneNumberValidityParse);
          console.log('Phone Validity', phoneNumber.isValid());
          console.log('Phone Num', _phoneNumberValidityParse.number);
          setInvalidPhone(false);
        }
      }
    }
    return errors;
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {error && (
          <Box mb={4}>
            <Alert severity="error">{error}</Alert>
          </Box>
        )}
        <Stack spacing={3} sx={{ mt: 4 }}>
          <FormLabel>Code Name</FormLabel>
          <TextField
            fullWidth
            autoComplete="name"
            id="name"
            type="text"
            placeholder="Enter Code Name"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <FormLabel>Email</FormLabel>
          <TextField
            fullWidth
            autoComplete="email_id"
            id="email_id"
            type="text"
            placeholder="Enter email "
            {...getFieldProps('email_id')}
            error={Boolean(touched.email_id && errors.email_id)}
            helperText={touched.email_id && errors.email_id}
          />
          {/* <FormLabel>Country Code</FormLabel>
          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
            <Select
              // placeholder="Select Country"
              {...getFieldProps('country')}
              error={Boolean(touched.country && errors.country)}
              helperText={touched.country && errors.country}
            >
              {countries &&
                countries.map((country) => {
                  return (
                    <MenuItem key={country.iso_code} value={country.iso_code}>
                      {country.name}
                    </MenuItem>
                  );
                })}
            </Select>
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {touched.country && errors.country}
            </FormHelperText>
          </FormControl> */}
          <FormGroup>
            <FormControlLabel
              sx={{}}
              control={<Checkbox />}
              label="I agree to terms and conditions"
              {...getFieldProps('termsAndConditions')}
              error={Boolean(touched.termsAndConditions && errors.termsAndConditions)}
              helperText={touched.termsAndConditions && errors.termsAndConditions}
            />
            <FormHelperText sx={{ color: '#d32f2f' }}>
              {touched.termsAndConditions && errors.termsAndConditions}
            </FormHelperText>
          </FormGroup>
          <Button
            variant="outlined"
            loadingPosition="start"
            id="create-profile"
            type="submit"
            sx={{
              width: 'auto',
              height: '2.5rem',
              mr: 2,
              mt: 2,
              backgroundColor: '#3449DD',
              color: '#fff',
              '&:hover': {
                color: '#3449DD',
                backgroundColor: '#fff'
              }
            }}
            onClick={() => {
              navigate('/create-profile');
            }}
          >
            Create Profile
          </Button>
          <FocusError />
        </Stack>
      </Form>
    </FormikProvider>
  );
};

export default CreateProfileForm;
