import constate from 'constate';
import { getErrorMessage, getErrorMessageAlternative } from 'helpers/error';
import { useSnackbar } from 'notistack';

const useAppState_ = () => {
  const { enqueueSnackbar } = useSnackbar();
  const throwErrorMessage = async (error) => {
    const _message = getErrorMessage(error);
    const newMessage = getErrorMessageAlternative(_message);

    enqueueSnackbar(newMessage, {
      variant: 'error'
    });
    return newMessage;
  };

  return {
    throwErrorMessage
  };
};
export const [AppStateProvider, useAppState] = constate(useAppState_);
