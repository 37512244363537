import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Typography,
  Grid,
  MenuItem,
  Select,
  TextField,
  Button
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { LoadingButton } from '@mui/lab';
import xdclogo from 'assets/images/icon-xdcpay.png';
import wxdclogo from 'assets/images/wxdclogo.png';
import { useAppState, useWeb3State } from 'state';
import Web3 from 'web3';
import { formatFloat, weiToEth } from 'helpers/numbers';
import { useSnackbar } from 'notistack';

const SwapCard = () => {
  const { balance, wxdcBalanceOf, depositXDC, withDrawXDC, account } = useWeb3State();
  const { throwErrorMessage } = useAppState();
  const balanceStr = formatFloat(parseFloat(Web3.utils.fromWei(balance, 'ether')), 3);
  const [firstValue, setFirstValue] = useState('0');
  const [secondValue, setSecondValue] = useState('0');
  const [wxdc, setWXDC] = useState(0);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const fetchdata = async () => {
    try {
      const response = await wxdcBalanceOf();
      console.log('response', response);
      setWXDC(response);
    } catch (e) {
      console.log(e);
      setWXDC('0');
    }
  };

  // useEffect(() => {
  //   fetchdata();
  // }, [account]);

  const formik = useFormik({
    initialValues: {
      value1: '',
      value2: '',
      amount: ''
    }
    // validationSchema: TokenSchema,
  });
  const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleChange = (e) => {
    console.log(e.target.value);
    setFirstValue(e.target.value);
    console.log('VALUE01', values.value1);
    if (e.target.value === 'xdc') {
      setFieldValue('value2', 'wxdc');
      setSecondValue('wxdc');
      fetchdata();
    } else if (e.target.value === 'wxdc') {
      setFieldValue('value2', 'xdc');
      setSecondValue('xdc');
      fetchdata();
    } else {
      setFieldValue('value2', '');
      setSecondValue('');
    }
  };

  const handleChange2 = (e) => {
    console.log(e.target.value);
    console.log('VALUE02', values.value2);
    setSecondValue(e.target.value);
    if (e.target.value === 'xdc') {
      setFieldValue('value1', 'wxdc');
      setFirstValue('wxdc');
      fetchdata();
    } else if (e.target.value === 'wxdc') {
      setFieldValue('value1', 'xdc');
      setFirstValue('xdc');
      fetchdata();
    } else {
      setFieldValue('value1', '');
      setFirstValue('');
    }
  };

  return (
    <Card
      sx={{
        width: '40%',
        py: 2,
        px: 3,
        borderRadius: 8
      }}
    >
      <Typography>Swap</Typography>
      <FormikProvider value={formik}>
        <Form autoComplete="off" onSubmit={handleSubmit}>
          <CardContent
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column'
            }}
          >
            <Grid container sx={{ width: '100%' }}>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  sx={{
                    // border: '2px solid #949292',
                    borderRadius: 4,
                    // boxShadow: '0px 0px 10px 0px #bab5b5',
                    p: 2,
                    backgroundColor: '#f5f5f5',
                    '&:hover': {
                      border: '1px solid #bab5b5'
                    }
                  }}
                >
                  <TextField
                    sx={{ width: '65%', fontWeight: 'bold' }}
                    fullWidth
                    variant="standard"
                    size="small"
                    placeholder="0.0"
                    autoComplete="off"
                    type="number"
                    InputProps={{ disableUnderline: true }}
                    {...getFieldProps('amount')}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />

                  <Box sx={{ width: '35%' }}>
                    <FormControl size="small" fullWidth>
                      <Select
                        inputProps={{ 'aria-label': 'Without label' }}
                        {...getFieldProps('value1')}
                        id="value1"
                        value={firstValue}
                        error={Boolean(touched.value1 && errors.value1)}
                        helperText={touched.value1 && errors.value1}
                        sx={{ borderRadius: 10, display: 'flex' }}
                        autoWidth
                        onChange={handleChange}
                      >
                        <MenuItem key="xdc" value="xdc">
                          <Box
                            display="flex"
                            sx={{
                              fontWeight: 600
                            }}
                          >
                            <Box
                              sx={{
                                pr: 0.5,
                                height: '1.3rem',
                                width: 'auto'
                              }}
                              component="img"
                              src={xdclogo}
                            />
                            XDC
                          </Box>
                        </MenuItem>
                        <MenuItem key="wxdc" value="wxdc">
                          <Box
                            display="flex"
                            sx={{
                              fontWeight: 600
                            }}
                          >
                            <Box
                              sx={{
                                pr: 0.5,
                                height: '1.3rem',
                                width: 'auto'
                              }}
                              component="img"
                              src={wxdclogo}
                            />
                            WXDC
                          </Box>
                        </MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: '#d32f2f' }}>
                        {touched.value1 && errors.value1}
                      </FormHelperText>
                    </FormControl>
                    <Typography fontWeight={600} fontSize="0.8rem">
                      Balance : {firstValue === 'xdc' && balanceStr}
                      {firstValue === 'wxdc' &&
                        (weiToEth(wxdc.toString()).split('.')[1] === undefined
                          ? parseFloat(weiToEth(wxdc.toString())).toFixed(3)
                          : `${weiToEth(wxdc.toString()).split('.')[0]}.${weiToEth(wxdc.toString())
                              .split('.')[1]
                              .slice(0, 2)}`)}
                      {firstValue === '' && 0}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  sx={{
                    // border: '2px solid #949292',
                    borderRadius: 4,
                    // boxShadow: '0px 0px 10px 0px #bab5b5',
                    p: 2,
                    backgroundColor: '#f5f5f5',
                    '&:hover': {
                      border: '1px solid #bab5b5'
                    },
                    my: 2
                  }}
                >
                  <TextField
                    sx={{ width: '65%' }}
                    fullWidth
                    variant="standard"
                    size="small"
                    autoComplete="off"
                    type="number"
                    placeholder="0.0"
                    InputProps={{ disableUnderline: true }}
                    {...getFieldProps('amount')}
                    error={Boolean(touched.amount && errors.amount)}
                    helperText={touched.amount && errors.amount}
                  />
                  <Box sx={{ width: '35%' }}>
                    <FormControl size="small" fullWidth>
                      <Select
                        inputProps={{ 'aria-label': 'Without label' }}
                        {...getFieldProps('value2')}
                        id="value2"
                        error={Boolean(touched.value2 && errors.value2)}
                        helperText={touched.value2 && errors.value2}
                        sx={{ borderRadius: 10 }}
                        autoWidth
                        value={secondValue}
                        onChange={handleChange2}
                      >
                        <MenuItem key="xdc" value="xdc">
                          <Box
                            display="flex"
                            sx={{
                              fontWeight: 600
                            }}
                          >
                            <Box
                              sx={{
                                pr: 0.5,
                                height: '1.3rem',
                                width: 'auto'
                              }}
                              component="img"
                              src={xdclogo}
                            />
                            XDC
                          </Box>
                        </MenuItem>
                        <MenuItem key="wxdc" value="wxdc">
                          <Box
                            display="flex"
                            sx={{
                              fontWeight: 600
                            }}
                          >
                            <Box
                              sx={{
                                pr: 0.5,
                                height: '1.3rem',
                                width: 'auto'
                              }}
                              component="img"
                              src={wxdclogo}
                            />
                            WXDC
                          </Box>
                        </MenuItem>
                      </Select>
                      <FormHelperText sx={{ color: '#d32f2f' }}>
                        {touched.value2 && errors.value2}
                      </FormHelperText>
                    </FormControl>
                    <Typography fontWeight={600} fontSize="0.8rem">
                      Balance : {secondValue === 'xdc' && balanceStr}
                      {secondValue === 'wxdc' &&
                        (weiToEth(wxdc.toString()).split('.')[1] === undefined
                          ? parseFloat(weiToEth(wxdc.toString())).toFixed(3)
                          : `${weiToEth(wxdc.toString()).split('.')[0]}.${weiToEth(wxdc.toString())
                              .split('.')[1]
                              .slice(0, 2)}`)}
                      {secondValue === '' && 0}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {firstValue === 'xdc' && (
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    loadingPosition="center"
                    loading={isDeposit}
                    onClick={async () => {
                      try {
                        setIsDeposit(true);
                        const amountToDeposit = values.amount;
                        const cbalance = formatFloat(
                          parseFloat(Web3.utils.fromWei(balance, 'ether'))
                        );
                        console.log('AMOUNT', amountToDeposit);
                        console.log('BALANCE', cbalance);
                        if (amountToDeposit === '' || amountToDeposit === 0) {
                          console.log('No Amount');
                          enqueueSnackbar('Amount should be entered', { variant: 'error' });
                          setIsDeposit(false);
                          return;
                        }
                        if (amountToDeposit > cbalance) {
                          console.log('I AM INSIDE');
                          enqueueSnackbar('Not enough balance', { variant: 'error' });
                          setIsDeposit(false);
                          return;
                        }

                        const tx = await depositXDC(amountToDeposit);
                        enqueueSnackbar(`Deposit ${amountToDeposit}  XDC successfully`, {
                          variant: 'success'
                        });
                        fetchdata();
                        setFieldValue('amount', '');
                        setIsDeposit(false);
                      } catch (e) {
                        console.log(e);
                        throwErrorMessage(e);
                        setIsDeposit(false);
                      }
                    }}
                  >
                    Deposit
                  </LoadingButton>
                )}
                {firstValue === '' && secondValue === '' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    loadingPosition="center"
                    loading={isDeposit}
                    disabled
                  >
                    Select Coin
                  </Button>
                )}

                {firstValue === 'wxdc' && (
                  <LoadingButton
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    loadingPosition="center"
                    loading={isWithdraw}
                    onClick={async () => {
                      try {
                        setIsWithdraw(true);
                        const amountToWithdraw = values.amount;
                        const cwxdc = weiToEth(wxdc);
                        console.log('AMOUNT', amountToWithdraw);
                        console.log('BALANCE', cwxdc);
                        if (amountToWithdraw === '' || amountToWithdraw === 0) {
                          console.log('No Amount');
                          enqueueSnackbar('Amount should be entered', { variant: 'error' });
                          setIsWithdraw(false);
                          return;
                        }
                        if (amountToWithdraw > cwxdc) {
                          console.log('I AM INSIDE');
                          enqueueSnackbar('Not enough balance', { variant: 'error' });
                          setIsWithdraw(false);
                          return;
                        }
                        const tx = await withDrawXDC(amountToWithdraw);
                        enqueueSnackbar(`Withdraw ${amountToWithdraw}  XDC successfully`, {
                          variant: 'success'
                        });
                        fetchdata();
                        setFieldValue('amount', '');
                        setIsWithdraw(false);
                      } catch (e) {
                        console.log(e);
                        throwErrorMessage(e);
                        setIsWithdraw(false);
                      }
                    }}
                  >
                    Withdraw
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Form>
      </FormikProvider>
    </Card>
  );
};

export default SwapCard;
