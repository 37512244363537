import React from 'react';
import { PieChart, Pie, Sector, Cell, Legend, Tooltip } from 'recharts';
import { Typography, Box, Card } from '@mui/material';

export default function PieChartData({ zero, hundred, five100, oneM, onepoint5, twoM, two5plus }) {
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#000000', '#6f4e7c', '#8dddd0'];

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const total = zero + hundred + five100 + oneM + onepoint5 + twoM + two5plus;
      const percent = ((payload[0].value / total) * 100).toFixed(2);
      return (
        <Card
          sx={{
            backgroundColor: '#ffffff',
            p: 1,
            borderRadius: 2
          }}
        >
          <Typography variant="h6">{`${payload[0].name} : ${percent} %`}</Typography>
        </Card>
      );
    }

    return null;
  };
  const data = [
    { name: '10k-100k', value: zero },
    { name: '100k-499k', value: hundred },
    { name: '500k-1M', value: five100 },
    { name: '1M-1.49M', value: oneM },
    { name: '1.5M-1.99M', value: onepoint5 },
    { name: '2M-2.49M', value: twoM },
    { name: '2.5M+', value: two5plus }
  ];
  return (
    <Box>
      <PieChart width={200} height={200}>
        <Pie
          data={data}
          dataKey="value"
          nameKey="name"
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          paddingAngle={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </Box>
  );
}
