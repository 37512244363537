import Page from 'components/Page';
import React from 'react';
import { Card, Container, CardContent, Box, Button, Grid } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import WhitelistManager from 'components/Admin/Tables/WhitelistManager';
import { useAppState, useHttpApi } from 'state';
import { useSnackbar } from 'notistack';

function InputButton() {
  const fileInput = React.useRef();
  const { uploadKyc } = useHttpApi();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        sx={{ color: 'white', fontWeight: 600, backgroundColor: '#2E4AE6' }}
        onClick={() => {
          fileInput.current.click();
          // read file
        }}
      >
        Upload file
      </Button>

      <input
        ref={fileInput}
        type="file"
        accept=".csv"
        onChange={async (e) => {
          try {
            const file = e.target.files[0];
            console.log(file);
            const data = {};
            data.kyc_file = file;
            const res = await uploadKyc(data);
            console.log(res);
            enqueueSnackbar('KYC file uploaded successfully', { variant: 'success' });
            fileInput.current.value = '';
          } catch (err) {
            throwErrorMessage(err);
          }
        }}
        style={{ display: 'none' }}
      />
    </Box>
  );
}
const AllTokenWhitelist = () => {
  return (
    <Page title="XDC Staking">
      <Container sx={{ mt: 2 }}>
        <Grid container spacing={2} alignItems="flex-end" justifyContent="space-between">
          <Grid item xs={8}>
            <Breadcrumbs pageHead="Platform Whitelist Manager" />
          </Grid>
          <Grid item xs={2}>
            <InputButton />
          </Grid>
        </Grid>
        <Card sx={{ mt: 2 }} className="form-card">
          <CardContent>
            <WhitelistManager />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default AllTokenWhitelist;
