import { Alert, AlertTitle, Box } from '@mui/material';

const InvestorWalletRequire = () => {
  console.log('Checl');
  return (
    <Alert
      severity="warning"
      sx={{
        position: 'fixed',
        top: '40vh',
        left: 'calc(50vw - 550px / 2)',
        'z-index': 5,
        width: '550px'
      }}
    >
      <AlertTitle>Connect Wallet</AlertTitle>
      Please Connect to the Wallet
    </Alert>
  );
};

export default InvestorWalletRequire;
