import Page from '../../components/Page';
import React from 'react';
import { Container } from '@mui/material';
import SwapCard from 'components/Investor/SwapCard';

const SwapDashboard = () => {
  return (
    <Page title="XDC Staking">
      <Container
        sx={{
          py: 6,
          justifyContent: 'center',
          display: 'center'
        }}
      >
        <SwapCard />
      </Container>
    </Page>
  );
};

export default SwapDashboard;
