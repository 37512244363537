import constate from 'constate';
import { useHttpApi } from 'state/useHttpApi';
import { useAuthStateShared } from 'state/useAuthStateShared';
import { userNamePersistence, userTokenPersistence } from 'persistence';

const useAuthState_ = () => {
  const { userLogin, userLogout, getCurrentUser } = useHttpApi();
  const { user, setUser, setLoggedOutState, setIsReady, setIsAuthorized, isReady, isAuthorized } =
    useAuthStateShared();

  const initAuth = async () => {
    if (isReady) {
      return;
    }
    const user = userNamePersistence.get();
    setUser(user);
    setIsAuthorized(true);

    if (!user) {
      setLoggedOutState();
      setIsReady(true);
    }
    const token = userTokenPersistence.get();

    if (!token) {
      setLoggedOutState();
      setIsReady(true);
    }
    try {
      const { user, token } = await getCurrentUser();
      setUser(user);
      setIsAuthorized(true);
    } catch (e) {
      setLoggedOutState();
    } finally {
      setIsReady(true);
    }
  };

  const login = async (username, password) => {
    const res = await userLogin({ username, password });
    userNamePersistence.set(res.username);
    userTokenPersistence.set(res.token);
    setUser(res.username);
    setIsAuthorized(true);
    setIsReady(true);
  };

  const logout = async () => {
    const name = userNamePersistence.get();
    await userLogout(name);
    setLoggedOutState();
    localStorage.clear();
  };

  return {
    user,
    isReady,
    isAuthorized,
    login,
    initAuth,
    logout
  };
};
export const [AuthStateProvider, useAuthState] = constate(useAuthState_);
